import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "services/client-id";
import { ParametrosListarPoliticaTemplate } from "./listar-politica-template-parametros";
import { AlteracaoPoliticaTemplate } from "./models/alteracao-politica-template";
import { InsercaoPoliticaTemplate } from "./models/insercao-politica-template";
import { PoliticaTemplate } from "./models/politica-template";
import { PoliticaTemplateDetalhada } from "./models/politica-template-detalhada";

const politicaTemplateUrl = process.env.REACT_APP_ATIVIDADE_API_URL + "/PoliticaTemplate";

const requestApi = new RequestApi(CLIENT_ID);

export const PoliticaTemplateService = {
    listarPoliticaTemplate: (parametros?: ParametrosListarPoliticaTemplate) => {
        const url = new URL(`${politicaTemplateUrl}/ListarPoliticaTemplate`);

        if (parametros) {
            url.search = RequestApi.objectToQueryString(parametros);
        }

        return requestApi.get<PoliticaTemplate[]>(url.toString());
    },
    obterPoliticaTemplate: async (idPoliticaTemplate: string) => {
        const url = `${politicaTemplateUrl}/ObterPoliticaTemplate/${idPoliticaTemplate}`;
        return await requestApi.get<PoliticaTemplateDetalhada>(url);
    },
    criarPoliticaTemplate: (parametros: InsercaoPoliticaTemplate) => {
        const url = new URL(politicaTemplateUrl);
        return requestApi.post<PoliticaTemplateDetalhada>(url.toString(), parametros);
    },
    alterarPoliticaTemplate: (parametros: AlteracaoPoliticaTemplate) => {
        const url = new URL(politicaTemplateUrl);
        return requestApi.patch<PoliticaTemplateDetalhada>(url.toString(), parametros);
    },
};
