import { ReactComponent as EditPen } from "assets/images/edit-pen.svg";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { IdTipoParametro } from "services/configuracoes/enums/id-tipo-parametro";
import { ParametroListado } from "services/configuracoes/models/parametro-listado";
import { listarParametros } from "services/configuracoes/parametros-service";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import { exibirModalEditarParametros } from "./modal-editar-parametros";
import styles from "./parametros.module.scss";

const BotaoEditarParametro = ({ parametro, atualizar }: { parametro: ParametroListado; atualizar: () => void }) => {
    return (
        <button
            className={styles.botaoEditar}
            onClick={async () => {
                await exibirModalEditarParametros(parametro, atualizar);
            }}
        >
            <EditPen />
        </button>
    );
};

export const Parametros = () => {
    const [promiseParametros, setPromiseParametros] = useState<Promise<ParametroListado[] | undefined>>();

    const atualizarListaParametros = useCallback(() => {
        setPromiseParametros(listarParametros());
    }, []);

    useEffect(() => {
        atualizarListaParametros();
    }, [atualizarListaParametros]);

    const columns: IDataTableColumn<ParametroListado>[] = useMemo(
        () => [
            {
                name: "Código",
                selector: (p) => p.id,
                sortable: true,
                maxWidth: "5rem",
            },
            {
                name: "Descrição",
                selector: (p) => p.descricao,
                sortable: true,
            },
            {
                name: "Valor",
                selector: (p) => p.valor,
                format: (p) =>
                    p.tipoParametro.id === IdTipoParametro.Booleano ? (p.valor === "true" ? "Sim" : "Não") : p.valor,
                sortable: true,
                maxWidth: "20rem",
            },
            {
                name: "Tipo",
                selector: (p) => p.tipoParametro.descricao,
                sortable: true,
                maxWidth: "5rem",
            },
            {
                name: "",
                cell: (p) => <BotaoEditarParametro parametro={p} atualizar={atualizarListaParametros} />,
                maxWidth: "5rem",
            },
        ],
        [atualizarListaParametros]
    );

    return (
        <div>
            <h2>Parâmetros</h2>
            <OmnijusCard
                body={
                    <Loading promise={promiseParametros}>
                        {(parametros) =>
                            parametros ? (
                                <DataTable
                                    noHeader={true}
                                    pagination={true}
                                    paginationPerPage={20}
                                    noDataComponent={<p>Nenhum registro encontrado!</p>}
                                    columns={columns}
                                    data={parametros}
                                />
                            ) : (
                                <>Erro ao listar parâmetros</>
                            )
                        }
                    </Loading>
                }
            />
        </div>
    );
};
