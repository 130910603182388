import { ActionFeedback, showModalForm } from '@omnijus/common';
import { Form } from 'formik';
import React from 'react';
import { DeadLetterService } from 'services/dead-letter/dead-letter-service';
import { ReenviarMensagemRequest } from 'services/dead-letter/models/dead-letter';
import { ButtonPrimary } from 'shared/buttons/button-primary/button-primary';
import { OmnijusTextAreaField } from 'shared/form/fields/omnijus-textarea-field';
import { object as YupObject, string as YupString } from "yup";
import { FormatJson } from '../forms/forms';
import styles from './reenviar-dead-letter.module.scss';

const validationSchema = YupObject().shape({
    mensagem: YupString().trim().required("Informe a mensagem"),
});

export const exibirModalEnviarDeadLetter = async (idMensagem: string, mensagemAtual: string) => {
    const msgFormatada = FormatJson(mensagemAtual);
    const res = await showModalForm<ReenviarMensagemRequest>(
        'Reenviar mensagem',
        { mensagem: msgFormatada },
        <FormReenviarMensagem />,
        '80%',
        validationSchema
    );

    if (res) {
        await ActionFeedback.processing({
            title: "Reenviando mensagem",
            execution: DeadLetterService.reenviarMensagem(idMensagem, res.mensagem),
        });
        await ActionFeedback.info({ title: "Mensagem reenviada" });

        return true;
    }

    return false;
}

export const FormReenviarMensagem = () => {
    return <div className='text-left'>
        <Form>
            <OmnijusTextAreaField
                name="mensagem"
                rows={30}
            />
            <div className={styles.buttonContainer}>
                <ButtonPrimary type='submit'>Enviar Mensagem</ButtonPrimary>
            </div>
        </Form>
    </div>
}