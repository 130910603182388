import { ActionFeedback } from "@omnijus/common";
import { Format } from "lib/format";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { CadastroCompleto } from "services/onboarding/models/cadastro-completo";
import { IdStatusCadastro } from "services/onboarding/models/id-status-cadastro";
import { IdTipoAnexo } from "services/onboarding/models/id-tipo-anexo";
import { Inconsistencia } from "services/onboarding/models/inconsistencia";
import {
    iniciarAnalise,
    obterInconsistencias,
    terminarAnalise,
} from "services/onboarding/onboarding-inconsistencias-service";
import { getCadastroCompleto } from "services/onboarding/onboarding-service";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { ButtonSecondary } from "shared/buttons/button-secondary/button-secondary";
import { Loading } from "shared/loading/loading";
import { exibirModalAprovarCadastro } from "./aprovar-cadastro/aprovar-cadastro";
import { DadosDoContato } from "./dados-do-contato/dados-do-contato";
import { DadosDoRG } from "./dados-do-rg/dados-do-rg";
import styles from "./detalhe-cadastro.module.scss";
import { DetalhesCadastroInfoContext } from "./detalhes-cadasatro-info-context";
import { Endereco } from "./endereco/endereco";
import { ExperienciaProfissional } from "./experiencia-profissional/experiencia-profissional";
import { FormacaoAcademica } from "./formacao-academica/formacao-academica";
import { HistoricoDeCadastro } from "./historico-de-cadastro/historico-de-cadastro";
import { RegistroProfissional } from "./registro-profissional/registro-profissional";

export interface DetalheCadastroProps {
    analiseInconsistencias?: boolean;
}
export const DetalheCadastro = ({ analiseInconsistencias }: DetalheCadastroProps) => {
    const history = useHistory();
    const { idCadastro } = useParams<{ idCadastro: string }>();
    const [promiseDetalheCadastro, setPromiseDetalheCadastro] = useState<Promise<CadastroCompleto | undefined>>();
    const [promiseInconsistencias, setPromiseInconsistencias] = useState<Promise<Inconsistencia[] | undefined>>();
    const [inconsistencias, setInconsistencias] = useState<Inconsistencia[]>();

    useEffect(() => {
        var atualizaInconsistencias = true;
        promiseInconsistencias?.then(
            (inconsistencias) => atualizaInconsistencias && setInconsistencias(inconsistencias)
        );
        return () => {
            atualizaInconsistencias = false;
        };
    }, [promiseInconsistencias]);

    const atualizaInconsistencias = useCallback(() => {
        const promise = obterInconsistencias(idCadastro);
        setPromiseInconsistencias(promise);
        return promise;
    }, [idCadastro]);

    const voltar = useCallback(() => {
        if (document.referrer) {
            history.goBack();
        } else {
            history.push("/onboarding/cadastro");
        }
    }, [history]);

    useEffect(() => {
        if (analiseInconsistencias) {
            iniciarAnalise(idCadastro)
                .catch((reason) =>
                    ActionFeedback.error({ titleText: "Erro iniciando análise", text: reason?.message }).then(() => {
                        voltar();
                    })
                )
                .then(() => setPromiseDetalheCadastro(getCadastroCompleto(idCadastro)))
                .then(atualizaInconsistencias);
        } else {
            setPromiseDetalheCadastro(getCadastroCompleto(idCadastro));
        }
    }, [idCadastro, analiseInconsistencias, history, voltar, atualizaInconsistencias]);

    return (
        <Loading promise={promiseDetalheCadastro}>
            {(detalheCadastro) =>
                detalheCadastro ? (
                    <DetalhesCadastroInfoContext.Provider
                        value={{
                            analiseInconsistencias: analiseInconsistencias || false,
                            idCadastroPrestador: detalheCadastro.id,
                            atualizaInconsistencias: atualizaInconsistencias,
                            inconsistencias: inconsistencias,
                            dataUltimoEmAnalise: detalheCadastro.historico
                                ?.sort((a, b) => a.data.getTime() - b.data.getTime())
                                .filter((h) => h.idStatusCadastro === IdStatusCadastro.EmAnalise)[1]?.data,
                        }}
                    >
                        <HistoricoDeCadastro
                            historico={detalheCadastro.historico || []}
                            historicoAtual={detalheCadastro.historicoAtual}
                            etapa={detalheCadastro.etapaCadastro.descricao || ""}
                        />
                        <DadosDoContato
                            nome={detalheCadastro.nome || ""}
                            cpf={detalheCadastro.cpf || ""}
                            telefone={
                                (detalheCadastro.telefone && Format.formatCellphoneNumber(detalheCadastro.telefone)) ||
                                ""
                            }
                            email={detalheCadastro.email || ""}
                            selfie={detalheCadastro.anexos?.find((a) => a.idTipoAnexo === IdTipoAnexo.Selfie)}
                        />
                        <DadosDoRG
                            dataExpedicaoDocumentoIdentidade={
                                detalheCadastro.dataExpedicaoDocumentoIdentidade || undefined
                            }
                            documentoIdentidade={detalheCadastro.documentoIdentidade || ""}
                            orgaoExpedicaoDocumentoIdentidade={detalheCadastro.orgaoExpedicaoDocumentoIdentidade || ""}
                            ufDocumentoIdentidade={detalheCadastro.ufDocumentoIdentidade || ""}
                            frenteRG={detalheCadastro.anexos?.find((a) => a.idTipoAnexo === IdTipoAnexo.RGFrente)}
                            versoRG={detalheCadastro.anexos?.find((a) => a.idTipoAnexo === IdTipoAnexo.RGVerso)}
                        />
                        <RegistroProfissional
                            possuiRegistroProfissional={detalheCadastro.possuiRegistroProfissional || false}
                            entidadeClasse={detalheCadastro.entidadeClasse?.descricao}
                            dataRegistroEntidadeClasse={detalheCadastro.dataRegistroEntidadeClasse || undefined}
                            registroEntidadeClasse={detalheCadastro.registroEntidadeClasse || undefined}
                            tipoDocumentoEntidadeClasse={detalheCadastro.tipoDocumentoEntidadeClasse?.descricao}
                            frenteRegistroProfissional={detalheCadastro.anexos?.find(
                                (a) => a.idTipoAnexo === IdTipoAnexo.RegistroProfissionalFrente
                            )}
                            versoRegistroProfissional={detalheCadastro.anexos?.find(
                                (a) => a.idTipoAnexo === IdTipoAnexo.RegistroProfissionalVerso
                            )}
                        />
                        <FormacaoAcademica
                            possuiFormacaoAcademica={detalheCadastro.possuiFormacaoAcademica || false}
                            formacoes={
                                detalheCadastro.formacaoAcademica?.map((formacao) => ({
                                    curso: formacao.curso || "",
                                    grauEscolaridade: formacao.grauEscolaridade?.descricao || "",
                                    id: formacao.id,
                                    instituicao: formacao.instituicaoEnsino || "",
                                    situacao: formacao.situacaoFormacaoAcademica?.descricao || "",
                                    terminoOuPrevisao: formacao.dataTermino || undefined,
                                    frenteCertificadoFormacaoAcademica: detalheCadastro.anexos?.find(
                                        (a) =>
                                            a.idTipoAnexo === IdTipoAnexo.CertificadoAcademicoFrente &&
                                            a.idFormacaoAcademica === formacao.id
                                    ),
                                    versoCertificadoFormacaoAcademica: detalheCadastro.anexos?.find(
                                        (a) =>
                                            a.idTipoAnexo === IdTipoAnexo.CertificadoAcademicoVerso &&
                                            a.idFormacaoAcademica === formacao.id
                                    ),
                                })) || []
                            }
                        />
                        <ExperienciaProfissional
                            urlLinkedIn={detalheCadastro.urlRedeSocialProfissional || ""}
                            experiencias={
                                detalheCadastro.experienciasProfissionais?.map((exp) => ({
                                    atualmenteNaEmpresa: !exp.dataTermino,
                                    autonomo: exp.autonomo,
                                    cargo: exp.cargo || "",
                                    descricao: exp.descricao || "",
                                    empresa: exp.empresa || "",
                                    id: exp.id,
                                    inicio: exp.dataInicio || undefined,
                                    termino: exp.dataTermino || undefined,
                                })) || []
                            }
                        />
                        <Endereco
                            cep={detalheCadastro.endereco?.cep || undefined}
                            logradouro={detalheCadastro.endereco?.logradouro || undefined}
                            numero={detalheCadastro.endereco?.numero || undefined}
                            complemento={detalheCadastro.endereco?.complemento || undefined}
                            cidade={detalheCadastro.endereco?.cidade || undefined}
                            uf={detalheCadastro.endereco?.uf || undefined}
                            comprovanteEndereco={detalheCadastro.anexos?.find(
                                (a) => a.idTipoAnexo === IdTipoAnexo.ComprovanteEndereco
                            )}
                        />
                        <div className={styles.botoesForm}>
                            <ButtonSecondary onClick={voltar}>Voltar</ButtonSecondary>
                            {analiseInconsistencias && (
                                <Loading promise={promiseInconsistencias}>
                                    {(inconsistencias) =>
                                        inconsistencias ? (
                                            inconsistencias.some((inc) => inc.pendente) ? (
                                                <ButtonPrimary
                                                    onClick={async () => {
                                                        await ActionFeedback.processing({
                                                            title: "Salvando inconsistências",
                                                            execution: terminarAnalise(idCadastro),
                                                        });
                                                        await ActionFeedback.info({ title: "Análise finalizada" });
                                                        voltar();
                                                    }}
                                                >
                                                    Salvar inconsistências
                                                </ButtonPrimary>
                                            ) : (
                                                <ButtonPrimary
                                                    className={styles.botaoAprovarCadastro}
                                                    onClick={async () => {
                                                        var res = await exibirModalAprovarCadastro(idCadastro);

                                                        if (res) {
                                                            await ActionFeedback.info({ title: "Cadastro aprovado" });
                                                            voltar();
                                                        }
                                                    }}
                                                >
                                                    Aprovar cadastro
                                                </ButtonPrimary>
                                            )
                                        ) : (
                                            <></>
                                        )
                                    }
                                </Loading>
                            )}
                        </div>
                    </DetalhesCadastroInfoContext.Provider>
                ) : (
                    <></>
                )
            }
        </Loading>
    );
};
