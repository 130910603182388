import { dateToDDMMYYYY } from "lib/time";
import React from "react";
import { Anexo } from "services/onboarding/models/anexo";
import { IdEtapaCadastro } from "services/onboarding/models/id-etapa-cadastro";
import { CampoCadastro, CampoImagem } from "../campo-cadastro/campo-cadastro";
import { CardCadastro } from "../card-cadastro/card-cadastro";
import styles from "./dados-do-rg.module.scss";

export interface DadosDoRGProps {
    documentoIdentidade: string;
    orgaoExpedicaoDocumentoIdentidade: string;
    dataExpedicaoDocumentoIdentidade?: Date;
    ufDocumentoIdentidade: string;
    frenteRG?: Anexo;
    versoRG?: Anexo;
}

const etapa = { descricao: "Dados do RG", id: IdEtapaCadastro.DadosRG };

export const DadosDoRG = ({
    documentoIdentidade,
    orgaoExpedicaoDocumentoIdentidade,
    dataExpedicaoDocumentoIdentidade,
    ufDocumentoIdentidade,
    frenteRG,
    versoRG,
}: DadosDoRGProps) => {
    return (
        <CardCadastro
            header={etapa.descricao}
            body={
                <div className={styles.dadosDoRg}>
                    <div>
                        <CampoCadastro
                            rotulo="Documento de Identidade"
                            valor={documentoIdentidade}
                            nome="documentoIdentidade"
                        />
                        <CampoCadastro
                            rotulo="Órgão Expedidor"
                            valor={orgaoExpedicaoDocumentoIdentidade}
                            nome="orgaoExpedicaoDocumentoIdentidade"
                        />
                        <CampoCadastro
                            rotulo="Data da Expedição"
                            valor={dataExpedicaoDocumentoIdentidade?.toString()}
                            nome="dataExpedicaoDocumentoIdentidade"
                            format={(dataExpedicao?: string) =>
                                dataExpedicao ? dateToDDMMYYYY(new Date(dataExpedicao)) : ""
                            }
                        />
                        <CampoCadastro rotulo="Estado" valor={ufDocumentoIdentidade} nome="ufDocumentoIdentidade" />
                    </div>
                    <CampoImagem rotulo="Frente RG" anexo={frenteRG} />
                    <CampoImagem rotulo="Verso RG" anexo={versoRG} />
                </div>
            }
            etapa={etapa}
        />
    );
};
