import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { PrestadorFiltroService } from "services/filtros/filtros-prestador-service";
import { FiltroQualificacao } from "services/filtros/models/filtro-qualificacao";
import { OmnijusSelectField, SelectObject } from "shared/form/fields/omnijus-select-field";
import { Loading } from "shared/loading/loading";

export const OmnijusCampoQualificacaoPrestador = (props: { hideLabel?: boolean; name: string; label?: string }) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<FiltroQualificacao[] | undefined>>();
    const [,, helpers] = useField({ name: props.name });

    useEffect(() => {
        setPromiseOpcoes(PrestadorFiltroService.listarQualificacao());
    }, []);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    name={props.name}
                    label={props.hideLabel ? undefined : props.label || "Escritório"}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.descricao || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                    onChange={(o) => {
                        let aux = o as SelectObject;
                        helpers.setValue(parseInt(aux?.value) || "");
                    }}
                />
            )}
        </Loading>
    );
};
