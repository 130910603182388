import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as EditIcon } from "assets/images/edit-pen.svg";
import { ReactComponent as DeleteIcon } from "assets/images/trash-icon.svg";
import { FieldArray, Form, useField } from "formik";
import { dateToMMYYYY } from "lib/time";
import React from "react";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { OmnijusDateField } from "shared/form/fields/omnijus-date-field";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import { showModalForm } from "shared/form/modal-form/modal-form";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import { date as YupDate, object as YupObject, string as YupString } from "yup";
import styles from "../cadastro-prestadores.module.scss";

export interface ExperienciaProfissional {
    id?: string;
    nomeEmpresa: string;
    nomeCargo: string;
    dataInicio?: Date;
    dataTermino?: Date;
    descricaoAtividades: string;
}

export const experienciaProfissionalValidation = YupObject().shape({
    nomeEmpresa: YupString().required("Informe o nome da empresa"),
    nomeCargo: YupString().required("Informe o nome do cargo"),
    dataInicio: YupDate()
        .required("Informe a data de início")
        .typeError("Informe a data de início")
        .max(new Date(), "A data não pode ser no futuro")
        .nullable(),
    dataTermino: YupDate()
        .when(["dataInicio"], (di: Date, schema: any) =>
            di ? schema.min(di, "A data de término deve ser posterior à de início") : schema
        )
        .max(new Date(), "A data não pode ser no futuro")
        .nullable(),
    descricaoAtividades: YupString().required("Informe a descrição"),
});

export const modalNovaExperienciaProfissional = (nomeAcao: string, valorInicial: ExperienciaProfissional) =>
    showModalForm(
        nomeAcao,
        valorInicial,
        <Form className={styles.modalForm}>
            <OmnijusTextField name="nomeEmpresa" label="Empresa" />
            <OmnijusTextField name="nomeCargo" label="Cargo" />
            <OmnijusDateField name="dataInicio" label="Início" monthOnly />
            <OmnijusDateField name="dataTermino" label="Término" monthOnly />
            <OmnijusTextField name="descricaoAtividades" label="Descrição" />
            <div className={styles.linhaSubmit}>
                <ButtonPrimary type="submit">{nomeAcao}</ButtonPrimary>
            </div>
        </Form>,
        "60rem",
        experienciaProfissionalValidation
    );

export const ExperienciasProfissionais = ({
    values,
}: {
    values: { experienciasProfissionais: ExperienciaProfissional[] };
}) => {
    const name = "experienciasProfissionais";
    const [, meta] = useField({ name });
    return (
        <OmnijusCard
            header={<h3>Experiência Profissional</h3>}
            body={
                <FieldArray name={name}>
                    {(arrayHelpers) => (
                        <>
                            <table className={styles.table}>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col className={styles.colData} />
                                    <col className={styles.colData} />
                                    <col />
                                    <col className={styles.colIcones} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <td>Empresa</td>
                                        <td>Cargo</td>
                                        <td>Início</td>
                                        <td>Término</td>
                                        <td>Descrição</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {values.experienciasProfissionais.map((experiencia, idx) => (
                                        <tr key={idx}>
                                            <td>{experiencia.nomeEmpresa}</td>
                                            <td>{experiencia.nomeCargo}</td>
                                            <td>{experiencia.dataInicio && dateToMMYYYY(experiencia.dataInicio)}</td>
                                            <td>{experiencia.dataTermino && dateToMMYYYY(experiencia.dataTermino)}</td>
                                            <td>{experiencia.descricaoAtividades}</td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className={styles.botaoIcone}
                                                    onClick={async () => {
                                                        const novaFormacaoExpProfissional = await modalNovaExperienciaProfissional(
                                                            "Editar Experiência Profissional",
                                                            experiencia
                                                        );
                                                        novaFormacaoExpProfissional &&
                                                            arrayHelpers.replace(idx, novaFormacaoExpProfissional);
                                                    }}
                                                >
                                                    <EditIcon title="Ícone de editar Experiência Profissional" />
                                                </button>
                                                <button
                                                    type="button"
                                                    className={styles.botaoIcone}
                                                    onClick={() => {
                                                        arrayHelpers.remove(idx);
                                                    }}
                                                >
                                                    <DeleteIcon title="Ícone de excluir Experiência Profissional" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <button
                                type="button"
                                className={styles.botaoIcone}
                                onClick={async () => {
                                    const novaExperienciaProfissional = await modalNovaExperienciaProfissional(
                                        "Adicionar Experiência Profissional",
                                        {
                                            nomeEmpresa: "",
                                            nomeCargo: "",
                                            dataInicio: undefined,
                                            dataTermino: undefined,
                                            descricaoAtividades: "",
                                        }
                                    );
                                    novaExperienciaProfissional && arrayHelpers.push(novaExperienciaProfissional);
                                }}
                            >
                                <AddIcon title="Ícone de adicionar novo meio de comunicação" />
                            </button>
                            {meta.touched && meta.error && (
                                <div className={styles.error}>
                                    {Array.isArray(meta.error)
                                        ? meta.error.map((er) => Object.values(er).join(","))
                                        : typeof meta.error === "object"
                                        ? Object.values(meta.error).join(",")
                                        : meta.error}
                                </div>
                            )}
                        </>
                    )}
                </FieldArray>
            }
        />
    );
};
