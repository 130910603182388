import { NomesTiposParametros, RequestApi } from "@omnijus/common";
import { IdStatusConvite } from "./id-status-convite";

export interface ParametrosPesquisaConvite {
    Nome?: string;
    Cpf?: string;
    Email?: string;
    Telefone?: string;
    Status?: IdStatusConvite;
    DataInicio?: Date;
    DataFim?: Date;
}

const tiposParametros: NomesTiposParametros<Required<ParametrosPesquisaConvite>> = {
    Nome: "string",
    Cpf: "string",
    Email: "string",
    Telefone: "string",
    Status: "number",
    DataInicio: "Date",
    DataFim: "Date",
};

export const parseParametrosPesquisaConvite = (queryString: string): ParametrosPesquisaConvite =>
    RequestApi.parseQueryString(queryString, tiposParametros);
