import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import { AtividadeService } from "services/atividade/atividade-service";
import { ListarAtividades } from "services/atividade/models/listar-atividades";
import { CardFiltros } from "shared/form/card-filtros/card-filtros";
import { OmnijusFiltroArea } from "shared/form/custom-fields/omnijus-filtro-area";
import { OmnijusFiltroAtividadeBusca } from "shared/form/custom-fields/omnijus-filtro-atividade-busca";
import { OmnijusFiltroStatusTemplate } from "shared/form/custom-fields/omnijus-filtro-status-template";
import { OmnijusFiltroTipoAtividade } from "shared/form/custom-fields/omnijus-filtro-tipo-atividade";
import { ValueType } from "shared/form/fields/omnijus-select-with-create";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import * as Yup from "yup";
import styles from "./listar-atividades.module.scss";

enum formNames {
    area = "IdArea",
    atividade = "Atividade",
    status = "EstaAtivo",
    tipoAtividade = "IdTipoAtividade",
}

const FiltrosListagemAtividades = () => {
    const formikValues = useFormikContext<Record<string, number>>().values;
    const idArea = formikValues[formNames.area];
    const idTipoAtividade = formikValues[formNames.tipoAtividade];

    return (
        <>
            <OmnijusFiltroAtividadeBusca
                label="Atividade"
                name={formNames.atividade}
                idArea={idArea}
                IdTipoAtividade={idTipoAtividade}
            />
            <OmnijusFiltroArea name={formNames.area} />
            <OmnijusFiltroTipoAtividade label="Tipo de Atividade" name={formNames.tipoAtividade} />
            <OmnijusFiltroStatusTemplate label="Status" name={formNames.status} />
        </>
    );
};

interface ParametrosFiltros {
    IdArea?: number;
    Atividade?: ValueType;
    EstaAtivo?: boolean;
    IdTipoAtividade?: number;
}

export const ListagemAtividades = () => {
    const history = useHistory();
    const initialValues: ParametrosFiltros = {};
    const validationSchema = Yup.object().shape({});
    const [promiseListaAtividades, setPromiseListaAtividades] = useState<Promise<ListarAtividades[] | undefined>>();
    useEffect(() => {
        setPromiseListaAtividades(AtividadeService.listarAtividades({ EstaAtivo: true }));
    }, []);

    return (
        <div className={styles.listarConfigTemplates}>
            <h2>Atividades</h2>
            <CardFiltros
                onBuscar={(values) => {
                    setPromiseListaAtividades(
                        AtividadeService.listarAtividades({
                            "Atividade.Id": values.Atividade?.id,
                            "Atividade.Descricao": values.Atividade?.text,
                            EstaAtivo: values.EstaAtivo,
                            IdArea: values.IdArea,
                            IdTipoAtividade: values.IdTipoAtividade,
                        })
                    );
                }}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                <FiltrosListagemAtividades />
            </CardFiltros>

            <div className={styles.adicionar}>
                <Link to="/atividades/criar">Adicionar Atividade</Link>
            </div>

            <Loading promise={promiseListaAtividades}>
                {(politicasTemplate) => (
                    <OmnijusCard
                        body={
                            <DataTable
                                noHeader={true}
                                customStyles={{
                                    rows: {
                                        style: {
                                            cursor: "pointer",
                                        },
                                    },
                                }}
                                pagination={true}
                                paginationPerPage={20}
                                noDataComponent={<p>Nenhum registro encontrado!</p>}
                                onRowClicked={(ativ) => history.push(`/atividades/${ativ.idAtividade}`)}
                                data={politicasTemplate || []}
                                columns={[
                                    {
                                        name: "Atividade",
                                        selector: (row) => row.descricaoAtividade,
                                        sortable: true,
                                    },
                                    {
                                        name: "Área",
                                        selector: (row) =>
                                            row.areaAtividades.map((area) => area.descricaoArea).join(", "),
                                        maxWidth: "10rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Tipo de atividade",
                                        selector: (row) => row.descricaoTipoAtividade,
                                        sortable: true,
                                    },
                                    {
                                        name: "Status",
                                        selector: (row) => (row.estaAtivo ? "Ativa" : "Inativa"),
                                        maxWidth: "5rem",
                                        sortable: true,
                                    },
                                ]}
                            />
                        }
                    />
                )}
            </Loading>
        </div>
    );
};
