import { ReactComponent as EditPen } from "assets/images/edit-pen.svg";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import { ParametrosListarPoliticaTemplate } from "services/politica-template/listar-politica-template-parametros";
import { PoliticaTemplate } from "services/politica-template/models/politica-template";
import { PoliticaTemplateService } from "services/politica-template/politica-template-service";
import { CardFiltros } from "shared/form/card-filtros/card-filtros";
import { OmnijusFiltroArea } from "shared/form/custom-fields/omnijus-filtro-area";
import { OmnijusFiltroAtividade } from "shared/form/custom-fields/omnijus-filtro-atividade";
import { OmnijusFiltroCliente } from "shared/form/custom-fields/omnijus-filtro-cliente";
import { OmnijusFiltroContrato } from "shared/form/custom-fields/omnijus-filtro-contrato";
import { OmnijusFiltroEmpresa } from "shared/form/custom-fields/omnijus-filtro-empresa";
import { OmnijusFiltroStatusTemplate } from "shared/form/custom-fields/omnijus-filtro-status-template";
import { OmnijusFiltroTemplate } from "shared/form/custom-fields/omnijus-filtro-template";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import * as Yup from "yup";
import styles from "./listar-config-templates.module.scss";

enum formNames {
    cliente = "IdCliente",
    empresa = "IdContratoEmpresa",
    contrato = "IdContrato",
    area = "IdArea",
    atividade = "IdAtividade",
    template = "IdEntrevistaGuiada",
    status = "EstaAtivo",
}

const FiltrosListagemConfigTemplates = () => {
    const idArea = useFormikContext<Record<string, number>>().values[formNames.area];

    return (
        <>
            <OmnijusFiltroCliente label="Cliente/Razão Social" name={formNames.cliente} />
            <OmnijusFiltroContrato label="Contrato" name={formNames.contrato} />
            <OmnijusFiltroEmpresa
                label="Empresa/Razão Social"
                name={formNames.empresa}
                reloadOnChangeName={formNames.contrato}
            />
            <div />
            <OmnijusFiltroArea name={formNames.area} />
            <OmnijusFiltroAtividade label="Atividade" name={formNames.atividade} idArea={idArea} />
            <OmnijusFiltroTemplate label="Template" name={formNames.template} />
            <OmnijusFiltroStatusTemplate label="Status" name={formNames.status} />
        </>
    );
};

export const ListagemConfigTemplates = () => {
    const history = useHistory();
    const initialValues: ParametrosListarPoliticaTemplate = {};
    const validationSchema = Yup.object().shape({
        [formNames.cliente]: Yup.string(),
        [formNames.empresa]: Yup.string(),
    });
    const [promisePoliticasTemplate, setPromisePoliticasTemplate] = useState<Promise<PoliticaTemplate[] | undefined>>();
    useEffect(() => {
        setPromisePoliticasTemplate(PoliticaTemplateService.listarPoliticaTemplate({ EstaAtivo: true }));
    }, []);

    return (
        <div className={styles.listarConfigTemplates}>
            <h2>Configurações de Template para o Cliente</h2>
            <CardFiltros
                onBuscar={(values) => {
                    setPromisePoliticasTemplate(PoliticaTemplateService.listarPoliticaTemplate(values));
                }}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                <FiltrosListagemConfigTemplates />
            </CardFiltros>

            <div className={styles.adicionar}>
                <Link to="/configuracao-templates/criar">Adicionar Configuração</Link>
            </div>

            <Loading promise={promisePoliticasTemplate}>
                {(politicasTemplate) => (
                    <OmnijusCard
                        body={
                            <DataTable
                                noHeader={true}
                                customStyles={{
                                    rows: {
                                        style: {
                                            cursor: "pointer",
                                        },
                                    },
                                }}
                                pagination={true}
                                paginationPerPage={20}
                                noDataComponent={<p>Nenhum registro encontrado!</p>}
                                onRowClicked={(p) => {
                                    history.push(`/configuracao-templates/${p.idPoliticaTemplate}`);
                                }}
                                data={politicasTemplate || []}
                                columns={[
                                    {
                                        name: "Contrato",
                                        selector: (row) => row.contrato.numero,
                                        maxWidth: "5rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Cliente",
                                        selector: (row) => row.cliente.razaoSocial,
                                        sortable: true,
                                    },
                                    {
                                        name: "Área",
                                        selector: (row) => row.area.descricaoArea,
                                        maxWidth: "5rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Empresa",
                                        selector: (row) =>
                                            row.empresas
                                                ?.filter((em) => em.razaoSocial)
                                                .map((em) => em.razaoSocial)
                                                .join(", "),
                                        sortable: true,
                                        minWidth: "45rem",
                                    },
                                    {
                                        name: "Status",
                                        selector: (row) => (row.estaAtivo ? "Ativo" : "Inativo"),
                                        maxWidth: "3rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "",
                                        button: true,
                                        cell: (row) => (
                                            <Link
                                                className={styles.editarConfig}
                                                to={`configuracao-templates/editar/${row.idPoliticaTemplate}`}
                                            >
                                                <EditPen />
                                            </Link>
                                        ),
                                        maxWidth: "3rem",
                                        minWidth: "3rem",
                                    },
                                ]}
                            />
                        }
                    />
                )}
            </Loading>
        </div>
    );
};
