import { ReactComponent as SendIcon } from "assets/images/send-icon.svg";
import { ActionFeedback } from "@omnijus/common";
import { Format } from "lib/format";
import { PromiseState } from "@omnijus/common";
import { RequestApi } from "@omnijus/common";
import { dateToDDMMYYYY, getTimeHHmm } from "lib/time";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { useHistory, useLocation } from "react-router-dom";
import {
    enviaConviteFilaLead,
    enviaConvitesListaFilaLeads,
    getPesquisaFilaLeads,
} from "services/onboarding/fila-leads-service";
import { FilaLead } from "services/onboarding/models/fila-leads";
import {
    ParametrosPesquisaConvite,
    parseParametrosPesquisaConvite,
} from "services/onboarding/models/parametros-pesquisa-convite";
import { CardFiltros } from "shared/form/card-filtros/card-filtros";
import { OmnijusCampoCelular } from "shared/form/custom-fields/omnijus-campo-celular";
import { OmnijusCampoCPF } from "shared/form/custom-fields/omnijus-campo-cpf";
import { OmnijusCampoStatusFilaLeads } from "shared/form/custom-fields/omnijus-campo-status-fila-leads";
import { OmnijusRangeDateField } from "shared/form/fields/omnijus-range-date-field";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import { FiltrosAplicados } from "shared/form/filtros-aplicados/filtros-aplicados";
import { ValoresComboContext, ValoresCombos } from "shared/form/valores-combo-context";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import Swal from "sweetalert2";
import { downloadExportar } from "./download-exportar";
import styles from "./listagem-fila-leads.module.scss";

export const ListagemFilaLeads = () => {
    const query = useLocation().search;
    const history = useHistory();
    const [selecionados, setSelecionados] = useState<FilaLead[]>([]);
    const [promiseListaLeads, setPromiseListaLeads] = useState<Promise<FilaLead[] | undefined>>();
    const parametrosBusca = useMemo(() => parseParametrosPesquisaConvite(query), [query]);
    const [infoFiltros, setInfoFiltros] = useState<ValoresCombos>({
        IdStatus: { label: "Status" },
        DataInicio: { label: "Data inicial de cadastro", format: dateToDDMMYYYY },
        DataFim: { label: "Data final de cadastro", format: dateToDDMMYYYY },
    });

    const buscar = useCallback(
        (filtros: ParametrosPesquisaConvite) => {
            // TODO (inserir método format:string=>TipoParametro no filtro select)
            const search = RequestApi.objectToQueryString(filtros);
            history.push({
                search,
            });
        },
        [history]
    );

    const atualiza = useCallback(() => {
        if (parametrosBusca) {
            const promise = getPesquisaFilaLeads({
                ...parametrosBusca,
                Telefone: parametrosBusca.Telefone?.replace(/\D/g, ""),
            });
            setPromiseListaLeads(promise);
            return promise;
        }
    }, [parametrosBusca]);

    useEffect(() => {
        atualiza();
    }, [atualiza]);

    const columns: IDataTableColumn<FilaLead>[] = useMemo(
        () => [
            {
                name: "Nome",
                selector: (l) => l.nome,
                sortable: true,
            },
            {
                name: "CPF",
                selector: (l) => l.cpf,
                maxWidth: "10rem",
            },
            {
                name: "Email",
                selector: (l) => l.email,
            },
            {
                name: "Telefone",
                selector: (l) => l.telefone,
                maxWidth: "10rem",
                format: (l) => l.telefone && Format.formatCellphoneNumber(l.telefone),
            },
            {
                name: "Data de cadastro",
                selector: (l) => l.dataCadastro,
                sortable: true,
                format: (l) =>
                    l.dataCadastro ? (
                        <>
                            <span>{dateToDDMMYYYY(l.dataCadastro)}</span>
                            <span className={styles.horaAlteracao}>{getTimeHHmm(l.dataCadastro)}</span>
                        </>
                    ) : (
                        ""
                    ),
                maxWidth: "12rem",
            },
            {
                name: " ",
                selector: (l) => l.possuiConvite,
                format: (l) =>
                    l.possuiConvite ? (
                        <div className={styles.labelConviteEnviado}>Convite Enviado</div>
                    ) : (
                        <button
                            title="Enviar convite"
                            className={styles.botaoEnviarConvite}
                            onClick={async () => {
                                await ActionFeedback.processing({
                                    title: "Enviando convite",
                                    execution: enviaConviteFilaLead(l),
                                });
                                await ActionFeedback.info({ title: "Convite enviado" });
                                await atualiza();
                            }}
                        >
                            <SendIcon />
                        </button>
                    ),
                maxWidth: "7rem",
                minWidth: "7rem",
                sortable: true,
                center: true,
            },
        ],
        [atualiza]
    );

    return (
        <div className={styles.listagemFilaLeads}>
            <h2>Fila de Leads</h2>
            <ValoresComboContext.Provider value={{ valoresCombos: infoFiltros, setValoresCombos: setInfoFiltros }}>
                <CardFiltros
                    onBuscar={async (filtros) => {
                        if (!promiseListaLeads || !(await PromiseState.isPending(promiseListaLeads))) {
                            buscar(filtros);
                        } else {
                            Swal.fire({
                                icon: "warning",
                                text: "Uma busca já está sendo realizada no momento! Aguarde...",
                            });
                        }
                    }}
                    initialValues={parametrosBusca}
                >
                    <OmnijusTextField name="Nome" label="Nome" />
                    <OmnijusCampoCPF name="Cpf" label="CPF" />
                    <OmnijusTextField name="Email" label="Email" />
                    <OmnijusCampoCelular name="Telefone" label="Telefone" />
                    <OmnijusCampoStatusFilaLeads name="Status" label="Status" />
                    <OmnijusRangeDateField nameDe="DataInicio" nameAte="DataFim" label="Data de cadastro" />
                </CardFiltros>
                <div className={styles.barraAcao}>
                    <div className={styles.filtrosAplicados}>
                        {Object.entries(parametrosBusca).length > 0 && (
                            <FiltrosAplicados
                                parametros={parametrosBusca}
                                onRemove={(nome) => buscar({ ...parametrosBusca, [nome]: undefined })}
                            />
                        )}
                    </div>
                    <div className={styles.botoesAcao}>
                        <button
                            onClick={async () => {
                                if (promiseListaLeads) {
                                    const leads = await ActionFeedback.processing({
                                        title: "Buscando fila de leads",
                                        execution: promiseListaLeads,
                                    });
                                    if (leads) {
                                        downloadExportar(leads, "csv");
                                    }
                                }
                            }}
                        >
                            Exportar CSV
                        </button>
                        <button
                            onClick={async () => {
                                if (selecionados && selecionados.length > 0) {
                                    const result = await ActionFeedback.processing({
                                        title: "Enviando convites",
                                        execution: enviaConvitesListaFilaLeads(selecionados),
                                    });
                                    if (result && result.quantidadeDeErros === 0) {
                                        await ActionFeedback.info({ title: "Todos os convites foram enviados" });
                                    } else {
                                        await ActionFeedback.error({
                                            title:
                                                !result || result.quantidadeDeConvitesEnviados === 0
                                                    ? "Nenhum convite pode ser enviado"
                                                    : `${result.quantidadeDeErros} convites não puderam ser enviados`,
                                        });
                                    }
                                    await atualiza();
                                } else {
                                    await ActionFeedback.error({ title: "Selecione os leads para enviar convites" });
                                }
                            }}
                        >
                            Enviar Convite
                        </button>
                    </div>
                </div>
            </ValoresComboContext.Provider>
            <OmnijusCard
                className={styles.tabelaListagem}
                body={
                    <Loading promise={promiseListaLeads}>
                        {(listaLeads) =>
                            listaLeads ? (
                                <DataTable
                                    selectableRows
                                    selectableRowDisabled={(filaLead) => filaLead.possuiConvite}
                                    onSelectedRowsChange={(a) => setSelecionados(a.selectedRows)}
                                    noHeader={true}
                                    pagination={true}
                                    paginationPerPage={20}
                                    noDataComponent={<p>Nenhum registro encontrado!</p>}
                                    columns={columns}
                                    data={listaLeads}
                                    customStyles={{
                                        rows: {
                                            style: {
                                                minHeight: "80px",
                                                padding: "0 20px",
                                            },
                                        },
                                        head: {
                                            style: {
                                                padding: "0 20px",
                                            },
                                        },
                                    }}
                                />
                            ) : (
                                <></>
                            )
                        }
                    </Loading>
                }
            />
        </div>
    );
};
