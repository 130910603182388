import { ReactComponent as ErrorIconFilled } from "assets/images/error-icon-filled.svg";
import classNames from "classnames";
import React from "react";
import { EtapaCadastro } from "services/onboarding/models/etapa-cadastro";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import { useDetalhesCadastroInfoContext } from "../detalhes-cadasatro-info-context";
import { EtapaCadastroContext } from "../etapa-cadastro-context";
import styles from "./card-cadastro.module.scss";
export interface CardCadastroProps {
    header: React.ReactNode;
    body: React.ReactNode;
    etapa?: EtapaCadastro;
}

enum Cor {
    Vermelho,
    Amarelo,
}

const IconeInconsistencia = ({ cor }: { cor: Cor }) => {
    return (
        <div className={styles.iconeInconsistencia}>
            {cor === Cor.Vermelho ? (
                <ErrorIconFilled title="ícone de inconsistência" className={styles.iconeVermelho} />
            ) : (
                <ErrorIconFilled title="ícone de inconsistência" className={styles.iconeAmarelo} />
            )}
        </div>
    );
};

export const CardCadastro = ({ header, body, etapa }: CardCadastroProps) => {
    const { inconsistencias, dataUltimoEmAnalise } = useDetalhesCadastroInfoContext();
    const alertaVermelho = etapa && inconsistencias?.some((inc) => inc.pendente && inc.idEtapaCadastro === etapa.id);
    const alertaAmarelo =
        etapa &&
        inconsistencias?.some(
            (inc) =>
                dataUltimoEmAnalise &&
                inc.dataCriacao.getTime() >= dataUltimoEmAnalise.getTime() &&
                inc.idEtapaCadastro === etapa.id
        );
    return (
        <div className={styles.cardCadastro}>
            <EtapaCadastroContext.Provider value={etapa}>
                <OmnijusCard
                    cardClassName={classNames({
                        [styles.fundoVermelho]: alertaVermelho,
                        [styles.fundoAmarelo]: alertaAmarelo && !alertaVermelho,
                    })}
                    header={
                        <div className={styles.headerCardCadastro}>
                            {header}
                            {etapa && (alertaVermelho || alertaAmarelo) && (
                                <IconeInconsistencia cor={alertaVermelho ? Cor.Vermelho : Cor.Amarelo} />
                            )}
                        </div>
                    }
                    body={<div className={styles.bodyCardCadastro}>{body}</div>}
                />
            </EtapaCadastroContext.Provider>
        </div>
    );
};
