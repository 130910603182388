import React from "react";
import { CardsCadastro } from "./cards-cadastro/cards-cadastro";
import { CardsTotais } from "./cards-totais/cards-totais";
import styles from "./dashboard-onboarding.module.scss";
import { TabelaEmCadastro } from "./tabela-em-cadastro/tabela-em-cadastro";

export const DashboardOnboarding = () => {
    return (
        <div className={styles.dashboardOnboarding}>
            <h2>Dashboard Onboarding Legal Ops</h2>
            <div className={styles.dashboardGrid}>
                <CardsCadastro />
                <TabelaEmCadastro />
                <CardsTotais />
            </div>
        </div>
    );
};
