import { NomesTiposParametros, RequestApi } from "@omnijus/common";
import { IdEtapaCadastro } from "./id-etapa-cadastro";
import { IdStatusCadastro } from "./id-status-cadastro";

export interface ParametrosPesquisarCadastro {
    Nome?: string;
    Cpf?: string;
    Email?: string;
    Telefone?: string;
    IdStatus?: IdStatusCadastro;
    IdEtapa?: IdEtapaCadastro;
    DataInicio?: Date;
    DataFim?: Date;
}

const tiposParametros: NomesTiposParametros<Required<ParametrosPesquisarCadastro>> = {
    Nome: "string",
    Cpf: "string",
    Email: "string",
    Telefone: "string",
    IdStatus: "number",
    IdEtapa: "number",
    DataInicio: "Date",
    DataFim: "Date",
};

export const parseParametrosPesquisarCadastro = (queryString: string): ParametrosPesquisarCadastro =>
    RequestApi.parseQueryString(queryString, tiposParametros);
