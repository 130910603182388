import { useField } from "formik";
import React from "react";
import fieldStyles from "../field.module.scss";
import styles from "./omnijus-picture-field.module.scss";
import { ReactComponent as PublishIcon } from "assets/images/publish-icon.svg";
import { ReactComponent as TrashIcon } from "assets/images/trash-icon.svg";
import { ActionFeedback } from "@omnijus/common";
import { useState } from "react";
import { useEffect } from "react";

const gray1x1 =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAAANSURBVBhXYzh8+PB/AAffA0nNPuCLAAAAAElFTkSuQmCC";

const allowedFileExtensions = ["image/png", "image/jpeg"];

export const OmnijusPictureField = (props: {
    title: string;
    name: string;
    width: number;
    height: number;
    loadImageText?: string;
    maxSizeMB?: number;
}) => {
    const [field, meta, helpers] = useField<{ dataUrl?: Blob }>(props.name);
    const [base64, setBase64] = useState(gray1x1);

    useEffect(() => {
        if (field.value.dataUrl) {
            const fr = new FileReader();
            fr.onload = () => {
                setBase64(fr.result as string);
            };
            fr.readAsDataURL(field.value.dataUrl);
        } else {
            setBase64(gray1x1);
        }
    }, [field.value.dataUrl]);

    return (
        <div className={fieldStyles["field-control"]}>
            <div className={styles.center}>
                <img alt={props.title} src={base64} width={props.width} height={props.height}></img>
                {field.value && field.value.dataUrl ? (
                    <button
                        type="button"
                        className={styles.button}
                        onClick={() => helpers.setValue({ dataUrl: undefined })}
                    >
                        Remover <TrashIcon title="Ícone de remover foto" />
                    </button>
                ) : (
                    <label className={styles.addImageLabel}>
                        <input
                            className={fieldStyles.invisible}
                            type="file"
                            accept={allowedFileExtensions.join(", ")}
                            onChange={(e) => {
                                const files = e.target.files;
                                if (files && files.length > 0) {
                                    if (props.maxSizeMB) {
                                        const size = files[0].size / 1024 / 1024;
                                        if (size > props.maxSizeMB) {
                                            ActionFeedback.error({
                                                title: `O tamanho do arquivo excede ${props.maxSizeMB}MB`,
                                            });
                                            return;
                                        }
                                    }
                                    if (!allowedFileExtensions.find((e) => e === files[0].type)) {
                                        ActionFeedback.error({
                                            title: `O tipo do arquivo deve ser ${allowedFileExtensions.join(" ou ")}`,
                                        });
                                        return;
                                    }

                                    helpers.setValue({ dataUrl: files[0] });
                                }
                            }}
                        />
                        {props.loadImageText || "Carregar imagem"}
                        <PublishIcon className={styles.addImageIcon} title="Ícone de carregar imagem" />
                    </label>
                )}
            </div>
            {meta.touched && meta.error ? <p className={fieldStyles["error"]}>{meta.error}</p> : null}
        </div>
    );
};
