import { AdicionarAtividade } from "pages/atividades/adicionar-atividade/adicionar-atividade";
import { DetalharAtividade } from "pages/atividades/detalhar-atividade/detalhar-atividade";
import { ListagemAtividades } from "pages/atividades/listar-atividades/listar-atividades";
import { AdicionarCadastroPrestador } from "pages/cadastro-prestadores/adicionar-cadastro-prestador/adicionar-cadastro-prestador";
import { DetalharCadastroPrestadorPF } from "pages/cadastro-prestadores/detalhar-cadastro-prestador/detalhar-cadastro-prestador-pf";
import { DetalharCadastroPrestadorPJ } from "pages/cadastro-prestadores/detalhar-cadastro-prestador/detalhar-cadastro-prestador-pj";
import { ListagemCadastroLegalOps } from "pages/cadastro-prestadores/listagem-cadastro-prestadores";
import { CriarEditarConfigTemplateCliente } from "pages/configuracao-templates/criar-editar-config-template/criar-editar-config-template";
import { DetalharConfigTemplate } from "pages/configuracao-templates/detalhar-config-template/detalhar-config-template";
import { ListagemConfigTemplates } from "pages/configuracao-templates/listar-config-templates";
import { DetalheCadastro } from "pages/onboarding/cadastro/detalhe-cadastro/detalhe-cadastro";
import { ListagemCadastros } from "pages/onboarding/cadastro/listagem-cadastros/listagem-cadastros";
import { ListagemConvites } from "pages/onboarding/convites/listar-convites/listar-convites";
import { DashboardOnboarding } from "pages/onboarding/dashboard/dashboard-onboarding";
import { ListagemFilaLeads } from "pages/onboarding/fila-leads/listagem-fila-leads";
import { GerarPendenciaPagamento } from "pages/pendencias/gerar-pendencia-pagamento";
import { ListarPendencias } from "pages/pendencias/listar-pendencias";
import { CriarEditarTemplate } from "pages/templates/criar-editar-template/criar-editar-template";
import { DetalharTemplate } from "pages/templates/detalhar-template/detalhar-template";
import { ListagemTemplates } from "pages/templates/listar-templates/listar-templates";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Error404 } from "shared/error-404/error-404";
import { Navigation } from "./shared/navigation/navigation";
import { Parametros } from "pages/configuracoes/parametros/parametros";
import { RotaControleAcesso } from "shared/navigation/acessos-helper";
import { ListarDeadLetter } from "pages/dead-letter/listar-dead-letter";
import { DetalharDeadLetter } from "pages/dead-letter/detalhar-dead-letter/detalhar-dead-letter";

export const Router = () => {
    return (
        <BrowserRouter>
            <Navigation />
            <div className="container">
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/onboarding" />
                    </Route>

                    <RotaControleAcesso tags={["dead_letters"]} path="/dead-letter/:idMensagem">
                        <DetalharDeadLetter />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["dead_letters"]} path="/dead-letter">
                        <ListarDeadLetter />
                    </RotaControleAcesso>

                    <RotaControleAcesso tags={["atividade"]} path="/atividades">
                        <ListagemAtividades />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["atividade"]} path="/atividades/criar">
                        <AdicionarAtividade />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["atividade"]} path="/atividades/:idAtividade">
                        <DetalharAtividade />
                    </RotaControleAcesso>

                    <RotaControleAcesso tags={["template"]} path="/templates">
                        <ListagemTemplates />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["template"]} path="/templates/criar">
                        <CriarEditarTemplate />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["template"]} path="/templates/editar/:templateId">
                        <CriarEditarTemplate />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["template"]} path="/templates/:templateId">
                        <DetalharTemplate />
                    </RotaControleAcesso>

                    <RotaControleAcesso tags={["configuracao_de_template"]} path="/configuracao-templates">
                        <ListagemConfigTemplates />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["configuracao_de_template"]} path="/configuracao-templates/criar">
                        <CriarEditarConfigTemplateCliente />
                    </RotaControleAcesso>
                    <RotaControleAcesso
                        tags={["configuracao_de_template"]}
                        path="/configuracao-templates/editar/:idConfiguracao"
                    >
                        <CriarEditarConfigTemplateCliente />
                    </RotaControleAcesso>
                    <RotaControleAcesso
                        tags={["configuracao_de_template"]}
                        path="/configuracao-templates/:politicaTemplateId"
                    >
                        <DetalharConfigTemplate />
                    </RotaControleAcesso>

                    <RotaControleAcesso tags={["cadastro_de_prestadores"]} path="/cadastro-legal-ops">
                        <ListagemCadastroLegalOps />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["cadastro_de_prestadores"]} path="/cadastro-legal-ops/criar">
                        <AdicionarCadastroPrestador />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["cadastro_de_prestadores"]} path="/cadastro-legal-ops/0/:idPrestador">
                        <DetalharCadastroPrestadorPF />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["cadastro_de_prestadores"]} path="/cadastro-legal-ops/1/:idPrestador">
                        <DetalharCadastroPrestadorPJ />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["cadastro_de_prestadores"]} path="/cadastro-legal-ops/editar/PF/:id">
                        <AdicionarCadastroPrestador />
                    </RotaControleAcesso>

                    <RotaControleAcesso tags={["pendencias"]} path="/pendencias">
                        <ListarPendencias />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["pendencias"]} path="/pendencias/gerar-pendencia-pagamento">
                        <GerarPendenciaPagamento />
                    </RotaControleAcesso>
                    {/* <RotaControleAcesso  tags={["agendamentos"]} path="/pendencias/guia-pagamento/:idResolucaoPendencia">
                        <GerarPendenciaPagamento />
                    </RotaControleAcesso> */}

                    <RotaControleAcesso tags={["configuracoes"]} path="/configuracoes/parametros">
                        <Parametros />
                    </RotaControleAcesso>

                    <RotaControleAcesso tags={["onboarding"]} path="/onboarding/cadastro/inconsistencias/:idCadastro">
                        <DetalheCadastro analiseInconsistencias />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["onboarding"]} path="/onboarding/cadastro/:idCadastro">
                        <DetalheCadastro />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["onboarding"]} path="/onboarding/cadastro">
                        <ListagemCadastros />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["onboarding"]} path="/onboarding/convites">
                        <ListagemConvites />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["onboarding"]} path="/onboarding/leads">
                        <ListagemFilaLeads />
                    </RotaControleAcesso>
                    <RotaControleAcesso tags={["onboarding"]} path="/onboarding">
                        <DashboardOnboarding />
                    </RotaControleAcesso>

                    <Route path="*">
                        <Error404 />
                    </Route>
                </Switch>
            </div>
        </BrowserRouter>
    );
};
