import React, { useEffect, useState } from "react";
import { AtividadeFiltroService } from "services/filtros/filtros-atividades-service";
import { FiltroTipoAtividade } from "services/filtros/models/filtro-tipo-atividade";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";
import { Loading } from "shared/loading/loading";

interface OmnijusFiltroTipoAtividadeProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isMulti?: boolean;
}

export const OmnijusFiltroTipoAtividade = (props: OmnijusFiltroTipoAtividadeProps) => {
    const [promiseOpcoesTipoAtividade, setPromiseOpcoesTipoAtividade] = useState<
        Promise<FiltroTipoAtividade[] | undefined>
    >();

    useEffect(() => {
        setPromiseOpcoesTipoAtividade(AtividadeFiltroService.listarTiposAtividade());
    }, []);

    return (
        <Loading promise={promiseOpcoesTipoAtividade}>
            {(opcoesTipoAtividade) => (
                <OmnijusSelectField
                    name={props.name || "IdTipoAtividade"}
                    label={props.hideLabel ? undefined : props.label || "Tipo de Atividade"}
                    isMulti={props.isMulti}
                    options={opcoesTipoAtividade
                        ?.map((o) => ({
                            value: String(o.id),
                            label: o.value || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
