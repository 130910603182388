import { ReactComponent as EllipseIcon } from 'assets/images/pontos-de-menu.svg';  
import "assets/images/ellipse-icon.png";
import { OmnijusCard } from '@omnijus/common';
import React from "react";
import { ItemTimeLine } from 'services/dead-letter/models/dead-letter';
import styles from "./historico-dead-letter.module.scss";
import { exibirModalDetalharItemTimeLine } from './modal-detalhar-item';

const BotaoDetalharItemTimeLine = ({ item, idMensagem, atualizar }: { item: ItemTimeLine; idMensagem: string, atualizar: () => void }) => {
    return (
        <button
            className={styles.botaoDetalhar}
            onClick={async () => {
                await exibirModalDetalharItemTimeLine(item, idMensagem);
                atualizar();
            }}
        >
            <EllipseIcon />
        </button>
    );
};

const dateToTempoMovimentacao = (tempo: Date) =>
    `${tempo.toLocaleDateString("pt-BR")} - ${tempo.toLocaleTimeString("pt-BR")}.${tempo.getMilliseconds()}`;

export const HistoricoDeadLetter = (props: {
    historico: ItemTimeLine[],
    idMensagem: string,
    atualizar: () => void
}) => {

    return (
        <OmnijusCard
            header={<h2>Histórico</h2>}
            body={<Timeline items={props.historico} idMensagem={props.idMensagem} atualizar={props.atualizar}/>}
        />
    );
};

const Timeline = (props: { items: ItemTimeLine[], idMensagem: string, atualizar: () => void }) => {

    function cardItem(item: ItemTimeLine, idMensagem: string, key: number) {
        return (
            <div key={key} className={[styles.timelineItem, styles[item.tipo.toLowerCase()]].join(' ')} onClick={async () => {
                await exibirModalDetalharItemTimeLine(item, idMensagem);
                props.atualizar();
            }}>
                <div className={styles.cardMovimentacao}>
                    <div className={styles.bolinhaTimeline}></div>
                    <div className={styles.movimentacaoData}>
                        <span className={styles.badge}>{item.tipo}</span>
                        {dateToTempoMovimentacao(new Date(item.data))}
                        <BotaoDetalharItemTimeLine item={item} idMensagem={idMensagem} atualizar={props.atualizar}/>
                    </div>
                    <div className={styles.movimentacaoTitulo}>{item.titulo}</div>
                    <div className={styles.movimentacaoSubtitulo}>{item.subtitulo}</div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.timeline}>
            <div className={styles.timelineLine}>
                <div className={styles.hoje}>Hoje</div>
                <div className={styles.verticalLine} />
            </div>
            <div className={styles.timelineItems}>{
                props.items.map((item, ii) => cardItem(item, props.idMensagem, ii))
            }</div>
        </div>
    );
};

