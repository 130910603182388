import { Form, Formik } from "formik";
import { ActionFeedback } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { listarQualificacoes } from "services/onboarding/filtros-onboarding-service";
import { Qualificacao } from "services/onboarding/models/qualificacao";
import { aprovarCadastro } from "services/onboarding/onboarding-inconsistencias-service";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { OmnijusCheckboxField } from "shared/form/fields/omnijus-checkbox-field";
import { Loading } from "shared/loading/loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { object as YupObject, string as YupString } from "yup";
import styles from "./aprovar-cadastro.module.scss";

const MySwal = withReactContent(Swal);

export const exibirModalAprovarCadastro = async (idCadastro: string) => {
    const result = await MySwal.fire({
        title: `Aprovar cadastro`,
        html: <AprovarCadastroForm />,
        showCancelButton: false,
        showConfirmButton: false,
        cancelButtonText: "Voltar",
        width: "820px",
    });

    if (result.isConfirmed && result.value) {
        const { qualificacoes } = result.value;
        await ActionFeedback.processing({
            title: "Aprovando Cadastro",
            execution: aprovarCadastro(idCadastro, qualificacoes),
        });
        return true;
    }
    return false;
};

const validationSchema = YupObject().shape({
    qualificacoes: YupString().required("Informe ao menos uma qualificação"),
});

export const AprovarCadastroForm = () => {
    const [promiseQualificacoes, setPromiseQualificacoes] = useState<Promise<Qualificacao[] | undefined>>();

    useEffect(() => {
        setPromiseQualificacoes(listarQualificacoes());
    }, []);

    return (
        <div>
            <div className={styles.form}>
                <Formik
                    initialValues={{
                        qualificacoes: "",
                    }}
                    onSubmit={async (values) => {
                        MySwal.close({ isConfirmed: true, value: values, isDenied: false, isDismissed: false });
                    }}
                    validationSchema={validationSchema}
                >
                    <Form>
                        <div className={styles.camposForm}>
                            <Loading promise={promiseQualificacoes}>
                                {(qualificacoes) => (
                                    <OmnijusCheckboxField
                                        inline={false}
                                        label="Qualificações"
                                        name="qualificacoes"
                                        options={
                                            qualificacoes
                                                ? qualificacoes.map((q) => ({
                                                      label: q.descricao,
                                                      value: q.id.toString(),
                                                  }))
                                                : []
                                        }
                                    />
                                )}
                            </Loading>
                        </div>
                        <div className={styles.botoes}>
                            <ButtonPrimary
                                type="button"
                                onClick={() => MySwal.close()}
                                className={styles.botaoCancelar}
                            >
                                Cancelar
                            </ButtonPrimary>
                            <ButtonPrimary type="submit">Salvar</ButtonPrimary>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};
