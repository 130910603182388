import { dateToDDMMYYYY } from "lib/time";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router-dom";
import {
    buscarFotoPrestador,
    detalharPrestadorServicoPF,
    downloadCertificado,
    downloadCurriculo,
} from "services/cadastro-prestadores/cadastro-prestadores-service";
import { CadastroPrestadorPessoaFisica } from "services/cadastro-prestadores/models/cadastro-prestador-pessoa-fisica";
import { ButtonSecondary } from "shared/buttons/button-secondary/button-secondary";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import { getDescricaoTipoPrestador } from "../descricao-tipo-prestador";
import styles from "./detalhar-cadastro-prestador-pf.module.scss";

export const DetalharCadastroPrestadorPF = () => {
    const { idPrestador } = useParams<{ idPrestador: string }>();
    const [promiseDetalharPrestador, setPromiseDetalharPrestador] = useState<
        Promise<CadastroPrestadorPessoaFisica | undefined>
    >();
    const [promiseFotoPrestador, setPromiseFotoPrestador] = useState<Promise<string>>();
    const history = useHistory();

    useEffect(() => {
        setPromiseDetalharPrestador(
            detalharPrestadorServicoPF(idPrestador).then((r) => {
                if (r?.foto !== null && r?.foto.idArquivo) {
                    setPromiseFotoPrestador(
                        buscarFotoPrestador(r?.foto.idArquivo)
                            .then(
                                (r) =>
                                    new Promise<string | ArrayBuffer | null>((resolve) => {
                                        if (r) {
                                            let reader = new FileReader();
                                            reader.readAsDataURL(r);
                                            reader.onloadend = () => resolve(reader.result);
                                        } else {
                                            resolve("");
                                        }
                                    })
                            )
                            .then((r) => {
                                return typeof r === "string" ? r : "";
                            })
                    );
                }

                setPromiseFotoPrestador(Promise.resolve(""));

                return r;
            })
        );
    }, [idPrestador]);

    return (
        <div className={styles["detalhar-cadastro-prestador-pf"]}>
            <Loading promise={promiseDetalharPrestador}>
                {(prestadorDetalhado) =>
                    prestadorDetalhado ? (
                        <>
                            <OmnijusCard
                                header={<h2>Identificação</h2>}
                                body={
                                    <div className={styles["identificacao"]}>
                                        <div className={styles["dados"]}>
                                            <p>
                                                <span className={styles["prefixo"]}>Nome</span>
                                                {prestadorDetalhado.nome}
                                            </p>
                                            <p>
                                                <span className={styles["prefixo"]}>Registro profissional</span>
                                                {prestadorDetalhado.registroProfissional}
                                            </p>
                                            <p>
                                                <span className={styles["prefixo"]}>Data de expedição</span>
                                                {prestadorDetalhado.dataExpedicaoRegistroProfissional !== null
                                                    ? dateToDDMMYYYY(
                                                        prestadorDetalhado.dataExpedicaoRegistroProfissional
                                                    )
                                                    : null}
                                            </p>
                                            <p>
                                                <span className={styles["prefixo"]}>Órgão</span>
                                                {`${prestadorDetalhado.descricaoOrgao} (${prestadorDetalhado.siglaOrgao})`}
                                            </p>
                                            <p>
                                                <span className={styles["prefixo"]}>CPF</span>
                                                {prestadorDetalhado.cpf}
                                            </p>
                                            <p>
                                                <span className={styles["prefixo"]}>RG</span>
                                                {prestadorDetalhado.rg}
                                            </p>
                                            <p>
                                                <span className={styles["prefixo"]}>Órgão expeditor</span>
                                                {prestadorDetalhado.orgaoExpeditor}
                                            </p>
                                            <p>
                                                <span className={styles["prefixo"]}>UF</span>
                                                {prestadorDetalhado.uf}
                                            </p>
                                            <p>
                                                <span className={styles["prefixo"]}>Data de expedição</span>
                                                {prestadorDetalhado.dataExpedicaoRG !== null
                                                    ? dateToDDMMYYYY(prestadorDetalhado.dataExpedicaoRG)
                                                    : null}
                                            </p>
                                            <p>
                                                <span className={styles["prefixo"]}>Perfil</span>
                                                {prestadorDetalhado.descricaoPerfil}
                                            </p>
                                            <p>
                                                <span className={styles["prefixo"]}>Associação</span>
                                                {getDescricaoTipoPrestador(prestadorDetalhado.idTipoPrestadorServico)}
                                            </p>
                                        </div>
                                        <div className={styles["perfil"]}>
                                            <Loading promise={promiseFotoPrestador}>
                                                {(foto) => {
                                                    if (foto) {
                                                        return <img alt="Foto do prestador de serviço" src={foto} />;
                                                    } else {
                                                        return (
                                                            <div className={styles["sem-foto"]}>
                                                                {prestadorDetalhado.nome}
                                                            </div>
                                                        );
                                                    }
                                                }}
                                            </Loading>
                                            <p>
                                                <span className={styles["prefixo"]}>Usuário</span>
                                                {prestadorDetalhado.usuario}
                                            </p>
                                        </div>
                                    </div>
                                }
                            />
                            <OmnijusCard
                                header={<h2>Endereços</h2>}
                                body={
                                    <DataTable
                                        noHeader={true}
                                        pagination={false}
                                        noDataComponent={<p>Nenhum registro encontrado!</p>}
                                        columns={[
                                            {
                                                name: "CEP",
                                                selector: "cep",
                                            },
                                            {
                                                name: "Logradouro",
                                                selector: "logradouro",
                                            },
                                            {
                                                name: "Nº",
                                                selector: "numero",
                                            },
                                            {
                                                name: "Complemento",
                                                selector: "complemento",
                                            },
                                            {
                                                name: "Bairro",
                                                selector: "bairro",
                                            },
                                            {
                                                name: "Cidade",
                                                selector: "cidade",
                                            },
                                            {
                                                name: "UF",
                                                selector: "uf",
                                            },
                                            {
                                                name: "Tipos",
                                                selector: (r) => (r.tipos !== null ? r.tipos.map(t => t.descricao).join(", ") : ""),
                                            },
                                        ]}
                                        data={prestadorDetalhado.enderecos}
                                    />
                                }
                            />
                            <OmnijusCard
                                header={<h2>Meios de comunicação</h2>}
                                body={
                                    <DataTable
                                        noHeader={true}
                                        pagination={false}
                                        noDataComponent={<p>Nenhum registro encontrado!</p>}
                                        columns={[
                                            {
                                                name: "Tipo",
                                                selector: "descricaoTipo",
                                            },
                                            {
                                                name: "Contato",
                                                selector: "valor",
                                            },
                                            {
                                                name: "Preferencial",
                                                selector: (r) => (r.preferencial ? "Sim" : "Não"),
                                            },
                                        ]}
                                        data={prestadorDetalhado.meiosComunicacao}
                                    />
                                }
                            />
                            <OmnijusCard
                                header={<h2>Formações acadêmicas</h2>}
                                body={
                                    <DataTable
                                        noHeader={true}
                                        pagination={false}
                                        noDataComponent={<p>Nenhum registro encontrado!</p>}
                                        columns={[
                                            {
                                                name: "Tipo",
                                                selector: "descricaoTipo",
                                            },
                                            {
                                                name: "Instituição",
                                                selector: "instituicao",
                                            },
                                            {
                                                name: "Curso",
                                                selector: "curso",
                                            },
                                            {
                                                name: "Situação",
                                                selector: "descricaoSituacaoCurso",
                                            },
                                            {
                                                name: "Tipo de período",
                                                selector: "descricaoTipoPeriodo",
                                            },
                                            {
                                                name: "Períodos cursados",
                                                selector: "periodosCursados",
                                            },
                                            {
                                                name: "Início",
                                                selector: (r) => dateToDDMMYYYY(r.inicio),
                                            },
                                            {
                                                name: "Término",
                                                selector: (r) => r.dataTermino ? dateToDDMMYYYY(r.dataTermino) : undefined,
                                            },
                                            {
                                                name: "Certificado",
                                                cell: (r) => r.certificado ? (
                                                    <button
                                                        className={styles["link"]}
                                                        onClick={() =>
                                                            downloadCertificado(
                                                                r.certificado.idArquivo,
                                                                r.certificado.nomeArquivo || r.certificado.idArquivo
                                                            )
                                                        }
                                                    >
                                                        Certificado.pdf
                                                    </button>
                                                ) : undefined,
                                            },
                                        ]}
                                        data={prestadorDetalhado.formacoes}
                                    />
                                }
                            />
                            <OmnijusCard
                                header={<h2>Experiências profissionais</h2>}
                                body={
                                    <DataTable
                                        noHeader={true}
                                        pagination={false}
                                        noDataComponent={<p>Nenhum registro encontrado!</p>}
                                        columns={[
                                            {
                                                name: "Empresa",
                                                selector: "empresa",
                                            },
                                            {
                                                name: "Cargo",
                                                selector: "cargo",
                                            },
                                            {
                                                name: "Início",
                                                selector: (r) => dateToDDMMYYYY(r.inicio),
                                            },
                                            {
                                                name: "Término",
                                                selector: (r) => (r.termino ? dateToDDMMYYYY(r.termino) : null),
                                            },
                                            {
                                                name: "Descrição",
                                                selector: "descricao",
                                            },
                                        ]}
                                        data={prestadorDetalhado.experiencias}
                                    />
                                }
                            />
                            <OmnijusCard
                                header={<h2>Informações profissionais</h2>}
                                body={
                                    <div className={styles["informacoes-profissionais"]}>
                                        <p>
                                            <span className={styles["prefixo"]}>LinkedIn</span>
                                            <a
                                                href={prestadorDetalhado.linkedin || ""}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {prestadorDetalhado.linkedin}
                                            </a>
                                        </p>
                                        {prestadorDetalhado.curriculo ? (
                                            <p>
                                                <span className={styles["prefixo"]}>Currículo</span>
                                                <button
                                                    className={styles["link"]}
                                                    onClick={() =>
                                                        prestadorDetalhado.curriculo
                                                            ? downloadCurriculo(
                                                                prestadorDetalhado.curriculo.idArquivo,
                                                                prestadorDetalhado.curriculo.nomeArquivo ||
                                                                prestadorDetalhado.curriculo.idArquivo
                                                            )
                                                            : null
                                                    }
                                                >
                                                    Curriculo.pdf
                                                </button>
                                            </p>
                                        ) : null}
                                    </div>
                                }
                            />
                            <OmnijusCard
                                header={<h2>Dados bancários</h2>}
                                body={
                                    <div className={styles["dados-bancarios"]}>
                                        <p>
                                            <span className={styles["prefixo"]}>Banco</span>
                                            {prestadorDetalhado.numeroBanco}
                                        </p>
                                        <p>
                                            <span className={styles["prefixo"]}>Agência</span>
                                            {prestadorDetalhado.agencia}
                                        </p>
                                        <p>
                                            <span className={styles["prefixo"]}>Conta</span>
                                            {prestadorDetalhado.conta}
                                        </p>
                                        <p>
                                            <span className={styles["prefixo"]}>Tipo</span>
                                            {prestadorDetalhado.descricaoTipoConta}
                                        </p>
                                    </div>
                                }
                            />
                        </>
                    ) : (
                        <OmnijusCard body={<p className="text-center">Prestador não encontrado</p>} />
                    )
                }
            </Loading>
            <div className={styles["acao-voltar"]}>
                <ButtonSecondary onClick={() => history.goBack()}>Voltar</ButtonSecondary>
            </div>
        </div>
    );
};
