import React, { useEffect, useState } from "react";
import { FiltroStatusResolucaoPendencia } from "services/pendencia/models/filtro-status-resolucao-pendencia";
import { listarStatusResolucaoPendencia } from "services/pendencia/pendencia-service";
import { Loading } from "shared/loading/loading";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

interface OmnijusCampoStatusPendenciaProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isDisabled?: boolean;
}

export const OmnijusCampoStatusPendencia = (props: OmnijusCampoStatusPendenciaProps) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<FiltroStatusResolucaoPendencia[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoes(listarStatusResolucaoPendencia());
    }, []);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    isDisabled={props.isDisabled}
                    name={props.name || "IdStatusResolucaoPendencia"}
                    label={props.hideLabel ? undefined : props.label || "Status"}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
