import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as TrashIcon } from "assets/images/trash-icon.svg";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { FiltroAlegacao } from "services/filtros/filtro-alegacao";
import { FiltroArea } from "services/filtros/filtro-area";
import { FiltroClasse } from "services/filtros/filtro-classe";
import { FiltroRito } from "services/filtros/filtro-rito";
import { ProcessoFiltroService } from "services/filtros/filtros-processos-service";
import { Loading } from "shared/loading/loading";
import { v4 } from "uuid";
import styles from "./template-utilizacao-field.module.scss";

export interface Utilizacao{
    idArea?: number;
    idRito?: number;
    idTipoAcao?: string;
    idAssunto?: string;
}

interface TemplateUtilizacaoFieldProps {
    onChangeValues: (utilizacoes: Array<Utilizacao>) => void;
    values: Array<Utilizacao>;
}

interface UtilizacaoComponentDados {
    id: string;
    dados: Utilizacao;
}

const UtilizacaoComponent = (props: {utilizacao: UtilizacaoComponentDados, onRemove: () => void, onChange: (v: UtilizacaoComponentDados) => void}) => {
    const [areaOptionsPromise, setAreaOptionsPromise] = useState<Promise<FiltroArea[] | undefined>>();
    const [ritoOptionsPromise, setRitoOptionsPromise] = useState<Promise<FiltroRito[] | undefined>>();
    const [acaoOptionsPromise, setAcaoOptionsPromise] = useState<Promise<FiltroClasse[] | undefined>>();
    const [assuntoOptionsPromise, setAssuntoOptionsPromise] = useState<Promise<FiltroAlegacao[] | undefined>>();

    useEffect(() => {
        setAreaOptionsPromise(ProcessoFiltroService.listarArea());
    }, []);

    useEffect(() => {
        setRitoOptionsPromise(ProcessoFiltroService.listarRito(Number(props.utilizacao.dados.idArea)));
    }, [props.utilizacao.dados.idArea]);

    useEffect(() => {
        setAcaoOptionsPromise(ProcessoFiltroService.listarClasse(Number(props.utilizacao.dados.idRito)));
        setAssuntoOptionsPromise(ProcessoFiltroService.listarAlegacao(Number(props.utilizacao.dados.idRito)));
    }, [props.utilizacao.dados.idRito]);

    return (
        <div className={styles["utilizacao"]}>
            <Loading promise={areaOptionsPromise}>
                {options => (
                    <label htmlFor="teste-aaaaa">
                        Área
                        <Select
                            id="teste-aaaaa"
                            placeholer="Área"
                            getOptionLabel={o => o.value}
                            getOptionValue={o => o.id.toString()}
                            noOptionsMessage={() => "Sem opções"}
                            value={options?.find(o => o.id.toString() === props.utilizacao.dados.idArea?.toString())}
                            onChange={(v) => {
                                let aux = v as FiltroArea;
                                props.onChange({
                                    id: props.utilizacao.id,
                                    dados: {
                                        ...props.utilizacao.dados,
                                        idArea: aux.id
                                    }
                                });
                            }}
                            options={options}
                        />
                    </label>
                )}
            </Loading>
            <Loading promise={ritoOptionsPromise}>
                {options => (
                    <label>
                        Rito
                        <Select
                            placeholer="Rito"
                            getOptionLabel={o => o.value}
                            getOptionValue={o => o.id.toString()}
                            noOptionsMessage={() => "Sem opções"}
                            value={options?.find(o => o.id.toString() === props.utilizacao.dados.idRito?.toString())}
                            onChange={(v) => {
                                let aux = v as FiltroRito;
                                props.onChange({
                                    id: props.utilizacao.id,
                                    dados: {
                                        ...props.utilizacao.dados,
                                        idRito: aux.id
                                    }
                                });
                            }}
                            options={options}
                        />
                    </label>
                )}
            </Loading>
            <Loading promise={acaoOptionsPromise}>
                {options => (
                    <label>
                        Tipo ação
                        <Select
                            placeholer="Tipo ação"
                            getOptionLabel={o => o.value}
                            getOptionValue={o => o.id.toString()}
                            noOptionsMessage={() => "Sem opções"}
                            value={options?.find(o => o.id.toString() === props.utilizacao.dados.idTipoAcao)}
                            onChange={(v) => {
                                let aux = v as FiltroClasse;
                                props.onChange({
                                    id: props.utilizacao.id,
                                    dados: {
                                        ...props.utilizacao.dados,
                                        idTipoAcao: aux.id
                                    }
                                });
                            }}
                            options={options}
                        />
                    </label>
                )}
            </Loading>
            <Loading promise={assuntoOptionsPromise}>
                {options => (
                    <label>
                        Assunto
                        <Select
                            placeholer="Assunto"
                            getOptionLabel={o => o.value}
                            getOptionValue={o => o.id.toString()}
                            noOptionsMessage={() => "Sem opções"}
                            value={options?.find(o => o.id.toString() === props.utilizacao.dados.idAssunto)}
                            onChange={(v) => {
                                let aux = v as FiltroAlegacao;
                                props.onChange({
                                    id: props.utilizacao.id,
                                    dados: {
                                        ...props.utilizacao.dados,
                                        idAssunto: aux.id
                                    }
                                });
                            }}
                            options={options}
                        />
                    </label>
                )}
            </Loading>
            <button className={styles["remover"]} onClick={props.onRemove}>
                <TrashIcon/>
            </button>
        </div>
    );
};

export const TemplateUtilizacaoField = (props: TemplateUtilizacaoFieldProps) => {
    const [utilizacoes, setUtilizacoes] = useState<Array<UtilizacaoComponentDados>>(props.values.map(v => ({id: v4(), dados: v})));
    const {onChangeValues} = props;

    return (
        <div className={styles["template-utilizacao-field"]}>
            {
                utilizacoes.map((u, i) => (
                    <UtilizacaoComponent 
                        key={u.id}
                        utilizacao={u}
                        onChange={newValue => {
                            let aux = utilizacoes.map(x => x.id === u.id ? newValue : x);
                            setUtilizacoes(aux);
                            onChangeValues(aux.map(u => u.dados));
                        }}
                        onRemove={() => {
                            let aux = utilizacoes.filter(x => x.id !== u.id);
                            setUtilizacoes(aux);
                            onChangeValues(aux.map(u => u.dados));
                        }}/>
                ))
            }
            <div className={styles["adicionar-registro"]}>
                <button
                    type="button"
                    className={styles["button"]} 
                    onClick={() => {
                        let aux = [...utilizacoes, {id: v4(), dados: {}}];
                        setUtilizacoes(aux);
                        onChangeValues(aux.map(u => u.dados));
                    }}
                >
                    <label className={styles["label"]}>Adicionar utilização</label>
                    <AddIcon className={styles["icon"]}/>
                </button>
            </div>
        </div>
    );
};