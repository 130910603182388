import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { PrestadorFiltroService } from "services/filtros/filtros-prestador-service";
import { TipoCurso } from "services/filtros/models/tipo-curso";
import { OmnijusSelectField, SelectObject } from "shared/form/fields/omnijus-select-field";
import { Loading } from "shared/loading/loading";

export const OmnijusCampoTipoCurso = (props: { hideLabel?: boolean; name?: string; label?: string }) => {
    const name = props.name || "tipoCurso";
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<TipoCurso[] | undefined>>();
    const [field, , helpers] = useField({ name });

    useEffect(() => {
        setPromiseOpcoes(PrestadorFiltroService.listarTiposCurso());
    }, []);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    name={name}
                    label={props.hideLabel ? undefined : props.label || "Tipo"}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.descricao || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                    onChange={(o) => {
                        const aux = o as SelectObject;
                        helpers.setValue(aux ? { id: parseInt(aux.value), descricao: aux.label } : { id: -1, descricao: "" });
                    }}
                    getValue={() => String(field.value.id)}
                />
            )}
        </Loading>
    );
};
