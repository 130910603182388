import { OmnijusSelectField } from '@omnijus/common';
import React, { useEffect, useState } from "react";
import { DeadLetterService } from 'services/dead-letter/dead-letter-service';
import { StatusDeadLetter } from 'services/dead-letter/models/dead-letter';
import { Loading } from "shared/loading/loading";

interface CampoStatusDeadLetterProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isDisabled?: boolean;
}

export const CampoStatusDeadLetter = (props: CampoStatusDeadLetterProps) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<StatusDeadLetter[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoes(DeadLetterService.listarStatus());
    }, []);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    isDisabled={props.isDisabled}
                    name={props.name || "IdStatusDeadLetter"}
                    label={props.hideLabel ? undefined : props.label || "Status"}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.descricao || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
