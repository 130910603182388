import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router-dom";
import { DetalheEntrevistaGuiada } from "services/entrevista-guiada/detalhe-entrevista-guiada";
import { EntrevistaGuiadaService } from "services/entrevista-guiada/entrevista-guiada-service";
import { ButtonSecondary } from "shared/buttons/button-secondary/button-secondary";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import styles from "./detalhar-template.module.scss";

export const DetalharTemplate = () => {
    const { templateId } = useParams<{templateId: string}>();
    const history = useHistory();
    const [promiseTemplate, setPromiseTemplate] = useState<Promise<DetalheEntrevistaGuiada | undefined>>();
    
    useEffect(() => {
        if(!isNaN(Number(templateId)))
            setPromiseTemplate(EntrevistaGuiadaService.obterEntrevistaGuiada(Number(templateId)));
    }, [templateId]);

    return (
        <div className={styles["detalhar-template"]}>
            <Loading promise={promiseTemplate}>
                {template => (
                    template ?
                        <>
                            <OmnijusCard
                                header={<h2>Template</h2>}
                                body={
                                    <div className={styles["template"]}>
                                        <p><span className={styles["prefixo"]}>Template:</span>{template.nomeEntrevistaGuiada}</p>
                                        <p><span className={styles["prefixo"]}>Descrição template:</span>{template.descricaoEntrevistaGuiada}</p>
                                        <p><span className={styles["prefixo"]}>Tipo de template:</span>{template.descricaoTipoEntrevistaGuiada}</p>
                                        <p><span className={styles["prefixo"]}>Template parceiro:</span>{template.descricaoTemplateParceiro}</p>
                                        <p><span className={styles["prefixo"]}>Atividades:</span>{template.atividadesEntrevistaGuiada.map(a => a.descricaoAtividade).join(", ")}</p>
                                        <p><span className={styles["prefixo"]}>Índice de complexidade</span>{template.indiceComplexidade?.toPrecision(4) || "-"}</p>
                                        <p><span className={styles["prefixo"]}>Status template:</span>{template.estaAtivo ? "Ativo" : "Inativo"}</p>
                                    </div>
                                }
                            />
                            <OmnijusCard
                                header={<h2>Utilizações</h2>}
                                body={
                                    <DataTable
                                        noHeader={true}
                                        pagination={false}
                                        noDataComponent={<p>Nenhum registro encontrado!</p>}
                                        columns={[
                                            {
                                                name: "Área",
                                                selector: "areaAtividade.descricaoArea"
                                            },
                                            {
                                                name: "Rito",
                                                selector: "ritoAtividade.descricao"
                                            },
                                            {
                                                name: "Tipo de ação",
                                                selector: "classeProcessoAtividade.descricao"
                                            },
                                            {
                                                name: "Assunto",
                                                selector: "alegacaoMeritoAtividade.descricao"
                                            }
                                        ]}
                                        data={template.utilizacaoEntrevistaGuiada}
                                />
                                }
                            />
                        </>
                        :
                        <OmnijusCard
                            body={<p className="text-center">Template não encontrado</p>}
                        />
                )}
            </Loading>
            <div className={styles["acao-voltar"]}>
                <ButtonSecondary onClick={() => history.goBack()}>Voltar</ButtonSecondary>
            </div>
        </div>
    );
}