import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "services/client-id";
import { EtapaCadastro } from "./models/etapa-cadastro";
import { StatusCadastro } from "./models/status-cadastro";
import { StatusConvite } from "./models/status-convite";
import { StatusFilaLead } from "./models/status-fila-lead";

const onboardingUrl = process.env.REACT_APP_BFF_ADMINISTRATIVO_API_URL + "/api/Onboarding/";

const requestApi = new RequestApi(CLIENT_ID);

export const listarStatusCadastro = () => {
    const url = onboardingUrl + "v1/Dominio/ListarStatusCadastro";
    return requestApi.get<StatusCadastro[]>(url);
};

export const listarEtapasCadastro = () => {
    const url = onboardingUrl + "v1/Dominio/ListarEtapas";
    return requestApi.get<EtapaCadastro[]>(url);
};

export const listarStatusConvite = () => {
    const url = onboardingUrl + "v1/Convite/ListarStatusConvite";
    return requestApi.get<StatusConvite[]>(url);
};

export const listarStatusFilaLeads = () => {
    const url = onboardingUrl + "v1/Fila/ListarStatusFila";
    return requestApi.get<StatusFilaLead[]>(url);
};

export const listarQualificacoes = () => {
    const url = onboardingUrl + "v1/Dominio/ListarQualificacoes";
    return requestApi.get<StatusFilaLead[]>(url);
};
