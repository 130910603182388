import { dateToDDMMYYYY } from "lib/time";
import React from "react";
import { IdEtapaCadastro } from "services/onboarding/models/id-etapa-cadastro";
import { CampoCadastro, CampoCheckbox } from "../campo-cadastro/campo-cadastro";
import { CardCadastro } from "../card-cadastro/card-cadastro";
import styles from "./experiencia-profissional.module.scss";

export interface DadosExperienciaProfissional {
    id: string;
    autonomo: boolean;
    empresa: string;
    cargo: string;
    inicio?: Date;
    termino?: Date;
    atualmenteNaEmpresa: boolean;
    descricao: string;
}
interface ExperienciaProfissionalProps {
    urlLinkedIn: string;
    experiencias: DadosExperienciaProfissional[];
}

const etapa = { descricao: "Experiência Profissional", id: IdEtapaCadastro.ExperienciaProfissional };

export const ExperienciaProfissional = (props: ExperienciaProfissionalProps) => {
    return (
        <CardCadastro
            header={etapa.descricao}
            body={
                <div>
                    <div className={styles.linksExperienciaProfissional}>
                        <CampoCadastro
                            rotulo="LinkedIn"
                            valor={props.urlLinkedIn}
                            nome="urlRedeSocialProfissional"
                            format={(user) =>
                                user && (
                                    <a
                                        href={`https://www.linkedin.com/in/${user}/`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {user}
                                    </a>
                                )
                            }
                        />
                    </div>
                    {props.experiencias.map((experiencia) => (
                        <div className={styles.camposExperienciaProfissional} key={experiencia.id}>
                            <CampoCheckbox
                                rotulo="Autônomo"
                                valor={experiencia.autonomo}
                                idExperienciaProfissional={experiencia.id}
                                nome="autonomo"
                            />
                            <CampoCadastro
                                rotulo="Empresa"
                                valor={experiencia.empresa}
                                nome="empresa"
                                idExperienciaProfissional={experiencia.id}
                            />
                            <CampoCadastro
                                rotulo="Cargo"
                                valor={experiencia.cargo}
                                nome="cargo"
                                idExperienciaProfissional={experiencia.id}
                            />
                            <CampoCadastro
                                rotulo="Início"
                                valor={experiencia.inicio?.toString()}
                                idExperienciaProfissional={experiencia.id}
                                nome="dataInicio"
                                format={(dataInicio?: string) =>
                                    dataInicio ? dateToDDMMYYYY(new Date(dataInicio)) : ""
                                }
                            />
                            <CampoCheckbox
                                rotulo="Atualmente na empresa"
                                valor={experiencia.atualmenteNaEmpresa}
                                idExperienciaProfissional={experiencia.id}
                                format={(dataTermino?: string) => (dataTermino ? "Não" : "Sim")}
                            />
                            <CampoCadastro
                                rotulo="Término"
                                valor={experiencia.termino?.toString()}
                                idExperienciaProfissional={experiencia.id}
                                nome="dataTermino"
                                format={(dataTermino?: string) =>
                                    dataTermino ? dateToDDMMYYYY(new Date(dataTermino)) : ""
                                }
                            />
                            <CampoCadastro
                                className={styles.campoDescricao}
                                rotulo="Descrição"
                                valor={experiencia.descricao}
                                idExperienciaProfissional={experiencia.id}
                                nome="descricao"
                            />
                        </div>
                    ))}
                </div>
            }
            etapa={etapa}
        />
    );
};
