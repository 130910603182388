import { AuthProvider, ControleAcessoProvider } from "@omnijus/common";
import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import { Router } from "router";
import { CLIENT_ID } from "services/client-id";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { AuthSplash } from "./shared/auth-splash";

const configuration = {
    client_id: "spa-administrador",
    redirect_uri: window.location.origin + "/authentication/callback",
    response_type: "code",
    post_logout_redirect_uri: window.location.origin,
    scope: "openid profile email api_bff_administrativo",
    authority: process.env.REACT_APP_AUTH_URL,
    silent_redirect_uri: window.location.origin + "/authentication/silent_callback",
    automaticSilentRenew: true,
    loadUserInfo: true,
};

const App = () => {
    return (
        <AuthProvider userManagerSettings={configuration} callbackComponent={<AuthSplash />}>
            <ControleAcessoProvider clientId={CLIENT_ID} baseUrl={process.env.REACT_APP_BFF_ADMINISTRATIVO_API_URL!}>
                <Router />
            </ControleAcessoProvider>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </AuthProvider>
    );
}; // Proteção do App

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
