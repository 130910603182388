import { dateToDDMMYYYY } from "lib/time";
import React from "react";
import { Anexo } from "services/onboarding/models/anexo";
import { IdEtapaCadastro } from "services/onboarding/models/id-etapa-cadastro";
import { CampoCadastro, CampoCheckbox, CampoImagem } from "../campo-cadastro/campo-cadastro";
import { CardCadastro } from "../card-cadastro/card-cadastro";
import styles from "../registro-profissional/registro-profissional.module.scss";

export interface DadosFormacaoAcademica {
    id: string;
    grauEscolaridade: string;
    curso: string;
    terminoOuPrevisao?: Date;
    instituicao: string;
    situacao: string;
    frenteCertificadoFormacaoAcademica?: Anexo;
    versoCertificadoFormacaoAcademica?: Anexo;
}
interface FormacaoAcademicaProps {
    possuiFormacaoAcademica: boolean;
    formacoes: DadosFormacaoAcademica[];
}

const etapa = { descricao: "Formacao Acadêmica", id: IdEtapaCadastro.FormacaoAcademica };

export const FormacaoAcademica = (props: FormacaoAcademicaProps) => {
    return (
        <CardCadastro
            header={etapa.descricao}
            body={
                <div>
                    <CampoCheckbox
                        nome="possuiFormacaoAcademica"
                        rotulo="Não possui formação acadêmica"
                        valor={!props.possuiFormacaoAcademica}
                        format={(valor?: string) =>
                            valor?.toUpperCase() === "FALSE" ? "Sim" : valor?.toUpperCase() === "TRUE" ? "Não" : ""
                        }
                    />
                    {
                        // props.possuiFormacaoAcademica &&
                        props.formacoes.map((formacao) => (
                            <div className={styles.grupoCampos} key={formacao.id}>
                                <div>
                                    <CampoCadastro
                                        rotulo="Grau de escolaridade"
                                        valor={formacao.grauEscolaridade}
                                        nome="idGrauEscolaridade"
                                        idFormacaoAcademica={formacao.id}
                                    />
                                    <CampoCadastro
                                        rotulo="Curso"
                                        valor={formacao.curso}
                                        nome="curso"
                                        idFormacaoAcademica={formacao.id}
                                    />
                                    <CampoCadastro
                                        rotulo="Término ou previsão de término"
                                        valor={
                                            formacao.terminoOuPrevisao ? dateToDDMMYYYY(formacao.terminoOuPrevisao) : ""
                                        }
                                        nome="dataTermino"
                                        idFormacaoAcademica={formacao.id}
                                    />
                                    <CampoCadastro
                                        rotulo="Instituição"
                                        valor={formacao.instituicao}
                                        nome="instituicaoEnsino"
                                        idFormacaoAcademica={formacao.id}
                                    />
                                    <CampoCadastro
                                        rotulo="Situação"
                                        valor={formacao.situacao}
                                        nome="idSituacaoFormacaoAcademica"
                                        idFormacaoAcademica={formacao.id}
                                    />
                                </div>
                                <CampoImagem
                                    rotulo={"Frente certificado de formação acadêmica"}
                                    anexo={formacao.frenteCertificadoFormacaoAcademica}
                                    idFormacaoAcademica={formacao.id}
                                />
                            </div>
                        ))
                    }
                </div>
            }
            etapa={etapa}
        />
    );
};
