import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "services/client-id";
import { DashboardEmCadastroResponse } from "services/onboarding/models/dashboard-em-cadastro-response";
import { DashboardOnboardingResponse } from "services/onboarding/models/dashboard-onboarding-response";
import { Cadastro } from "./models/cadastro";
import { CadastroCompleto } from "./models/cadastro-completo";
import { DashboardTotaisResponse } from "./models/dashboard-totais-response";
import { ParametrosPesquisarCadastro } from "./models/parametros-pesquisa-cadastro";

const onboardingUrl = process.env.REACT_APP_BFF_ADMINISTRATIVO_API_URL + "/api/Onboarding/";

const requestApi = new RequestApi(CLIENT_ID);

export const getDashboardOnboarding = () => {
    const url = onboardingUrl + "v1/Dashboard/Onboarding";
    return requestApi.get<DashboardOnboardingResponse>(url);
};

export const getDashboardEmCadastro = () => {
    const url = onboardingUrl + "v1/Dashboard/EmCadastro";
    return requestApi.get<DashboardEmCadastroResponse>(url);
};

export const getDashboardTotais = async () => {
    const url = onboardingUrl + "v1/Dashboard/Totais";
    return requestApi.get<DashboardTotaisResponse>(url);
};

export const getPesquisaCadastro = async (parametros: ParametrosPesquisarCadastro) => {
    const url = new URL(`${onboardingUrl}v1/Cadastro/Pesquisa`);
    url.search = RequestApi.objectToQueryString(parametros);
    const res = await requestApi.get<Cadastro[]>(url.toString());
    return res?.map((cadastro) => ({
        ...cadastro,
        dataUltimaAlteracao: cadastro.dataUltimaAlteracao && new Date(cadastro.dataUltimaAlteracao),
        dataInicioAnalise: cadastro.dataInicioAnalise && new Date(cadastro.dataInicioAnalise),
    }));
};

export const getCadastroCompleto = async (id: string): Promise<CadastroCompleto | undefined> => {
    const url = new URL(`${onboardingUrl}v1/Cadastro/${id}`);
    const res = await requestApi.get<CadastroCompleto>(url.toString());
    return (
        res && {
            ...res,
            dataExpedicaoDocumentoIdentidade:
                res.dataExpedicaoDocumentoIdentidade && new Date(res.dataExpedicaoDocumentoIdentidade),
            dataInicioAnalise: res.dataInicioAnalise && new Date(res.dataInicioAnalise),
            dataRegistroEntidadeClasse: res.dataRegistroEntidadeClasse && new Date(res.dataRegistroEntidadeClasse),
            dataUltimaAlteracao: res.dataUltimaAlteracao && new Date(res.dataUltimaAlteracao),
            historicoAtual: {
                ...res.historicoAtual,
                data: res.historicoAtual.data && new Date(res.historicoAtual.data),
            },
            historico: res.historico && res.historico.map((h) => ({ ...h, data: h.data && new Date(h.data) })),
            experienciasProfissionais:
                res.experienciasProfissionais &&
                res.experienciasProfissionais.map((e) => ({
                    ...e,
                    dataInicio: e.dataInicio && new Date(e.dataInicio),
                    dataTermino: e.dataTermino && new Date(e.dataTermino),
                })),
            formacaoAcademica: res.formacaoAcademica?.map((formacao) => ({
                ...formacao,
                dataTermino: formacao.dataTermino && new Date(formacao.dataTermino),
            })),
        }
    );
};

export const postNotificacaoCadastro = async (
    filtroCadastro: ParametrosPesquisarCadastro,
    titulo: string,
    mensagem: string,
    dataAgendamento?: Date
) => {
    const url = new URL(`${onboardingUrl}v1/Cadastro/notificacao`);
    return requestApi.post(url.toString(), {
        titulo,
        mensagem,
        dataAgendamento,
        cadastroFilter: filtroCadastro,
    });
};
