import {
    ActionFeedback,
    CardFiltros,
    Loading,
    OmnijusCard,
    OmnijusRangeDateField,
    OmnijusTextField,
    RequestApi,
} from "@omnijus/common";
import { format } from "date-fns";
import { PagedResult } from "lib/paged-result";
import { PaginationInfo } from "lib/pagination-info";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { useHistory, useLocation } from "react-router-dom";
import { DeadLetterService } from "services/dead-letter/dead-letter-service";
import { DeadLetterViewModel, IdStatusMensagem } from "services/dead-letter/models/dead-letter";
import {
    ParametrosPesquisarDLQ,
    parseParametrosPesquisarDLQ,
} from "services/dead-letter/models/parametros-pesquisar-dlq";
import { ButtonAccent } from "shared/buttons/button-accent/button-accent";
import { date as YupDate, object as YupObject, string as YupString } from "yup";
import { CampoStatusDeadLetter } from "./detalhar-dead-letter/forms/campo-status-dead-letter";
import styles from "./listar-dead-letter.module.scss";

const formataData = (input: any) => {
    if (!input) {
        return <div>--</div>;
    }
    const data = new Date(input);
    return (
        <div>
            {format(new Date(data), "dd/MM/yyyy")} <br />
            <small>{format(new Date(data), "HH:mm:ss")}</small>
        </div>
    );
};

const columns: IDataTableColumn<DeadLetterViewModel>[] = [
    {
        name: "Tópico/Consumer",
        selector: "topico",
        maxWidth: "40%",
        cell: (row) => (
            <div>
                {row.topico}
                <br />
                <small title="Consumer Group">{row.consumer}</small>
            </div>
        ),
        sortable: true,
        id: "topico",
        wrap: false,
        allowOverflow: false,
    },
    {
        name: "Erro",
        maxWidth: "50%",
        selector: "mensagemErro",
        sortable: true,
        id: "mensagemErro",
        wrap: true,
        cell: (row) => (
            <div>
                {row.tipoErro}
                <br />
                <small title="Mensagem de erro">{row.mensagemErro}</small>
            </div>
        ),
    },
    {
        name: "Status",
        maxWidth: "10%",
        selector: "idStatusMensagem",
        cell: (row) => (
            <div>
                {row.statusMensagem}
                <br />
                <small title="Total envios / Total erros / Máximo Retentativas">
                    {row.totalEnvios}/{row.totalErros}/{row.maximoRetentativas}
                </small>
            </div>
        ),
        sortable: true,
        id: "status",
    },
    {
        name: "Primeira emissão",
        selector: "DataPrimeiraEmissao",
        maxWidth: "120px",
        format: (row) => formataData(row.dataPrimeiraEmissao),
        sortable: true,
        id: "dataPrimeiraEmissao",
    },
    {
        name: "Data de Criação",
        selector: "DataCriacao",
        maxWidth: "120px",
        format: (row) => formataData(row.dataCriacao),
        sortable: true,
        id: "dataCriacao",
    },
    {
        name: "Última Tentativa",
        selector: "dataUltimaTentativa",
        maxWidth: "120px",
        format: (row) => formataData(row.dataUltimaTentativa),
        sortable: true,
        id: "dataUltimaTentativa",
        wrap: false,
    },
    {
        name: "Próxima Tentativa",
        selector: "dataProximaTentativa",
        maxWidth: "120px",
        format: (row) => formataData(row.dataProximaTentativa),
        sortable: true,
        id: "dataProximaTentativa",
    },
];

const validacaoPesquisa = YupObject().shape({
    id: YupString(),
    topico: YupString(),
    consumer: YupString(),
    key: YupString(),
    tipoErro: YupString(),
    dataCriacaoStart: YupDate().nullable().typeError("Data inválida"),
    dataCriacaoEnd: YupDate()
        .nullable()
        .typeError("Data inválida")
        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
            const { DataUltimaTentativaStart } = this.parent;

            if (dataAte && DataUltimaTentativaStart && dataAte < DataUltimaTentativaStart) {
                return false;
            }

            return true;
        }),
    dataUltimaTentativaStart: YupDate().nullable().typeError("Data inválida"),
    dataUltimaTentativaEnd: YupDate()
        .nullable()
        .typeError("Data inválida")
        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
            const { DataUltimaTentativaStart } = this.parent;

            if (dataAte && DataUltimaTentativaStart && dataAte < DataUltimaTentativaStart) {
                return false;
            }

            return true;
        }),
    dataHoraCriacaoDe: YupDate().nullable().typeError("Data inválida"),
    dataHoraCriacaoAte: YupDate()
        .nullable()
        .typeError("Data inválida")
        .test("maior", "A data final não pode ser anterior à data inicial", function (dataAte) {
            const { dataHoraCriacaoDe } = this.parent;

            if (dataAte && dataHoraCriacaoDe && dataAte < dataHoraCriacaoDe) {
                return false;
            }

            return true;
        }),
});

export const ListarDeadLetter = () => {
    const query = useLocation().search;
    const history = useHistory();
    const [promise, setPromise] = useState<Promise<PagedResult<DeadLetterViewModel>>>();
    const [dataBusca, setDataBusca] = useState<Date>(new Date());
    const [selecionados, setSelecionados] = useState<DeadLetterViewModel[]>([]);

    const filtro = useMemo<ParametrosPesquisarDLQ>(() => parseParametrosPesquisarDLQ(query), [query]);

    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        rowsPerPage: 20,
        pageNumber: 1,
        sortColumn: "dataPrimeiraEmissao",
        sortDirection: "asc",
    });

    const handleRowsPerPage = (rowsPerPage: number) => {
        setPaginationInfo((paginationInfo) => ({ ...paginationInfo, rowsPerPage, pageNumber: 1 }));
    };

    const handleSort = (column: IDataTableColumn<any>, sortDirection: "asc" | "desc") => {
        setPaginationInfo((paginationInfo) => ({
            ...paginationInfo,
            sortDirection,
            sortColumn: String(column.id),
            pageNumber: 1,
        }));
    };

    const handleChangePage = (page: number) => {
        setPaginationInfo((paginationInfo) => ({ ...paginationInfo, pageNumber: page }));
    };

    const buscar = useCallback(
        (filtros: ParametrosPesquisarDLQ) => {
            const search = RequestApi.objectToQueryString(filtros);
            history.replace({
                search,
            });
        },
        [history]
    );

    const enviarEmLote = useCallback(async () => {
        const msg =
            selecionados.length > 1
                ? `Deseja enviar os ${selecionados.length} registros selecionados em lote?`
                : `Deseja enviar o registro selecionado?`;
        const aceito = await ActionFeedback.confirm(msg);
        if (!aceito) {
            return;
        }

        await ActionFeedback.processing({
            title: `Enviando ${selecionados.length} mensagens...`,
            execution: DeadLetterService.enviarEmLote(selecionados.map((x) => x.id)),
        });
        setDataBusca(new Date());

        await ActionFeedback.info({
            title: "Mensagens enviadas com sucesso!",
        });
    }, [selecionados]);

    useEffect(() => {
        setSelecionados([]);
        setPromise(DeadLetterService.consultarMensagens(filtro, paginationInfo));
    }, [paginationInfo, filtro, dataBusca]);

    return (
        <div className={styles["consulta-mensagens"]}>
            <h2>Mensagens</h2>
            <CardFiltros
                textoBotao="Filtrar"
                onBuscar={async (values) => {
                    setDataBusca(new Date());
                    buscar(values);
                }}
                initialValues={filtro}
                botaoFiltrarInline
                validationSchema={validacaoPesquisa}
            >
                <OmnijusTextField name="id" label="Id da Mensagem" />
                <OmnijusTextField name="topico" label="Tópico" />
                <OmnijusTextField name="consumer" label="Consumer" />
                <OmnijusTextField name="key" label="Key" />
                <OmnijusTextField name="mensagemErro" label="Mensagem de erro" />
                <OmnijusRangeDateField nameDe="dataCriacaoStart" nameAte="dataCriacaoEnd" label="Data de Criação" />
                <OmnijusRangeDateField
                    nameDe="dataUltimaTentativaStart"
                    nameAte="dataUltimaTentativaEnd"
                    label="Data de ultima tentativa"
                />
                <CampoStatusDeadLetter name="idStatusMensagem" />
            </CardFiltros>

            <div className="text-right">
                <ButtonAccent disabled={!selecionados.length} onClick={enviarEmLote}>
                    Enviar em lote
                </ButtonAccent>
            </div>

            {promise ? (
                <Loading promise={promise}>
                    {(pagedResult) => (
                        <OmnijusCard
                            body={
                                <DataTable
                                    className={styles["dlq-table"]}
                                    noHeader={true}
                                    customStyles={{
                                        rows: {
                                            style: {
                                                cursor: "pointer",
                                            },
                                        },
                                    }}
                                    pagination
                                    paginationPerPage={paginationInfo.rowsPerPage}
                                    noDataComponent={<p>A consulta não retornou registros</p>}
                                    data={pagedResult?.results || []}
                                    paginationServer
                                    onChangeRowsPerPage={handleRowsPerPage}
                                    paginationDefaultPage={pagedResult?.pageNumber ?? 1}
                                    onChangePage={handleChangePage}
                                    paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                                    onSort={handleSort}
                                    sortServer
                                    defaultSortField={
                                        columns.find((c) => c.id === paginationInfo.sortColumn)?.selector as string
                                    }
                                    defaultSortAsc={paginationInfo.sortDirection === "asc"}
                                    paginationTotalRows={pagedResult?.total}
                                    onRowClicked={(row) => history.push(`/dead-letter/${row.id}`)}
                                    columns={columns}
                                    selectableRows
                                    selectableRowDisabled={(item) =>
                                        item.idStatusMensagem === IdStatusMensagem.Processada
                                    }
                                    onSelectedRowsChange={(selecao) => {
                                        setSelecionados(selecao.selectedRows);
                                    }}
                                />
                            }
                        />
                    )}
                </Loading>
            ) : (
                <p className="text-center">Utilize os filtros para realizar uma nova busca!</p>
            )}
        </div>
    );
};
