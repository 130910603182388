import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FiltroAlegacao } from "services/filtros/filtro-alegacao";
import { ProcessoFiltroService } from "services/filtros/filtros-processos-service";
import { Loading } from "shared/loading/loading";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";

const filtroRitoName = "IdRitoProcesso";

type getIdRitoFromValues = (values: any) => string;
interface OmnijusFiltroAssuntoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    reloadOnChange?: string | getIdRitoFromValues;
}

export const OmnijusFiltroAssunto = (props: OmnijusFiltroAssuntoProps) => {
    const formikContext = useFormikContext<any>();
    const { setFieldValue } = formikContext;
    const values = formikContext.values;
    const idRito =
        typeof props.reloadOnChange === "undefined" || typeof props.reloadOnChange === "string"
            ? values[props.reloadOnChange || filtroRitoName]
            : props.reloadOnChange(values);
    const [promiseOpcoesAssunto, setPromiseOpcoesAssunto] = useState<Promise<FiltroAlegacao[] | undefined>>();
    const isFirstRun = useRef(true);
    const name = props.name || "IdAlegacaoMerito";

    useEffect(() => {
        isFirstRun.current || setFieldValue(name, undefined);
        setPromiseOpcoesAssunto(ProcessoFiltroService.listarAlegacao(idRito));
        isFirstRun.current = false;
    }, [idRito, name, setFieldValue]);

    return (
        <Loading promise={promiseOpcoesAssunto}>
            {(opcoesAssunto) => (
                <OmnijusSelectField
                    name={name}
                    label={props.hideLabel ? undefined : props.label || "Assunto"}
                    options={opcoesAssunto
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
