import { ReactComponent as EditPen } from "assets/images/edit-pen.svg";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { NavLink, useHistory } from "react-router-dom";
import { EntrevistaGuiada } from "services/entrevista-guiada/entrevista-guiada";
import { EntrevistaGuiadaService } from "services/entrevista-guiada/entrevista-guiada-service";
import { ParametroListarEntrevistaGuiada } from "services/entrevista-guiada/parametro-listar-entrevista-guiada";
import { CardFiltros } from "shared/form/card-filtros/card-filtros";
import { OmnijusFiltroArea } from "shared/form/custom-fields/omnijus-filtro-area";
import { OmnijusFiltroAssunto } from "shared/form/custom-fields/omnijus-filtro-assunto";
import { OmnijusFiltroAtividade } from "shared/form/custom-fields/omnijus-filtro-atividade";
import { OmnijusFiltroStatusTemplate } from "shared/form/custom-fields/omnijus-filtro-status-template";
import { OmnijusFiltroTemplate } from "shared/form/custom-fields/omnijus-filtro-template";
import { OmnijusFiltroTipoAcao } from "shared/form/custom-fields/omnijus-filtro-tipo-acao";
import { OmnijusFiltroTipoTemplate } from "shared/form/custom-fields/omnijus-filtro-tipo-template";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import styles from "./listar-templates.module.scss";

const FiltrosListaTemplate = () => {
    const idArea = useFormikContext<{ IdArea?: number }>().values.IdArea;

    return (
        <>
            <OmnijusFiltroArea name="IdArea" label="Área" />
            <OmnijusFiltroAtividade name="IdAtividade" label="Atividade" idArea={idArea} />
            <OmnijusFiltroTipoAcao name="IdClasseProcesso" label="Tipo de ação" />
            <OmnijusFiltroAssunto name="IdAlegacaoMerito" label="Assunto" />
            <OmnijusFiltroTipoTemplate name="IdTipoEntrevistaGuiada" label="Tipo de template" />
            <OmnijusFiltroTemplate name="IdEntrevistaGuiada" label="Template" />
            <OmnijusFiltroStatusTemplate name="EstaAtivo" label="Status template" />
        </>
    );
};

export const ListagemTemplates = () => {
    const initialValues: ParametroListarEntrevistaGuiada = {};
    const history = useHistory();
    const [promiseListaTemplate, setPromiseListaTemplate] = useState<Promise<EntrevistaGuiada[] | undefined>>();

    useEffect(() => {
        setPromiseListaTemplate(EntrevistaGuiadaService.listarEntrevistaGuiada());
    }, []);

    return (
        <div className={styles["listar-templates"]}>
            <CardFiltros
                onBuscar={(filtros) => setPromiseListaTemplate(EntrevistaGuiadaService.listarEntrevistaGuiada(filtros))}
                initialValues={initialValues}
                botaoFiltrarInline
            >
                <FiltrosListaTemplate />
            </CardFiltros>
            <div className={styles["adicionar-template"]}>
                <NavLink to="/templates/criar">Adicionar template</NavLink>
            </div>
            <OmnijusCard
                body={
                    <Loading promise={promiseListaTemplate}>
                        {(listaTemplates) => (
                            <DataTable
                                noHeader={true}
                                customStyles={{
                                    rows: {
                                        style: {
                                            cursor: "pointer",
                                        },
                                    },
                                }}
                                pagination={true}
                                paginationPerPage={20}
                                noDataComponent={<p>Nenhum registro encontrado!</p>}
                                onRowClicked={(p) => history.push(`/templates/${p.idEntrevistaGuiada}`)}
                                data={listaTemplates || []}
                                columns={[
                                    {
                                        name: "Atividades",
                                        selector: (row) =>
                                            row.atividadesEntrevistaGuiada.map((a) => a.descricaoAtividade).join(","),
                                        wrap: true,
                                        style: { padding: 20 },
                                    },
                                    {
                                        name: "Template",
                                        selector: (row) => row.nomeEntrevistaGuiada,
                                        wrap: true,
                                        style: { padding: 20 },
                                    },
                                    {
                                        name: "Áreas",
                                        selector: (row) => row.areaAtividade.map((a) => a.descricaoArea).join(","),
                                        wrap: true,
                                        style: { padding: 20 },
                                    },
                                    {
                                        name: "Ritos",
                                        selector: (row) => row.ritoAtividade.map((a) => a.descricao).join(","),
                                        wrap: true,
                                        style: { padding: 20 },
                                    },
                                    {
                                        name: "Tipos de ação",
                                        selector: (row) =>
                                            row.classeProcessoAtividade.map((a) => a.descricao).join(","),
                                        wrap: true,
                                        style: { padding: 20 },
                                    },
                                    {
                                        name: "Assuntos",
                                        selector: (row) =>
                                            row.alegacaoMeritoAtividade.map((a) => a.descricao).join(","),
                                        wrap: true,
                                        style: { padding: 20 },
                                    },
                                    {
                                        name: "Status",
                                        selector: (row) => (row.estaAtivo ? "Ativo" : "Inativo"),
                                        wrap: true,
                                        style: { padding: 20 },
                                    },
                                    {
                                        name: "",
                                        button: true,
                                        cell: (row: EntrevistaGuiada) => (
                                            <button
                                                className={styles["editar-template"]}
                                                onClick={() =>
                                                    history.push(`templates/editar/${row.idEntrevistaGuiada}`)
                                                }
                                            >
                                                <EditPen />
                                            </button>
                                        ),
                                    },
                                ]}
                            />
                        )}
                    </Loading>
                }
            />
        </div>
    );
};
