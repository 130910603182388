import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "services/client-id";
import { Convite } from "./models/convite";
import { ImportacaoConviteResult } from "./models/importacao-convite-result";
import { NotificacoesDeConviteEnviadosResult } from "./models/notificacoes-de-convite-enviados-result";
import { ParametrosPesquisaConvite } from "./models/parametros-pesquisa-convite";

const onboardingUrl = process.env.REACT_APP_BFF_ADMINISTRATIVO_API_URL + "/api/Onboarding/";

const requestApi = new RequestApi(CLIENT_ID);

export const getPesquisaConvite = async (parametros: ParametrosPesquisaConvite) => {
    const url = new URL(`${onboardingUrl}v1/Convite/Pesquisa`);
    url.search = RequestApi.objectToQueryString(parametros);
    const res = await requestApi.get<Convite[]>(url.toString());
    return res?.map((cadastro) => ({
        ...cadastro,
        dataCadastroConvite: cadastro.dataCadastroConvite && new Date(cadastro.dataCadastroConvite),
        dataHoraCadastro: cadastro.dataHoraCadastro && new Date(cadastro.dataHoraCadastro),
        dataHoraUtilizacao: cadastro.dataHoraUtilizacao && new Date(cadastro.dataHoraUtilizacao),
    }));
};

export const atualizarConvite = (idConvite: string, nome: string, cpf: string, email: string, telefone: string) => {
    const url = new URL(`${onboardingUrl}v1/Convite/${idConvite}`);
    return requestApi.put(url.toString(), {
        nome,
        cpf,
        email,
        telefone,
    });
};

export const adicionarConvite = (nome: string, cpf: string, email: string, telefone: string) => {
    const url = new URL(`${onboardingUrl}v1/Convite`);
    return requestApi.post(url.toString(), {
        nome,
        cpf,
        email,
        telefone,
    });
};

export const deletarConvite = (idConvite: string) => {
    const url = new URL(`${onboardingUrl}v1/Convite/${idConvite}`);
    return requestApi.delete(url.toString());
};

export const importarConvitesCSV = (convitesCSV: File) => {
    const url = new URL(`${onboardingUrl}v1/Convite/ImportarCSV`);
    return requestApi.postFormdata<ImportacaoConviteResult>(url.toString(), {
        file: convitesCSV,
    });
};

export const downloadModeloCSV = () => {
    const url = `${onboardingUrl}/Content/modelo-convite.csv`;
    requestApi.download(url, "modelo-convite.csv");
};

export const reenviarNotificacaoConvite = (convites: Convite[]) => {
    const url = new URL(`${onboardingUrl}v1/Convite/ReenviarNotificacaoConvite`);
    return requestApi.put<NotificacoesDeConviteEnviadosResult>(
        url.toString(),
        convites.map((c) => c.id)
    );
};
