import React from "react";
import Modal from "react-modal";
import { DetalheResolucaoPendenciaAdmin } from "services/pendencia/models/detalhe-resolucao-pendencia-admin";
import { ButtonSecondary, OmnijusCard } from "@omnijus/common";
import { dateToDDMMYYYY } from "lib/time";
import styles from "./listar-pendencias.module.scss";
import { Link } from "react-router-dom";

interface ModalDetalhePendenciaProps extends DetalheResolucaoPendenciaAdmin {
    isModalOpen: boolean;
    onCloseModal: () => void;
}

export const ModalDetalhePendencia = (props: ModalDetalhePendenciaProps) => {
    return (
        <>
            <Modal
                style={{
                    content: {
                        width: "50vw",
                        margin: "auto",
                        height: "38vh",
                    },
                }}
                isOpen={props.isModalOpen}
                onRequestClose={props.onCloseModal}
                shouldCloseOnEsc
                ariaHideApp={false}
            >
                <OmnijusCard
                    header={<h2>Detalhamento da Pendência</h2>}
                    className={styles.modalDetalheInfo}
                    body={
                        <>
                            <span>
                                <strong>Processo</strong>: {props.numerosProcessos?.join(", ")}
                            </span>
                            <span>
                                <strong>Data de Solicitação</strong>: {dateToDDMMYYYY(props.dataHoraSolicitacao)}
                            </span>
                            <span>
                                <strong>Cliente</strong>: {props.nomesClientes?.join(", ")}
                            </span>
                            <span>
                                <strong>Prazo de Execução</strong>: {dateToDDMMYYYY(props.dataHoraLimiteResolucao)}
                            </span>
                            <span>
                                <strong>Pendência</strong>: {props.tipoPendencia}
                            </span>
                            <span>
                                <strong>Data da Resolução</strong>:{" "}
                                {(props.dataHoraResolucao && dateToDDMMYYYY(new Date(props.dataHoraResolucao))) || "-"}
                            </span>
                            <span>
                                <strong>Status</strong>: {props.statusResolucaoPendencia}
                            </span>

                            {props.pathComprovantePagamentoGuia && (
                                <span>
                                    <strong>Download</strong>:{" "}
                                    <Link
                                        title="Clique para abrir o arquivo em uma nova aba"
                                        to={{ pathname: props.pathComprovantePagamentoGuia }}
                                        target="_blank"
                                    >
                                        Comprovante de pagamento de guia
                                    </Link>
                                </span>
                            )}

                            <div>
                                <strong>{props.autores && props.autores.length > 1 ? "Autores: " : "Autor: "}</strong>
                                {props.autores ? (
                                    props.autores.length > 1 ? (
                                        <ul className={styles.modalDetalheInfoAutor}>
                                            {props.autores?.map((a) => (
                                                <li>
                                                    {`${
                                                        a.numeroDocumento
                                                            ? a.nome + " (" + a.numeroDocumento + ")"
                                                            : a.nome
                                                    }`}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <>{`${
                                            props.autores[0].numeroDocumento
                                                ? props.autores[0].nome + " (" + props.autores[0].numeroDocumento + ")"
                                                : props.autores[0].nome
                                        }`}</>
                                    )
                                ) : (
                                    "-"
                                )}
                            </div>
                        </>
                    }
                />
                <div className={styles.alignRight}>
                    <ButtonSecondary className={styles.marginTop} onClick={props.onCloseModal}>
                        Fechar
                    </ButtonSecondary>
                </div>
            </Modal>
        </>
    );
};
