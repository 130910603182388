import React from "react";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";

export const OmnijusFiltroStatusTemplate = (props: {
  name?: string;
  label?: string;
  hideLabel?: boolean;
}) => {
  return (
    <OmnijusSelectField
      name={props.name || "StatusTemplate"}
      label={props.hideLabel ? undefined : props.label || "Status"}
      options={[
        {
          value: "true",
          label: "Ativo",
        },
        {
          value: "false",
          label: "Inativo",
        },
      ]}
    />
  );
};
