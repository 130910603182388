import { ActionFeedback } from "@omnijus/common";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AtividadeService } from "services/atividade/atividade-service";
import { ParametroAdicionarAtividade } from "services/atividade/models/parametro-adicionar-atividade";
import { AtividadeFiltroService } from "services/filtros/filtros-atividades-service";
import { TipoAtividadePrincipal } from "services/filtros/models/tipo-atividade-principal";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { ButtonSecondary } from "shared/buttons/button-secondary/button-secondary";
import { OmnijusCheckboxAreas } from "shared/form/custom-fields/omnijus-checkbox-areas";
import { OmnijusFieldQualificacaoTempo } from "shared/form/custom-fields/omnijus-field-qualificacao-tempo";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import { OmnijusToggleField } from "shared/form/fields/omnijus-toggle-field";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import * as Yup from "yup";
import styles from "./adicionar-atividade.module.scss";

type NomesParametros<T extends object> = { [key in keyof T]: keyof T };
const formNames: NomesParametros<Required<ParametroAdicionarAtividade>> = {
    nome: "nome",
    tipoAtividade: "tipoAtividade",
    idsArea: "idsArea",
    ativo: "ativo",
    tempoExecucao: "tempoExecucao",
    numeroExecucaoAtividade: "numeroExecucaoAtividade",
    sla: "sla",
    tempoExecucaoRevisao: "tempoExecucaoRevisao",
    numeroExecucaoAtividadeRevisao: "numeroExecucaoAtividadeRevisao",
    slaRevisao: "slaRevisao",
    qualificacoes: "qualificacoes",
    qualificacoesRevisao: "qualificacoesRevisao",
};

const possuiRevisao = (idTipoAtividade: string, tiposAtividades: TipoAtividadePrincipal[]) =>
    tiposAtividades.find((tipoAtiv) => String(tipoAtiv.id) === idTipoAtividade)?.possuiAtividadeRevisao;

export const AdicionarAtividade = () => {
    const [promiseTipoAtividade, setPromiseTipoAtividade] = useState<Promise<TipoAtividadePrincipal[] | undefined>>();
    const history = useHistory();
    const initialValues: Partial<ParametroAdicionarAtividade> = {
        [formNames.nome]: "",
        [formNames.tipoAtividade]: "",
        [formNames.idsArea]: [],
        [formNames.ativo]: true,
        [formNames.tempoExecucao]: "",
        [formNames.numeroExecucaoAtividade]: "",
        [formNames.sla]: "",
        [formNames.tempoExecucaoRevisao]: "",
        [formNames.numeroExecucaoAtividadeRevisao]: "1",
        [formNames.slaRevisao]: "",
    };
    useEffect(() => {
        setPromiseTipoAtividade(AtividadeFiltroService.listarAtividadesPrincipais());
    }, []);
    return (
        <div className={styles.adicionarAtividade}>
            <Loading promise={promiseTipoAtividade}>
                {(tiposAtividades) =>
                    tiposAtividades ? (
                        <Formik
                            onSubmit={async (values) => {
                                const par: ParametroAdicionarAtividade = {
                                    nome: values.nome,
                                    tipoAtividade: values.tipoAtividade || "0",
                                    idsArea: values.idsArea,
                                    ativo: values.ativo || false,
                                    tempoExecucao: values.tempoExecucao || 0,
                                    numeroExecucaoAtividade: values.numeroExecucaoAtividade || 0,
                                    sla: values.sla || 0,
                                    tempoExecucaoRevisao: values.tempoExecucaoRevisao,
                                    numeroExecucaoAtividadeRevisao: 1,
                                    slaRevisao: values.slaRevisao,
                                    qualificacoes: values.qualificacoes,
                                    qualificacoesRevisao: values.qualificacoesRevisao,
                                };

                                await ActionFeedback.processing({
                                    title: "Adicionando atividade",
                                    execution: AtividadeService.adicionarAtividade(par),
                                });
                                await ActionFeedback.info({ title: "Atividade adicionada" });
                                history.goBack();
                            }}
                            initialValues={initialValues}
                            validationSchema={Yup.object().shape({
                                [formNames.nome]: Yup.string()
                                    .max(50, "Não pode ser maior que 50 caracteres")
                                    .required("Campo necessário"),
                                [formNames.tipoAtividade]: Yup.string().required("Campo necessário"),
                                [formNames.idsArea]: Yup.array()
                                    .min(1, "É necessário ao menos 1 item")
                                    .required("Campo necessário"),
                                [formNames.tempoExecucao]: Yup.number()
                                    .typeError("Deve ser um número")
                                    .min(0, "Deve ser maior ou igual a 0")
                                    .max(3600, "Deve ser menor ou igual a 3600")
                                    .required("Campo necessário"),
                                [formNames.numeroExecucaoAtividade]: Yup.number()
                                    .typeError("Deve ser um número")
                                    .required("Campo necessário"),
                                [formNames.sla]: Yup.number()
                                    .typeError("Deve ser um número")
                                    .required("Campo necessário"),
                                [formNames.qualificacoes]: Yup.array()
                                    .min(1, "É necessário ao menos 1 qualificação")
                                    .required("É necessário ao menos 1 qualificação"),
                                [formNames.tempoExecucaoRevisao]: Yup.number().when(formNames.tipoAtividade, {
                                    is: (val) => possuiRevisao(val, tiposAtividades),
                                    then: Yup.number()
                                        .typeError("Deve ser um número")
                                        .min(0, "Deve ser maior ou igual a 0")
                                        .max(3600, "Deve ser menor ou igual a 3600")
                                        .required("Campo necessário"),
                                }),
                                [formNames.numeroExecucaoAtividadeRevisao]: Yup.number().when(formNames.tipoAtividade, {
                                    is: (val) => possuiRevisao(val, tiposAtividades),
                                    then: Yup.number().typeError("Deve ser um número").required("Campo necessário"),
                                }),
                                [formNames.slaRevisao]: Yup.number().when(formNames.tipoAtividade, {
                                    is: (val) => possuiRevisao(val, tiposAtividades),
                                    then: Yup.number().typeError("Deve ser um número").required("Campo necessário"),
                                }),
                                [formNames.qualificacoesRevisao]: Yup.array().when(formNames.tipoAtividade, {
                                    is: (val) => possuiRevisao(val, tiposAtividades),
                                    then: Yup.array()
                                        .min(1, "É necessário ao menos 1 qualificação")
                                        .required("É necessário ao menos 1 qualificação"),
                                }),
                            })}
                        >
                            {({ values }) => (
                                <Form className={styles.cards}>
                                    <OmnijusCard
                                        header={<h3>Adicionar atividade</h3>}
                                        body={
                                            <div className={styles.formulario}>
                                                <OmnijusTextField label="Nome da Atividade" name={formNames.nome} />

                                                <OmnijusSelectField
                                                    options={tiposAtividades.map((opt) => ({
                                                        label: opt.descricao || "",
                                                        value: String(opt.id),
                                                    }))}
                                                    label="Tipo de Atividade"
                                                    name={formNames.tipoAtividade}
                                                />
                                                <OmnijusCheckboxAreas name={formNames.idsArea} />
                                                <OmnijusToggleField name={formNames.ativo} label="Ativo" />
                                                <OmnijusTextField
                                                    label="Tempo de Execução da Atividade (segundos)"
                                                    name={formNames.tempoExecucao}
                                                />
                                                <OmnijusTextField
                                                    label="Nº de execuções da Atividade"
                                                    name={formNames.numeroExecucaoAtividade}
                                                />
                                                <OmnijusTextField
                                                    label="SLA da Atividade (horas)"
                                                    name={formNames.sla}
                                                />
                                                <OmnijusFieldQualificacaoTempo name={formNames.qualificacoes} />
                                            </div>
                                        }
                                    />

                                    {values[formNames.tipoAtividade] &&
                                    possuiRevisao(values[formNames.tipoAtividade] as string, tiposAtividades) ? (
                                        <OmnijusCard
                                            header={<h3>Revisão</h3>}
                                            body={
                                                <div className={styles.formulario}>
                                                    <OmnijusTextField
                                                        label="Tempo de Execução da Atividade (segundos)"
                                                        name={formNames.tempoExecucaoRevisao}
                                                    />
                                                    <div>Nº de execuções da Atividade: 1</div>
                                                    <OmnijusTextField
                                                        label="SLA da Atividade (horas)"
                                                        name={formNames.slaRevisao}
                                                    />
                                                    <OmnijusFieldQualificacaoTempo
                                                        name={formNames.qualificacoesRevisao}
                                                    />
                                                </div>
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <div className={styles.linhaSubmit}>
                                        <ButtonPrimary type="submit">Salvar</ButtonPrimary>
                                        <ButtonSecondary onClick={() => history.goBack()}>Cancelar</ButtonSecondary>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    ) : (
                        <></>
                    )
                }
            </Loading>
        </div>
    );
};
