import { useField } from "formik";
import React from "react";
import Toggle from "react-toggle";
import styles from "../field.module.scss";
import "react-toggle/style.css";

interface OmnijusToggleFieldProps {
    name: string;
    activeLabel?: string;
    label?: string;
}

export const OmnijusToggleField = (props: OmnijusToggleFieldProps) => {
    const [field, meta] = useField(props.name);

    return (
        <div className={styles["field-control"]}>
            {props.label ? (
                <label className={styles["label"]} htmlFor={props.name}>
                    {props.label}
                </label>
            ) : null}
            <div className={styles["toggle-wrapper"]}>
                <Toggle name={field.name} checked={field.value} onChange={field.onChange} className={styles["toggle"]} icons={false} />
                <span>{props.activeLabel}</span>
            </div>
            {meta.touched && meta.error ?
                <p className={styles["error"]}>
                    {meta.error}
                </p>    
                :
                null
            }
        </div>
    );
}