import React from "react";
import styles from "./button-accent.module.scss";

interface ButtonAccentProps {
    value?: string;
    onClick?: () => void;
    children?: JSX.Element | string;
    type?: "button" | "submit" | "reset";
    className?: string;
    disabled?: boolean;
}

export const ButtonAccent = (props: ButtonAccentProps) => {
    return (
        <button
            type={props.type}
            onClick={props.onClick}
            className={styles.btnAccent + (props.className ? ` ${props.className}` : "")}
            value={props.value}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    );
};
