import { Form, Formik } from "formik";
import { ActionFeedback } from "@omnijus/common";
import React from "react";
import { adicionarConvite, atualizarConvite } from "services/onboarding/convite-service";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { OmnijusCampoCPF } from "shared/form/custom-fields/omnijus-campo-cpf";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styles from "./adicionar-convite.module.scss";
import { object as YupObject, string as YupString } from "yup";
import { Convite } from "services/onboarding/models/convite";
import { OmnijusCampoCelular } from "shared/form/custom-fields/omnijus-campo-celular";

const MySwal = withReactContent(Swal);

export const exibirModalAdicionarConvite = async (convite?: Convite) => {
    const atualizacao = !!convite;
    const result = await MySwal.fire({
        title: `${atualizacao ? "Atualizar" : "Adicionar"} Convite`,
        html: <AdicionarConvite convite={convite} />,
        showCancelButton: false,
        showConfirmButton: false,
        cancelButtonText: "Voltar",
        width: "820px",
    });

    if (result.isConfirmed && result.value) {
        const { nome, cpf, email, telefone } = result.value;
        await ActionFeedback.processing({
            title: `${atualizacao ? "Atualizando" : "Adicionando"} convite`,
            execution: atualizacao
                ? atualizarConvite(convite!.id, nome, cpf, email, telefone)
                : adicionarConvite(nome, cpf, email, telefone),
        });
        await ActionFeedback.info({ title: `Convite ${atualizacao ? "atualizado" : "adicionado"}` });
        return true;
    }
    return false;
};

const validationSchema = YupObject().shape({
    nome: YupString().required("Necessário informar o nome"),
    email: YupString().required("Necessário informar o email").email("Email inválido"),
    telefone: YupString().required("Necessário informar o telefone"),
    cpf: YupString().required("Necessário informar o CPF"),
});

export const AdicionarConvite = ({ convite }: { convite?: Convite }) => {
    return (
        <div>
            <div className={styles.form}>
                <Formik
                    initialValues={{
                        nome: convite?.nome || "",
                        cpf: convite?.cpf || "",
                        telefone: convite?.telefone || "",
                        email: convite?.email || "",
                    }}
                    onSubmit={async (values) => {
                        MySwal.close({ isConfirmed: true, value: values, isDenied: false, isDismissed: false });
                    }}
                    validationSchema={validationSchema}
                >
                    <Form>
                        <div className={styles.camposForm}>
                            <OmnijusTextField name="nome" label="Nome" />
                            <OmnijusCampoCPF name="cpf" label="CPF" />
                            <OmnijusTextField name="email" label="Email" />
                            <OmnijusCampoCelular name="telefone" label="Telefone" />
                        </div>
                        <div className={styles.botoes}>
                            <ButtonPrimary
                                type="button"
                                onClick={() => MySwal.close()}
                                className={styles.botaoCancelar}
                            >
                                Cancelar
                            </ButtonPrimary>
                            <ButtonPrimary type="submit">Salvar</ButtonPrimary>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};
