import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DashboardEmCadastroResponse } from "services/onboarding/models/dashboard-em-cadastro-response";
import { IdStatusCadastro } from "services/onboarding/models/id-status-cadastro";
import { getDashboardEmCadastro } from "services/onboarding/onboarding-service";
import { Loading } from "shared/loading/loading";
import styles from "./tabela-em-cadastro.module.scss";

export const TabelaEmCadastro = () => {
    const [promiseEmCadastro, setPromiseEmCadastro] = useState<Promise<DashboardEmCadastroResponse | undefined>>();

    useEffect(() => {
        setPromiseEmCadastro(getDashboardEmCadastro());
    }, []);

    return (
        <div className={styles.tabelaEmCadastro}>
            <h3>Etapa "Em Cadastro"</h3>
            <Loading promise={promiseEmCadastro}>
                {(emCadastroResponse) =>
                    emCadastroResponse ? (
                        <table>
                            <tbody>
                                {emCadastroResponse.items.map((i) => (
                                    <tr key={i.descricao}>
                                        <td>
                                            <Link
                                                className={styles.link}
                                                to={{
                                                    pathname: "/onboarding/cadastro",
                                                    search: new URLSearchParams({
                                                        IdEtapa: String(i.idEtapa),
                                                        IdStatus: String(IdStatusCadastro.EmCadastro),
                                                    }).toString(),
                                                }}
                                            >
                                                {i.descricao}
                                            </Link>
                                        </td>
                                        <td className={styles.quantidade}>
                                            <Link className={styles.link} to="#">
                                                {i.quantidade}
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <></>
                    )
                }
            </Loading>
        </div>
    );
};
