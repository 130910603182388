import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "services/client-id";
import { Inconsistencia } from "./models/inconsistencia";
import { InconsistenciaRequest } from "./models/inconsistencia-request";
import { TipoInconsistencia } from "./models/tipo-inconsistencia";

const onboardingUrl = process.env.REACT_APP_BFF_ADMINISTRATIVO_API_URL + "/api/Onboarding/";

const requestApi = new RequestApi(CLIENT_ID);

export const iniciarAnalise = (idCadastro: string) => {
    const url = onboardingUrl + `v1/Cadastro/${idCadastro}/IniciarAnalise`;
    return requestApi.put(url);
};

export const terminarAnalise = (idCadastro: string) => {
    const url = onboardingUrl + `v1/Cadastro/${idCadastro}/TerminarAnalise`;
    return requestApi.put(url);
};

export const aprovarCadastro = (idCadastro: string, qualificacoes: string[]) => {
    const url = onboardingUrl + `v1/Cadastro/${idCadastro}/AprovarCadastro`;
    return requestApi.put(url, { qualificacoes });
};

export const informarInconsistencia = (idCadastro: string, inconsistencia: InconsistenciaRequest) => {
    const url = onboardingUrl + `v1/Inconsistencia/${idCadastro}`;
    return requestApi.post(url, inconsistencia);
};

export const editarInconsistencia = (
    idCadastro: string,
    inconsistencia: InconsistenciaRequest,
    idInconsistencia: string
) => {
    const url = onboardingUrl + `v1/Inconsistencia/${idCadastro}/Editar/${idInconsistencia}`;
    return requestApi.put(url, inconsistencia);
};

export const deletarInconsistencia = (idCadastro: string, idInconsistencia: string) => {
    const url = onboardingUrl + `v1/Inconsistencia/${idCadastro}/Remover/${idInconsistencia}`;
    return requestApi.delete(url);
};

export const obterInconsistencias = async (idCadastro: string): Promise<Inconsistencia[] | undefined> => {
    const url = onboardingUrl + `v1/Inconsistencia/${idCadastro}`;
    const res = await requestApi.get<Inconsistencia[]>(url);
    return res?.map((inc) => ({
        ...inc,
        dataCriacao: inc.dataCriacao && new Date(inc.dataCriacao),
        dataResolucao: inc.dataResolucao && new Date(inc.dataResolucao),
    }));
};

export const listarTiposInconsistencia = () => {
    const url = onboardingUrl + "v1/Dominio/ListarTipoDeInconsistencias";
    return requestApi.get<TipoInconsistencia[]>(url);
};
