import { createContext, useContext } from "react";
import { Inconsistencia } from "services/onboarding/models/inconsistencia";

interface DetalhesCadastroInfo {
    analiseInconsistencias: boolean;
    atualizaInconsistencias?: () => Promise<Inconsistencia[] | undefined>;
    idCadastroPrestador?: string;
    inconsistencias?: Inconsistencia[];
    dataUltimoEmAnalise?: Date;
}
export const DetalhesCadastroInfoContext = createContext<DetalhesCadastroInfo>({
    analiseInconsistencias: false,
    atualizaInconsistencias: undefined,
    idCadastroPrestador: undefined,
});

export const useDetalhesCadastroInfoContext = () => useContext(DetalhesCadastroInfoContext);
