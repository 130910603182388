import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as TrashIcon } from "assets/images/trash-icon.svg";
import { FieldArray, useField, useFormikContext } from "formik";
import { ActionFeedback } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { PrestadorFiltroService } from "services/filtros/filtros-prestador-service";
import { FiltroQualificacao } from "services/filtros/models/filtro-qualificacao";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";
import { Loading } from "shared/loading/loading";
import styles from "./omnijus-field-qualificacao-tempo.module.scss";

interface OmnijusFieldQualificacaoTempoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
}

export const OmnijusFieldQualificacaoTempo = (props: OmnijusFieldQualificacaoTempoProps) => {
    const name = props.name || "QualificacaoTempo";
    const nameMeta = `${name}-meta`;
    const [promiseOpcoesQualificacao, setPromiseOpcoesQualificacao] =
        useState<Promise<FiltroQualificacao[] | undefined>>();
    const formikContext = useFormikContext<any>();
    const values = formikContext.values;
    const metaValues = values[nameMeta];
    const arrayValues: { idQualificacao: string }[] | undefined = values[name];
    const [, meta] = useField({ name });

    useEffect(() => {
        setPromiseOpcoesQualificacao(PrestadorFiltroService.listarQualificacao());
    }, []);

    return (
        <Loading promise={promiseOpcoesQualificacao}>
            {(opcoesQualificacao) =>
                opcoesQualificacao ? (
                    <FieldArray name={name}>
                        {(arrayHelpers) => (
                            <div className={styles.omnijusFieldQualificacao}>
                                <div className={styles.selects}>
                                    <div>
                                        <OmnijusSelectField
                                            name={`${nameMeta}.idQualificacao`}
                                            label={props.hideLabel ? undefined : props.label || "Qualificação"}
                                            options={opcoesQualificacao
                                                ?.map((o) => ({
                                                    value: o.id.toString(),
                                                    label: o.descricao || "",
                                                }))
                                                .filter(
                                                    (o) => !arrayValues?.find((val) => val.idQualificacao === o.value)
                                                )
                                                .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                                        />
                                    </div>
                                    <div className={styles.tempoEAdd}>
                                        <div className={styles.iconeAdd}>
                                            <AddIcon
                                                onClick={() => {
                                                    const idQualificacao = metaValues?.idQualificacao;
                                                    const tempoExperiencia = metaValues?.tempoExperiencia;
                                                    let erroQualificacao: string | undefined;
                                                    if (!idQualificacao) {
                                                        erroQualificacao = "Selecione a qualificação";
                                                    }
                                                    erroQualificacao &&
                                                        ActionFeedback.error({ title: erroQualificacao });
                                                    if (typeof erroQualificacao === "undefined") {
                                                        arrayHelpers.push({ idQualificacao, tempoExperiencia });
                                                        formikContext.setFieldValue(
                                                            `${nameMeta}.idQualificacao`,
                                                            undefined
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {<div className={styles.error}>{meta.error}</div>}
                                <div>
                                    {arrayValues?.map((qual, idx) => (
                                        <div key={qual.idQualificacao} className={styles.linhaQualificacaoga}>
                                            <span>
                                                {
                                                    opcoesQualificacao.find(
                                                        (op) => String(op.id) === qual.idQualificacao
                                                    )?.descricao
                                                }{" "}
                                            </span>
                                            <span className={styles.direita}>
                                                <div className={styles.iconeExcluir}>
                                                    <TrashIcon onClick={() => arrayHelpers.remove(idx)} />
                                                </div>
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </FieldArray>
                ) : (
                    <></>
                )
            }
        </Loading>
    );
};
