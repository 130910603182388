import React, { useEffect, useState } from "react";
import { ListarAtividades } from "services/atividade/models/listar-atividades";
import { AtividadeFiltroService } from "services/filtros/filtros-atividades-service";
import { Loading } from "shared/loading/loading";
import { OmnijusSelectWithCreateField } from "../fields/omnijus-select-with-create";

interface OmnijusFiltroAtividadeBuscaProps {
    hideLabel?: boolean;
    name?: string;
    textSearchName?: string;
    label?: string;
    idArea?: number;
    IdTipoAtividade?: number;
}

export const OmnijusFiltroAtividadeBusca = (props: OmnijusFiltroAtividadeBuscaProps) => {
    const [promiseOpcoesAtividade, setPromiseOpcoesAtividade] = useState<Promise<ListarAtividades[] | undefined>>();
    const name = props.name || "IdAtividade";

    useEffect(() => {
        setPromiseOpcoesAtividade(AtividadeFiltroService.listarAtividades(props.idArea, props.IdTipoAtividade));
    }, [props.idArea, props.IdTipoAtividade]);

    return (
        <Loading promise={promiseOpcoesAtividade}>
            {(opcoesArea) => (
                <OmnijusSelectWithCreateField
                    name={name}
                    label={props.hideLabel ? undefined : props.label || "Atividade"}
                    options={opcoesArea
                        ?.map((o) => ({
                            value: { id: o.idAtividade },
                            label: o.descricaoAtividade || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
