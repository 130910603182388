import React from "react";
import styles from "./auth-splash.module.scss";
import { OmnijusLogo } from "shared/omnijus-logo/omnijus-logo";

export const AuthSplash = () => {
    return (
        <div className={styles.authSplash}>
            <OmnijusLogo pulse={true} />
        </div>
    );
};
