export enum IdTipoAnexo {
    RGFrente = 1,
    RGVerso = 2,
    RegistroProfissionalFrente = 3,
    RegistroProfissionalVerso = 4,
    ComprovanteEndereco = 5,
    ExperienciaProfissional = 6,
    CertificadoAcademicoFrente = 7,
    CertificadoAcademicoVerso = 8,
    Curriculo = 9,
    Selfie = 10,
}
