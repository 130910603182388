import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as EditIcon } from "assets/images/edit-pen.svg";
import { ReactComponent as DeleteIcon } from "assets/images/trash-icon.svg";
import { FieldArray, Form, useField } from "formik";
import React from "react";
import { TipoEndereco } from "services/filtros/models/tipo-endereco";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { OmnijusCampoCEP } from "shared/form/custom-fields/omnijus-campo-cep";
import { OmnijusCampoTipoEndereco } from "shared/form/custom-fields/omnijus-campo-tipo-endereco";
import { OmnijusCampoUF } from "shared/form/custom-fields/omnijus-campo-uf";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import { showModalForm } from "shared/form/modal-form/modal-form";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import { array as YupArray, number as YupNumber, object as YupObject, string as YupString } from "yup";
import styles from "../cadastro-prestadores.module.scss";

export interface Endereco {
    id?: string;
    cep: string;
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    localidade: string;
    uf: string;
    tipos: TipoEndereco[];
}

export const enderecoValidation = YupObject().shape({
    cep: YupString()
        .matches(/\d{5}-\d{3}/, "CEP inválido")
        .required("Informe o CEP"),
    logradouro: YupString().required("Informe o logradouro"),
    numero: YupString().required("Informe o número"),
    bairro: YupString().required("Informe o bairro"),
    localidade: YupString().required("Informe a cidade"),
    uf: YupString().required("Informe o UF"),
    tipos: YupArray()
        .of(YupObject().shape({ id: YupNumber().min(0, "Informe o tipo do endereço") }))
        .required("Selecione pelo menos um tipo de endereço"),
});

export const modalNovoEndereco = (nomeAcao: string, valorInicial: Endereco) =>
    showModalForm(
        nomeAcao,
        valorInicial,
        <Form className={styles.modalForm}>
            <OmnijusCampoCEP name="cep" label="CEP" />
            <OmnijusTextField name="logradouro" label="Logradouro" />
            <OmnijusTextField name="numero" label="Número" />
            <OmnijusTextField name="complemento" label="Complemento" />
            <OmnijusTextField name="bairro" label="Bairro" />
            <OmnijusTextField name="localidade" label="Cidade" />
            <OmnijusCampoUF name="uf" label="UF" />
            <OmnijusCampoTipoEndereco name="tipos" label="Tipo" />
            <div className={styles.linhaSubmit}>
                <ButtonPrimary type="submit">{nomeAcao}</ButtonPrimary>
            </div>
        </Form>,
        "70rem",
        enderecoValidation
    );

export const Enderecos = ({
    values,
}: {
    values: {
        enderecos: Endereco[];
    };
}) => {
    const name = "enderecos";
    const [, meta] = useField({ name });
    return (
        <OmnijusCard
            header={<h3>Endereço</h3>}
            body={
                <FieldArray name={name}>
                    {(arrayHelpers) => (
                        <>
                            <table className={styles.table}>
                                <colgroup>
                                    <col className={styles.colCep} />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col className={styles.colIcones} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <td>CEP</td>
                                        <td>Logradouro</td>
                                        <td>Nº</td>
                                        <td>Complemento</td>
                                        <td>Bairro</td>
                                        <td>Cidade</td>
                                        <td>UF</td>
                                        <td>Tipo</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {values.enderecos.map((endereco, idx) => (
                                        <tr key={idx}>
                                            <td>{endereco.cep}</td>
                                            <td>{endereco.logradouro}</td>
                                            <td>{endereco.numero}</td>
                                            <td>{endereco.complemento}</td>
                                            <td>{endereco.bairro}</td>
                                            <td>{endereco.localidade}</td>
                                            <td>{endereco.uf}</td>
                                            <td>{endereco.tipos.map((t) => t.descricao).join(", ")}</td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className={styles.botaoIcone}
                                                    onClick={async () => {
                                                        const novoEndereco = await modalNovoEndereco(
                                                            "Editar Endereço",
                                                            endereco
                                                        );
                                                        novoEndereco && arrayHelpers.replace(idx, novoEndereco);
                                                    }}
                                                >
                                                    <EditIcon title="Ícone de editar endereço" />
                                                </button>
                                                <button
                                                    type="button"
                                                    className={styles.botaoIcone}
                                                    onClick={() => {
                                                        arrayHelpers.remove(idx);
                                                    }}
                                                >
                                                    <DeleteIcon title="Ícone de excluir endereço" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button
                                type="button"
                                className={styles.botaoIcone}
                                onClick={async () => {
                                    const novoEndereco = await modalNovoEndereco("Adicionar Endereço", {
                                        cep: "",
                                        logradouro: "",
                                        numero: "",
                                        complemento: "",
                                        bairro: "",
                                        localidade: "",
                                        uf: "",
                                        tipos: [],
                                    });
                                    novoEndereco && arrayHelpers.push(novoEndereco);
                                }}
                            >
                                <AddIcon title="Ícone de adicionar novo endereço" />
                            </button>
                            {meta.touched && meta.error && (
                                <div className={styles.error}>
                                    {Array.isArray(meta.error)
                                        ? meta.error.map((er) => Object.values(er).join(","))
                                        : typeof meta.error === "object"
                                        ? Object.values(meta.error).join(",")
                                        : meta.error}
                                </div>
                            )}
                        </>
                    )}
                </FieldArray>
            }
        />
    );
};
