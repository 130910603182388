import { useControleAcesso, WrapperAcessoAcao, WrapperAcessoFuncionalidade } from "@omnijus/common";
import React from "react";
import { PropsWithChildren } from "react";
import { NavLink, Route } from "react-router-dom";
import styles from "./navigation.module.scss";

export type TagsFuncionalidades =
    | "atividade"
    | "template"
    | "configuracao_de_template"
    | "cadastro_de_prestadores"
    | "pendencias"
    | "configuracoes"
    | "onboarding"
    | "relatorios"
    | "dead_letters";

export type TagsAcoes =
    | "atividade.adicionar_atividade.acesso_total"
    | "atividade.adicionar_template.excluir_utilizacao"
    | "atividade.adicionar_template.adicionar_utilizacao"
    | "atividade.adicionar_template.salvar_template"
    | "atividade.editar.excluir_utilizacao"
    | "atividade.editar.adicionar_utilizacao"
    | "atividade.editar.salvar_template"
    | "configuracao_de_template.adicionar_configuracao.acesso_total"
    | "cadastro_de_prestadores.adicionar_pessoa_fisica"
    | "cadastro_de_prestadores.enviar_notificacao"
    | "cadastro_de_prestadores.editar"
    | "pendencias.adicionar_pendencia.acesso_total"
    | "configuracoes.parametro.acesso_total"
    | "onboarding.dashboard.acesso_total"
    | "onboarding.cadastro.acesso_total"
    | "onboarding.convites.importar_convite"
    | "onboarding.convites.adicionar_convite"
    | "onboarding.convites.reenviar_notificacao"
    | "onboarding.fila_de_leads.enviar_convite"
    | "relatorios.acesso_total"
    | "dead_letters.enviar_em_lote"
    | "dead_letters.reenviar_mensagem";

export const WrapperAcessoFuncionalidadeAdmnistrativo = (props: PropsWithChildren<{ tags: TagsFuncionalidades[] }>) => (
    <WrapperAcessoFuncionalidade {...props} />
);
export const WrapperAcessoAcaoAdmnistrativo = (props: PropsWithChildren<{ tags: TagsAcoes[] }>) => (
    <WrapperAcessoAcao {...props} />
);

export function RotaControleAcesso(props: PropsWithChildren<{ path: string; tags: TagsFuncionalidades[] }>) {
    const controleAcesso = useControleAcesso();

    if (controleAcesso.possuiAcessoFuncionalidade(...props.tags)) {
        return (
            <Route exact path={props.path}>
                {props.children}
            </Route>
        );
    }

    return <p style={{ textAlign: "center" }}>Acesso negado</p>;
}

export function NavLinkControleAcesso(
    props: PropsWithChildren<{
        exact?: boolean;
        to: string;
        tags: TagsFuncionalidades[];
        className?: string;
        isActive?: boolean;
    }>
) {
    return (
        <WrapperAcessoFuncionalidade tags={props.tags}>
            <NavLink className={styles.navLink} activeClassName={styles.active} to={props.to} exact={props.exact}>
                {props.children}
            </NavLink>
        </WrapperAcessoFuncionalidade>
    );
}
