import classNames from "classnames";
import { Form, FormikProps } from "formik";
import { ActionFeedback } from "@omnijus/common";
import React from "react";
import { ParametrosPesquisarCadastro } from "services/onboarding/models/parametros-pesquisa-cadastro";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { OmnijusDateField } from "shared/form/fields/omnijus-date-field";
import { OmnijusRadioField } from "shared/form/fields/omnijus-radio-field";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import { OmnijusTextAreaField } from "shared/form/fields/omnijus-textarea-field";
import { FiltrosAplicados } from "shared/form/filtros-aplicados/filtros-aplicados";
import { showModalForm } from "shared/form/modal-form/modal-form";
import { ValoresComboContext, ValoresCombos } from "shared/form/valores-combo-context";
import { date as YupDate, object as YupObject, string as YupString } from "yup";
import styles from "./enviar-notificacoes.module.scss";

const validationSchema = YupObject().shape({
    titulo: YupString().trim().required("Informe o título"),
    mensagem: YupString().trim().required("Informe a mensagem"),
    dataAgendamento: YupDate().when("agendar", {
        is: "true",
        then: YupDate().required("Selecione a data").typeError("Selecione a data"),
    }),
});

interface FormFieldsNotificacoes {
    titulo: string;
    mensagem: string;
    agendar: string;
    dataAgendamento?: Date;
}

export const exibirModalEnviarNotificacoes = async <T extends object>(
    filtro: T,
    valoresCombos: ValoresCombos,
    enviaNotificacoes: (filtros: T, titulo: string, mensagem: string, dataAgendamento?: Date) => Promise<any>
) => {
    const res = await showModalForm(
        "Enviar Notificações",
        { titulo: "", mensagem: "", agendar: "true", dataAgendamento: undefined },

        ({ values }: FormikProps<FormFieldsNotificacoes>) => {
            return <EnviarNotificacoes values={values} filtrosAplicados={filtro} valoresCombos={valoresCombos} />;
        },
        "650px",
        validationSchema
    );
    if (res) {
        await ActionFeedback.processing({
            title: "Enviando notificações",
            execution: enviaNotificacoes(
                filtro,
                res.titulo,
                res.mensagem,
                res.agendar === "true" ? res.dataAgendamento : undefined
            ),
        });
        await ActionFeedback.info({ title: "Notificações enviadas" });
    }
};

interface EnviarNotificacoesProps {
    values: FormFieldsNotificacoes;
    filtrosAplicados: ParametrosPesquisarCadastro;
    valoresCombos: ValoresCombos;
}
export const EnviarNotificacoes = ({ values, filtrosAplicados, valoresCombos }: EnviarNotificacoesProps) => {
    return (
        <div>
            <div className={styles.texto}>Enviar notificações para os filtros informados na tela de pesquisa</div>
            {Object.entries(filtrosAplicados).filter((e) => e[1]).length === 0 && (
                <div className={styles.alerta}>
                    Notificação será enviada para toda a base, pois não foram aplicados filtros
                </div>
            )}
            <div>
                <ValoresComboContext.Provider value={{ valoresCombos: valoresCombos, setValoresCombos: () => {} }}>
                    <FiltrosAplicados parametros={filtrosAplicados} />
                </ValoresComboContext.Provider>
            </div>
            <Form>
                <div className={styles.form}>
                    <div className={classNames(styles.campoForm, styles.campoTitulo)}>
                        <div className={styles.label}>Título:</div>
                        <div className={styles.inline}>
                            <OmnijusTextField name="titulo" />
                        </div>
                    </div>
                    <div className={styles.campoForm}>
                        <div className={styles.label}>Mensagem:</div>
                        <div className={styles.inline}>
                            <OmnijusTextAreaField name="mensagem" />
                        </div>
                    </div>
                    <div className={styles.campoForm}>
                        <div className={styles.label}></div>
                        <div className={styles.agendar}>
                            <div className={styles.radioAgendar}>
                                <OmnijusRadioField
                                    vertical
                                    name="agendar"
                                    options={[
                                        { label: "Enviar agora", value: "false" },
                                        { label: "Agendar", value: "true" },
                                    ]}
                                />
                            </div>
                            {values.agendar === "true" && (
                                <div className={styles.agendamento}>
                                    <span className={styles.labelDataAgendamento}>Data de agendamento</span>
                                    <span className={styles.dataAgendamento}>
                                        <OmnijusDateField name="dataAgendamento" />
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${styles.acoes}`}>
                    <ButtonPrimary className={styles.botaoSubmit} type="submit">
                        Enviar Notificações
                    </ButtonPrimary>
                </div>
            </Form>
        </div>
    );
};
