import { Form, Formik } from "formik";
import { ActionFeedback } from "@omnijus/common";
import React from "react";
import { gerarPendenciaGuiaPagamento } from "services/pendencia/pendencia-service";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { OmnijusCampoNumeroProcesso } from "shared/form/custom-fields/omnijus-campo-numero-processo";
import { OmnijusDateField } from "shared/form/fields/omnijus-date-field";
import { OmnijusFileFieldMultiple } from "shared/form/fields/omnijus-file-field";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import { date as YupDate, object as YupObject, string as YupString, number as YupNumber, mixed as YupMixed } from "yup";
import styles from "./gerar-pendencia-pagamento.module.scss";
import { useHistory } from "react-router";

const validationSchema = YupObject().shape({
    NumeroProcesso: YupString().required("Informe o número do processo").min(25, "Número do processo inválido"),
    // dataVencimento: YupDate().required("Informe a data de vencimento").typeError("Informe a data de vencimento"),
    prazoExecucao: YupDate().required("Informe a data de vencimento").typeError("Informe a data de vencimento"),
    tipo: YupNumber().required("Informe o tipo de pendência").min(1, "Informe o tipo de pendência"),
    descricao: YupString().required("Informe a descrição da pendência"),
    guia: YupMixed().required("Selecione o(s) arquivo(s) da guia de pagamento").nullable(),
    // .test("arquivo", "Selecione o arquivo da guia de pagamento", (file) => {
    //     return file !== null && file !== undefined;
    // }),
});
export const GerarPendenciaPagamento = () => {
    const history = useHistory();
    return (
        <div>
            <h2>Gerar pendência</h2>
            <Formik
                onSubmit={async (values) => {
                    await ActionFeedback.processing({
                        title: "Gerando pendência",
                        execution: gerarPendenciaGuiaPagamento(
                            values.NumeroProcesso,
                            values.prazoExecucao!,
                            values.descricao,
                            values.guia!
                        ),
                        onError: async (error: any) =>
                            await ActionFeedback.error({
                                icon: "error",
                                html: error?.json?.erros
                                    ? error.json.erros.join("<br />")
                                    : error?.json?.title
                                    ? error?.json?.title
                                    : "Ocorreu um erro na geração da pendência",
                            }),
                    });

                    await ActionFeedback.info({
                        text: "Pendência gerada com sucesso",
                    });

                    history.push("/pendencias");
                }}
                initialValues={{
                    NumeroProcesso: "",
                    tipo: 3,
                    // dataVencimento: undefined as Date | undefined,
                    prazoExecucao: undefined as Date | undefined,
                    guia: null as FileList | null,
                    descricao: "",
                }}
                validationSchema={validationSchema}
            >
                {({ values, errors }) => (
                    <Form>
                        <OmnijusCard
                            body={
                                <div className={styles.form}>
                                    <OmnijusSelectField
                                        options={[{ label: "Pagamento de Guias", value: "3" }]}
                                        name="tipo"
                                        label="Pendência"
                                    />
                                    {/* <OmnijusFiltroCliente label="Cliente" name="cliente" /> */}
                                    <OmnijusCampoNumeroProcesso name="NumeroProcesso" label="Número do Processo" />
                                    {/* <OmnijusDateField name="dataVencimento" label="Data de Vencimento" /> */}
                                    <OmnijusDateField name="prazoExecucao" label="Prazo para Execução" />
                                    <OmnijusTextField name="descricao" label="Descrição" />
                                    <OmnijusFileFieldMultiple name="guia" label="Guia" />
                                </div>
                            }
                        />
                        <ButtonPrimary className={styles.submit} type="submit">
                            Concluir
                        </ButtonPrimary>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
