import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "services/client-id";
import { ListarAtividades } from "./models/listar-atividades";
import { ObterAtividade } from "./models/obter-atividade";
import { ParametroAdicionarAtividade } from "./models/parametro-adicionar-atividade";
import { ParametrosListarAtividades } from "./models/parametros-listar-atividades";

const atividadePrestadorUrl = process.env.REACT_APP_ATIVIDADE_API_URL + "/Atividades";

const requestApi = new RequestApi(CLIENT_ID);

export const AtividadeService = {
    listarAtividades: (parametros?: ParametrosListarAtividades) => {
        const url = new URL(atividadePrestadorUrl);

        if (parametros) {
            url.search = RequestApi.objectToQueryString(parametros);
        }

        return requestApi.get<ListarAtividades[]>(url.toString());
    },
    obterAtividade: (idAtividade: string) => {
        const url = `${atividadePrestadorUrl}/${idAtividade}`;
        return requestApi.get<ObterAtividade>(url);
    },
    adicionarAtividade: (atividade: ParametroAdicionarAtividade) => {
        const url = atividadePrestadorUrl;
        return requestApi.post(url, { ...atividade, tipoAtividade: { id: atividade.tipoAtividade } });
    },
};
