import classNames from "classnames";
import React from "react";
import styles from "./forms.module.scss";

export interface campoProps {
    label: string;
    valor: string | null | undefined;
    readonly: boolean;
    onChange?: any;
}

export const CampoForm = (props: campoProps) => {

    return (
        <div className={classNames(styles.campo, styles.campoCadastro)}>
            <span className={styles.rotulo}>{props.label}:</span>
            <div className={styles.valorAcoes}>
                <span className={styles.valor}>
                    {props.valor}
                </span>
            </div>
        </div>
    );
};


export const JsonForm = (props: campoProps) => {
    let isValid = IsJsonString(props.valor ?? '');
    const valorFormatado = FormatJson(props.valor || '');

    return (
        <div className={classNames(styles.campo, styles.campoCadastro)}>
            <span className={styles.rotulo}>{props.label}:</span>
            <div className={styles.valorAcoes}>
                <textarea
                    onChange={v => props.onChange(v.target.value)}
                    readOnly={props.readonly ?? false}
                    className={classNames(styles.textArea, isValid ? styles.success : styles.danger)}>
                    {valorFormatado}
                </textarea>
            </div>
        </div >
    );
};

function IsJsonString(str: String) {
    try {
        var json = JSON.parse(str.toString());
        return (typeof json === 'object');
    } catch (e) {
        return false;
    }
}

export const FormatJson = (str: string) => {
    const isValid = IsJsonString(str ?? '');

    if (isValid) {
        let json = JSON.parse(str);
        return JSON.stringify(json, undefined, 4);
    } else {
        return str;
    }
}