import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { FiltrosClienteService } from "services/filtros/filtros-cliente-service";
import { FiltroEmpresa } from "services/filtros/models/filtro-empresa";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";
import { Loading } from "shared/loading/loading";

const filtroContratoName = "IdContrato";

interface OmnijusFiltroEmpresaProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    reloadOnChangeName?: string;
}

export const OmnijusFiltroEmpresa = (props: OmnijusFiltroEmpresaProps) => {
    const idContrato = useFormikContext<any>().values[props.reloadOnChangeName || filtroContratoName];
    const [promiseOpcoesEmpresas, setPromiseOpcoesEmpresas] = useState<Promise<FiltroEmpresa[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoesEmpresas(FiltrosClienteService.listarEmpresas(idContrato));
    }, [idContrato, props.reloadOnChangeName]);

    return (
        <Loading promise={promiseOpcoesEmpresas}>
            {(opcoesEmpresas) => (
                <OmnijusSelectField
                    name={props.name || "IdEmpresa"}
                    label={props.hideLabel ? undefined : props.label || "Rito"}
                    options={opcoesEmpresas
                        ?.map((o) => ({
                            value: o.idContratoClienteServico,
                            label: o.nomeFantasia || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
