import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "services/client-id";
import { Parametro } from "./models/parametro";
import { ParametroListado } from "./models/parametro-listado";

const configuracoesUrl = process.env.REACT_APP_BFF_ADMINISTRATIVO_API_URL + "/api/Configuracoes/";

const requestApi = new RequestApi(CLIENT_ID);

export const listarParametros = async (): Promise<ParametroListado[] | undefined> => {
    const url = new URL(`${configuracoesUrl}v1/Parametros`);
    const res = await requestApi.get<ParametroListado[]>(url.toString());
    return res?.map((parametro) => ({
        ...parametro,
        dataHoraAtualizacao: parametro.dataHoraAtualizacao && new Date(parametro.dataHoraAtualizacao),
    }));
};

export const obterParametro = async (idParametro: number): Promise<Parametro | undefined> => {
    const url = new URL(`${configuracoesUrl}v1/Parametros/${idParametro}`);
    return await requestApi.get<Parametro>(url.toString());
};

export const atualizarParametro = async (idParametro: number, novoValor: string) => {
    const url = new URL(`${configuracoesUrl}v1/Parametros`);
    return await requestApi.put(url.toString(), { idParametro, novoValor });
};
