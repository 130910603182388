import classNames from "classnames";
import { Location, LocationDescriptor, LocationState } from "history";
import { Format } from "lib/format";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./card.module.scss";

export enum Color {
    Green,
    Orange,
    Red,
    Blue,
    LimeGreen,
}

export interface CardProps<S = LocationState> {
    topText?: string;
    topLeft?: boolean;
    quantity: number;
    name?: string;
    linkTo: LocationDescriptor<S> | ((location: Location<S>) => LocationDescriptor<S>);
    color: Color;
    big?: boolean;
}
export const Card = (props: CardProps) => {
    return (
        <Link
            to={props.linkTo}
            className={classNames({
                [styles.card]: true,
                [styles.big]: props.big,
                [styles.orange]: props.color === Color.Orange,
                [styles.red]: props.color === Color.Red,
                // [styles.green]: props.color === Color.Green,
                [styles.blue]: props.color === Color.Blue,
                [styles.limeGreen]: props.color === Color.LimeGreen,
            })}
            title={props.name || props.topText}
        >
            <div className={classNames({ [styles.top]: true, [styles.left]: props.topLeft })}>{props.topText}</div>
            <div className={styles.main}>{Format.toNumberString(props.quantity)}</div>
            <div className={styles.bottom}>
                <div className={styles.text}>{props.name}</div>
            </div>
            <div />
        </Link>
    );
};
