import React, { useEffect, useState } from "react";
import { getDashboardOnboarding } from "services/onboarding/onboarding-service";
import { Loading } from "shared/loading/loading";
import { Card } from "../card/card";
import { DashboardOnboardingItem } from "services/onboarding/models/dashboard-onboarding-response";
import styles from "./cards-cadastro.module.scss";
import { IdStatusCadastro } from "services/onboarding/models/id-status-cadastro";


export const CardsCadastro = () => {
    const [promiseOnboarding, setPromiseOnboarding] = useState<Promise<DashboardOnboardingItem[] | undefined>>();
    const [total, setTotal] = useState<number>(0);

    const toPercent = (n: number): string => {
        return total ? `${Math.round(n / total * 100)}%` : '';
    };


    useEffect(() => {
        setPromiseOnboarding(getDashboardOnboarding().then((res) => {
            var filtereds = res!.items.filter((i) =>
                [
                    IdStatusCadastro.EmCadastro,
                    IdStatusCadastro.EmAnalise,
                    IdStatusCadastro.Inconsistente,
                    IdStatusCadastro.Aprovado,
                ].includes(i.idStatus));

            setTotal(filtereds.map(x => x.quantidade).reduce((acc, cur) => acc += cur));

            return filtereds;
        }));
    }, []);
    return (
        <div className={styles.cardsCadastro}>
            <h3>Onboarding</h3>
            <Loading promise={promiseOnboarding}>
                {(dashboardItems) =>
                    dashboardItems ? (
                        <>
                            <div className={styles.cards}>
                                {dashboardItems
                                    .map((i, idx) => (
                                        <Card
                                            key={i.descricao}
                                            color={idx % 3}
                                            quantity={i.quantidade}
                                            topText={toPercent(i.quantidade)}
                                            name={i.descricao}
                                            linkTo={{
                                                pathname: "/onboarding/cadastro",
                                                search: new URLSearchParams({
                                                    IdStatus: String(i.idStatus),
                                                }).toString(),
                                            }}
                                        />
                                    ))}
                            </div>
                            <div className={styles.total}>
                                <span>Total em processo de cadastro:</span> <strong>{total}</strong>
                            </div>
                        </>
                    ) : (
                        <></>
                    )
                }
            </Loading>
        </div>
    );
};
