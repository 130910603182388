import classNames from "classnames";
import { Form, Formik } from "formik";
import { ActionFeedback } from "@omnijus/common";
import React from "react";
import { Anexo } from "services/onboarding/models/anexo";
import { EtapaCadastro } from "services/onboarding/models/etapa-cadastro";
import { Inconsistencia } from "services/onboarding/models/inconsistencia";
import {
    deletarInconsistencia,
    editarInconsistencia,
    informarInconsistencia,
} from "services/onboarding/onboarding-inconsistencias-service";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { ButtonSecondary } from "shared/buttons/button-secondary/button-secondary";
import { OmnijusCampoTipoInconsistencia } from "shared/form/custom-fields/omnijus-campo-tipo-inconsistencia";
import { OmnijusTextAreaField } from "shared/form/fields/omnijus-textarea-field";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { string as YupString, object as YupObject } from "yup";
import styles from "./criar-inconsistencia.module.scss";

const validationSchema = YupObject().shape({
    idTipoInconsistencia: YupString().required("É preciso informar o tipo da inconsistência"),
});
export interface CriarInconsistenciaProps {
    idCadastroPrestador: string;
    idFormacaoAcademica?: string;
    idExperienciaProfissional?: string;
    nome?: string;
    rotulo: string;
    etapa: EtapaCadastro;
    anexo?: Anexo;
    inconsistencia?: Inconsistencia;

    link?: boolean;
    format?: (valor?: string) => React.ReactNode;
    atualizaInconsistencias?: () => Promise<Inconsistencia[] | undefined>;
}

const MySwal = withReactContent(Swal);

export const exibirModalCriarInconsistencia = async (props: CriarInconsistenciaProps) => {
    return MySwal.fire({
        title: "Inconsistência",
        html: <CriarInconsistencia {...props} />,
        showCancelButton: false,
        showConfirmButton: false,
        cancelButtonText: "Voltar",
        showCloseButton: true,
        width: "600px",
        customClass: { closeButton: styles.closeButton },
    });
};

export const CriarInconsistencia = (props: CriarInconsistenciaProps) => {
    const { rotulo, etapa, idCadastroPrestador, atualizaInconsistencias, inconsistencia } = props;

    return (
        <div>
            <div className={styles.etapaECampo}>
                <span>
                    <span className={styles.label}>Etapa:</span>
                    <span>{etapa.descricao}</span>
                </span>
                <span>
                    <span className={styles.label}>Campo:</span>
                    <span>{rotulo}</span>
                </span>
            </div>
            <div className={styles.table}>
                <Formik
                    initialValues={{
                        descricao: inconsistencia?.descricao || "",
                        idTipoInconsistencia: String(inconsistencia?.tipoInconsistencia?.id || ""),
                    }}
                    onSubmit={async (values) => {
                        var inconsistenciaRequest = {
                            campos: props.nome || "",
                            idTipoInconsistencia: values.idTipoInconsistencia,
                            idTipoAnexo: props.anexo?.idTipoAnexo,
                            descricao: values.descricao,
                            idAnexo: props.anexo?.id,
                            idExperienciaProfissional: props.idExperienciaProfissional,
                            idFormacaoAcademica: props.idFormacaoAcademica,
                            idEtapaCadastro: props.etapa.id,
                        };
                        await ActionFeedback.processing({
                            title: `${inconsistencia ? "Editando" : "Criando"} inconsistência`,
                            execution: (inconsistencia
                                ? editarInconsistencia(idCadastroPrestador, inconsistenciaRequest, inconsistencia.id)
                                : informarInconsistencia(idCadastroPrestador, inconsistenciaRequest)
                            ).then(() => {
                                atualizaInconsistencias && atualizaInconsistencias();
                            }),
                        });
                        MySwal.close();
                    }}
                    validationSchema={validationSchema}
                >
                    <Form>
                        <label className={classNames(styles.campoInconsistencia, styles.select)}>
                            <span className={styles.label}>Inconsistência:</span>
                            <OmnijusCampoTipoInconsistencia name="idTipoInconsistencia" />
                        </label>
                        <label className={styles.campoInconsistencia}>
                            <span className={styles.label}>Descrição:</span>
                            <OmnijusTextAreaField name="descricao" />
                        </label>
                        {inconsistencia && (
                            <ButtonSecondary
                                className={styles.botaoApagarInconsistencia}
                                onClick={async () => {
                                    await ActionFeedback.processing({
                                        title: "Deletando inconsistência",
                                        execution: deletarInconsistencia(idCadastroPrestador, inconsistencia.id).then(
                                            () => {
                                                atualizaInconsistencias && atualizaInconsistencias();
                                            }
                                        ),
                                    });
                                    await ActionFeedback.info({ title: "Inconsistência apagada" });
                                    MySwal.close();
                                }}
                            >
                                Apagar inconsistencia
                            </ButtonSecondary>
                        )}
                        <ButtonPrimary type="submit">Salvar inconsistencia</ButtonPrimary>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};
