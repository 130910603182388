import Cleave from "cleave.js/react";
import { Field, FieldProps } from "formik";
import styles from "../field.module.scss";
import React from "react";

export const OmnijusCampoCEP = (props: {
    name: string;
    placeholder?: string;
    label?: string;
    className?: string;
}) => {
    return (
        <div className={`${styles["field-control"]} ${props.className || ""}`}>
            {props.label ? (
                <label className={styles["label"]} htmlFor={props.name}>
                    {props.label}
                </label>
            ) : null}
            <Field name={props.name} id={props.name}>
                {({ field, meta }: FieldProps) => (
                    <>
                        <Cleave
                            options={{ blocks: [5, 3], delimiters: ["-"], numericOnly:true}} 
                            className={styles["input"]}
                            id={props.name}
                            {...field}
                        />
                        <div className={styles["error"]}>{meta.touched && meta.error ? meta.error : ""}</div>
                    </>
                )}
            </Field>
        </div>
    );
};
