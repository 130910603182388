import { dateToDDMMYYYY, getTimeHHmm } from "lib/time";
import React, { useEffect, useState } from "react";
import { AlteracaoPorAnexo } from "services/onboarding/models/alteracao-anexo";
import { AlteracaoPorPropriedade } from "services/onboarding/models/alteracao-propriedade";
import { EtapaCadastro } from "services/onboarding/models/etapa-cadastro";
import { IdTipoAnexo } from "services/onboarding/models/id-tipo-anexo";
import {
    getListarHistoricoAlteracoes,
    getListarHistoricoAlteracoesPorAnexo,
} from "services/onboarding/onboarding-historico-service";
import { Loading } from "shared/loading/loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styles from "./historico-campo-cadastro.module.scss";

export interface HistoricoCampoCadastroProps {
    idCadastroPrestador: string;
    idFormacaoAcademica?: string;
    idExperienciaProfissional?: string;
    nome?: string;
    rotulo: string;
    etapa: EtapaCadastro;
    idTipoAnexo?: IdTipoAnexo;

    link?: boolean;
    format?: (valor?: string) => React.ReactNode;
}

const MySwal = withReactContent(Swal);

export const exibirModalHistoricoCampo = async (props: HistoricoCampoCadastroProps) => {
    return MySwal.fire({
        title: "Histórico",
        html: <HistoricoCampoCadastro {...props} />,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "Voltar",
        width: "814px",
        customClass: styles.historicoCampoCadastro,
    });
};

const TabelaHistoricoCampoTexto = ({
    link,
    idCadastroPrestador,
    idFormacaoAcademica,
    idExperienciaProfissional,
    nome,
    format,
}: {
    idCadastroPrestador: string;
    idFormacaoAcademica?: string;
    idExperienciaProfissional?: string;
    nome: string;

    link?: boolean;
    format?: (valor?: string) => React.ReactNode;
}) => {
    const [promiseAlteracoes, setPromiseAlteracoes] = useState<Promise<AlteracaoPorPropriedade[] | undefined>>();
    useEffect(() => {
        setPromiseAlteracoes(
            getListarHistoricoAlteracoes(idCadastroPrestador, nome, idFormacaoAcademica || idExperienciaProfissional)
        );
    }, [idCadastroPrestador, nome, idFormacaoAcademica, idExperienciaProfissional]);
    return (
        <Loading promise={promiseAlteracoes}>
            {(alteracoes) =>
                alteracoes ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Valor antigo</th>
                                <th>Valor novo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {alteracoes.map((alteracao) => (
                                <tr key={`${alteracao.dataAlteracao}${alteracao.valorAnterior}`}>
                                    <td>
                                        {alteracao.dataAlteracao && (
                                            <>
                                                <span>{dateToDDMMYYYY(alteracao.dataAlteracao)}</span>
                                                <span className={styles.horaAlteracao}>
                                                    {getTimeHHmm(alteracao.dataAlteracao)}
                                                </span>
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        {link
                                            ? alteracao.valorAnterior && (
                                                  <a
                                                      href={alteracao.valorAnterior}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                  >
                                                      {alteracao.valorAnterior}
                                                  </a>
                                              )
                                            : format
                                            ? format(alteracao.valorAnterior || undefined)
                                            : alteracao.valorAnterior}
                                    </td>
                                    <td>
                                        {link
                                            ? alteracao.valorAtual && (
                                                  <a
                                                      href={alteracao.valorAtual}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                  >
                                                      {alteracao.valorAtual}
                                                  </a>
                                              )
                                            : format
                                            ? format(alteracao.valorAtual || undefined)
                                            : alteracao.valorAtual}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <></>
                )
            }
        </Loading>
    );
};
const TabelaHistoricoCampoAnexo = ({
    idCadastroPrestador,
    idTipoAnexo,
    idFormacaoAcademica,
}: {
    idCadastroPrestador: string;
    idFormacaoAcademica?: string;
    idTipoAnexo: IdTipoAnexo;
}) => {
    const [promiseAlteracoes, setPromiseAlteracoes] = useState<Promise<AlteracaoPorAnexo[] | undefined>>();
    useEffect(() => {
        setPromiseAlteracoes(
            getListarHistoricoAlteracoesPorAnexo(idCadastroPrestador, idTipoAnexo, idFormacaoAcademica)
        );
    }, [idCadastroPrestador, idFormacaoAcademica, idTipoAnexo]);
    return (
        <Loading promise={promiseAlteracoes}>
            {(alteracoes) =>
                alteracoes ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Valor antigo</th>
                                <th>Valor novo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {alteracoes.map((alteracao) => (
                                <tr key={`${alteracao.dataAlteracao}${alteracao.urlAnexoAtual}`}>
                                    <td>
                                        {alteracao.dataAlteracao && (
                                            <>
                                                <span>{dateToDDMMYYYY(alteracao.dataAlteracao)}</span>
                                                <span className={styles.horaAlteracao}>
                                                    {getTimeHHmm(alteracao.dataAlteracao)}
                                                </span>
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        {alteracao.urlAnexoAnterior && (
                                            <a
                                                href={alteracao.urlAnexoAnterior}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {alteracao.nomeOriginalAnterior || "anexo sem nome"}
                                            </a>
                                        )}
                                    </td>
                                    <td>
                                        {alteracao.urlAnexoAtual && (
                                            <a href={alteracao.urlAnexoAtual} target="_blank" rel="noopener noreferrer">
                                                {alteracao.nomeOriginalAtual || "anexo sem nome"}
                                            </a>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <></>
                )
            }
        </Loading>
    );
};

export const HistoricoCampoCadastro = (props: HistoricoCampoCadastroProps) => {
    const { rotulo, etapa, idTipoAnexo, nome } = props;
    return (
        <div>
            <div className={styles.etapaECampo}>
                <span>
                    <span className={styles.label}>Etapa:</span>
                    <span>{etapa.descricao}</span>
                </span>
                <span>
                    <span className={styles.label}>Campo:</span>
                    <span>{rotulo}</span>
                </span>
            </div>
            <div className={styles.table}>
                {(idTipoAnexo && <TabelaHistoricoCampoAnexo {...props} idTipoAnexo={idTipoAnexo} />) || (
                    <TabelaHistoricoCampoTexto {...props} nome={nome!} />
                )}
            </div>
        </div>
    );
};
