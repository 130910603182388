import React, { useEffect, useState, useRef } from "react";
import styles from "./dropdown.module.scss";

interface DropdownProps {
    options?: Array<{ label: string; onClick: () => void }>;
    children: React.ReactNode;
    items?: React.ReactNode;
}

export const Dropdown = (props: DropdownProps) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (showDropdown && dropdownElement.current) {
            const clickListener = (e: MouseEvent) => {
                let elementClicked = e.target;

                if (elementClicked instanceof Node && !dropdownElement.current?.contains(elementClicked)) {
                    setShowDropdown(false);
                }
            };

            window.addEventListener("click", clickListener);

            return () => window.removeEventListener("click", clickListener);
        }
    }, [showDropdown]);

    return (
        <div ref={dropdownElement} className={styles["dropdown"]} onClick={() => setShowDropdown(!showDropdown)}>
            {props.children}
            {showDropdown ? (
                props.options ? (
                    <ul role="navigation" className={styles["menu"]}>
                        {props.options.map((option, index) => (
                            <li key={`${option.label}${index}`} onClick={() => option.onClick()}>
                                {option.label}
                            </li>
                        ))}
                    </ul>
                ) : (
                    props.items
                )
            ) : null}
        </div>
    );
};
