import React from "react";
import { Anexo } from "services/onboarding/models/anexo";
import { IdEtapaCadastro } from "services/onboarding/models/id-etapa-cadastro";
import { CampoCadastro, CampoImagem } from "../campo-cadastro/campo-cadastro";
import { CardCadastro } from "../card-cadastro/card-cadastro";
import styles from "./dados-do-contato.module.scss";

export interface DadosDoContatoProps {
    nome: string;
    email: string;
    cpf: string;
    telefone: string;
    selfie?: Anexo;
}
const etapa = { descricao: "Dados do contato", id: IdEtapaCadastro.DadosContato };

export const DadosDoContato = ({ nome, email, cpf, telefone, selfie }: DadosDoContatoProps) => {
    return (
        <CardCadastro
            header={etapa.descricao}
            body={
                <div className={styles.dadosDoContato}>
                    <div>
                        <CampoCadastro rotulo="Nome" valor={nome} nome="nome" />
                        <CampoCadastro rotulo="Email" valor={email} nome="email" />
                        <CampoCadastro rotulo="CPF" valor={cpf} nome="cpf" />
                        <CampoCadastro rotulo="Telefone" valor={telefone} nome="telefone" />
                    </div>
                    <div>
                        <CampoImagem rotulo="Selfie" anexo={selfie} />
                    </div>
                </div>
            }
            etapa={etapa}
        />
    );
};
