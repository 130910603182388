import React from "react";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";

export const listaUfs = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
];

export const OmnijusCampoUF = (props: { hideLabel?: boolean; name?: string; label?: string; disabled?: boolean }) => {
    return (
        <OmnijusSelectField
            isDisabled={props.disabled}
            name={props.name || "UF"}
            label={props.hideLabel ? undefined : props.label || "UF"}
            options={listaUfs.map((uf) => ({ label: uf, value: uf }))}
        />
    );
};
