import { Field, FieldProps } from "formik";
import React from "react";
import "./inputs.scss";

export const OmnijusTextAreaField = (props: {
    name: string;
    label?: string;
    disabled?: boolean;
    placeholder?: string;
    rows?: number;
}) => (
    <Field name={props.name} id={props.name} placeholder={props.placeholder}>
        {({ field, meta }: FieldProps) => (
            <div className="form-field-control">
                {props.label ? (
                    <label className="label" htmlFor={props.name}>
                        {props.label}
                    </label>
                ) : null}
                <textarea
                    id={props.name}
                    {...field}
                    disabled={props.disabled}
                    className={"input" + (meta.touched && meta.error ? " input-error" : "")}
                    placeholder={props.placeholder}
                    rows={props.rows}
                />
                <div className="error">{meta.touched && meta.error ? meta.error : ""}</div>
            </div>
        )}
    </Field>
);
