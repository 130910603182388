import { dateToDDMMYYYY } from "lib/time";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { DetalheResolucaoPendenciaAdmin } from "services/pendencia/models/detalhe-resolucao-pendencia-admin";
import { IdTipoPendencia, listarPendencias, ParametrosListarPendencias } from "services/pendencia/pendencia-service";
import { CardFiltros } from "shared/form/card-filtros/card-filtros";
import { OmnijusCampoNumeroProcesso } from "shared/form/custom-fields/omnijus-campo-numero-processo";
import { OmnijusCampoStatusPendencia } from "shared/form/custom-fields/omnijus-campo-status-pendencia";
import { OmnijusFiltroCliente } from "shared/form/custom-fields/omnijus-filtro-cliente";
import { OmnijusRangeDateField } from "shared/form/fields/omnijus-range-date-field";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import { date as YupDate, object as YupObject } from "yup";
import styles from "./listar-pendencias.module.scss";
import { ModalDetalhePendencia } from "./modal-detalhe-pendencia";

export const ListarPendencias = () => {
    const [promisePendencias, setPromisePendencias] = useState<Promise<DetalheResolucaoPendenciaAdmin[] | undefined>>();

    const [isModalOpen, setModalOpen] = useState(false);
    const [pendenciaSelecionada, setPendenciaSelecionada] = useState<DetalheResolucaoPendenciaAdmin>();

    useEffect(() => {
        setPromisePendencias(listarPendencias({ IdTipoPendencia: IdTipoPendencia.PagamentoGuia }));
    }, []);

    return (
        <div className={styles.listarPendencias}>
            <h2>Pendências</h2>
            <CardFiltros
                onBuscar={(values) => {
                    const parametros: ParametrosListarPendencias = {
                        ...values,
                        IdStatusResolucaoPendencia: parseInt(values.IdStatusResolucaoPendencia) || undefined,
                    };
                    setPromisePendencias(listarPendencias(parametros));
                }}
                initialValues={{
                    IdTipoPendencia: 2,
                    IdCliente: "",
                    NumeroProcessoPendencias: "",
                    IdStatusResolucaoPendencia: "",
                    DataHoraLimiteResolucaoInicio: undefined as Date | undefined,
                    DataHoraLimiteResolucaoFim: undefined as Date | undefined,
                    DataHoraSolicitacaoInicio: undefined as Date | undefined,
                    DataHoraSolicitacaoFim: undefined as Date | undefined,
                    DataHoraResolucaoInicio: undefined as Date | undefined,
                    DataHoraResolucaoFim: undefined as Date | undefined,
                }}
                botaoFiltrarInline
                validationSchema={YupObject().shape({
                    DataHoraLimiteResolucaoInicio: YupDate().typeError("Data inválida").nullable(),
                    DataHoraLimiteResolucaoFim: YupDate()
                        .typeError("Data inválida")
                        .nullable()
                        .when(["DataHoraLimiteResolucaoInicio"], (di: Date, schema: any) => {
                            return di ? schema.min(di, "A data de final não pode ser anterior à data inicial") : schema;
                        }),
                    DataHoraSolicitacaoInicio: YupDate()
                        .typeError("Data inválida")
                        .nullable()
                        .max(new Date(), "A data não pode ser futura"),
                    DataHoraSolicitacaoFim: YupDate()
                        .typeError("Data inválida")
                        .nullable()
                        .max(new Date(), "A data não pode ser futura")
                        .when(["DataHoraSolicitacaoInicio"], (di: Date, schema: any) => {
                            return di ? schema.min(di, "A data de final não pode ser anterior à data inicial") : schema;
                        }),
                    DataHoraResolucaoInicio: YupDate()
                        .typeError("Data inválida")
                        .nullable()
                        .max(new Date(), "A data não pode ser futura"),
                    DataHoraResolucaoFim: YupDate()
                        .typeError("Data inválida")
                        .nullable()
                        .max(new Date(), "A data não pode ser futura")
                        .when(["DataHoraResolucaoInicio"], (di: Date, schema: any) => {
                            return di ? schema.min(di, "A data de final não pode ser anterior à data inicial") : schema;
                        }),
                })}
            >
                <OmnijusSelectField
                    options={[{ label: "Pagamento de Guias", value: String(IdTipoPendencia.PagamentoGuia) }]}
                    name="IdTipoPendencia"
                    label="Pendência"
                />
                <OmnijusFiltroCliente name="IdCliente" label="Cliente" />
                <OmnijusCampoNumeroProcesso name="NumeroProcessoPendencias" label="Nº do Processo" />

                <OmnijusCampoStatusPendencia />
                <OmnijusRangeDateField
                    nameDe="DataHoraLimiteResolucaoInicio"
                    nameAte="DataHoraLimiteResolucaoFim"
                    label="Prazo de Execução"
                />
                <OmnijusRangeDateField
                    nameDe="DataHoraSolicitacaoInicio"
                    nameAte="DataHoraSolicitacaoFim"
                    label="Data de Solicitação"
                />

                <OmnijusRangeDateField
                    nameDe="DataHoraResolucaoInicio"
                    nameAte="DataHoraResolucaoFim"
                    label="Data da Resolução"
                />
            </CardFiltros>

            <div className={styles.adicionar}>
                <Link to="/pendencias/gerar-pendencia-pagamento">Adicionar Pendência</Link>
            </div>

            <Loading promise={promisePendencias}>
                {(pendencias) => (
                    <OmnijusCard
                        body={
                            <DataTable
                                noHeader={true}
                                customStyles={{
                                    rows: {
                                        style: {
                                            cursor: "pointer",
                                        },
                                    },
                                }}
                                pagination={true}
                                paginationPerPage={20}
                                noDataComponent={<p>Nenhum registro encontrado!</p>}
                                data={pendencias || []}
                                // onRowClicked={(resolucaoPendencia) => {
                                //     if (resolucaoPendencia.idTipoPendencia === IdTipoPendencia.PagamentoGuia) {
                                //         history.push(`/pendencias/guia-pagamento/${resolucaoPendencia.id}`);
                                //     }
                                // }}
                                onRowClicked={(pendencia) => {
                                    setPendenciaSelecionada(pendencia);
                                    setModalOpen(true);
                                }}
                                columns={[
                                    {
                                        name: "Processo",
                                        selector: (row) =>
                                            row.numerosProcessos && row.numerosProcessos.length > 0
                                                ? row.numerosProcessos.length > 1
                                                    ? `${row.numerosProcessos.length} processos`
                                                    : row.numerosProcessos[0]
                                                : "",
                                        width: "15rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Cliente",
                                        selector: (row) => row.nomesClientes?.join(", "),
                                        sortable: true,
                                        wrap: true,
                                        minWidth: "20rem",
                                    },
                                    {
                                        name: "Pendência",
                                        selector: (row) => row.tipoPendencia,
                                        width: "12rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Data de Solicitação",
                                        selector: (row) => dateToDDMMYYYY(row.dataHoraSolicitacao),
                                        minWidth: "10rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Prazo de Execução",
                                        selector: (row) => dateToDDMMYYYY(row.dataHoraLimiteResolucao),
                                        minWidth: "10rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Status",
                                        selector: (row) => row.statusResolucaoPendencia,
                                        maxWidth: "10rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Data da Resolução",
                                        selector: (row) =>
                                            (row.dataHoraResolucao &&
                                                dateToDDMMYYYY(new Date(row.dataHoraResolucao))) ||
                                            "-",
                                        minWidth: "10rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Autor",
                                        selector: (row) => row.autores?.flatMap((a) => a.nome).join(", "),
                                        sortable: true,
                                        wrap: true,
                                        minWidth: "20rem",
                                    },
                                ]}
                            />
                        }
                    />
                )}
            </Loading>

            {pendenciaSelecionada && (
                <ModalDetalhePendencia
                    {...pendenciaSelecionada}
                    isModalOpen={isModalOpen}
                    onCloseModal={() => setModalOpen(false)}
                />
            )}
        </div>
    );
};
