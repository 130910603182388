import React, { useEffect, useState } from "react";
import { PrestadorFiltroService } from "services/filtros/filtros-prestador-service";
import { Escritorio } from "services/filtros/models/escritorio";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";
import { Loading } from "shared/loading/loading";

export const OmnijusCampoEscritorio = (props: { hideLabel?: boolean; name?: string; label?: string }) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<Escritorio[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoes(PrestadorFiltroService.listarEscritorios());
    }, []);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    name={props.name || "qualificacaoPrestador"}
                    label={props.hideLabel ? undefined : props.label || "Órgão"}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id,
                            label: o.nomeFantasia || o.razaoSocial || o.cnpj || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
