import React, { useEffect, useState } from "react";
import { FiltroTipoEntrevistaGuiada } from "services/filtros/filtro-tipo-entrevista-guiada";
import { FiltroEntrevistaGuiadaService } from "services/filtros/filtros-entrevista-guiada";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";
import { Loading } from "shared/loading/loading";

interface OmnijusFiltroTipoTemplateProps {
  hideLabel?: boolean;
  name?: string;
  label?: string;
}

export const OmnijusFiltroTipoTemplate = (props: OmnijusFiltroTipoTemplateProps) => {
  const [promiseTipoTemplate, setPromiseTipoTemplate] = useState<Promise<Array<FiltroTipoEntrevistaGuiada> | undefined>>();

  useEffect(() => {
      setPromiseTipoTemplate(FiltroEntrevistaGuiadaService.listarTipoEntrevistaGuiada());
  }, []);

  return (
    <Loading promise={promiseTipoTemplate}>
      {opcoesTipoTemplate => (
        <OmnijusSelectField
          name={props.name || "IdTipoTemplate"}
          label={props.hideLabel ? undefined : props.label || "Tipo template"}
          options={opcoesTipoTemplate
            ?.map((o) => ({
                value: o.id.toString(),
                label: o.value.toString(),
            }))
            .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
        />
      )}
    </Loading>
  );
};
