import { NomesTiposParametros, RequestApi } from "@omnijus/common";

export interface ParametrosPesquisarDLQ {
    id?: string;
    topico?: String;
    consumer?: String;
    key?: String;
    mensagemErro?: String;
    dataCriacaoStart?: Date;
    dataCriacaoEnd?: Date;
    dataUltimaTentativaStart?: Date;
    dataUltimaTentativaEnd?: Date;
    idStatusMensagem?: number;
}

const tiposParametros: NomesTiposParametros<Required<ParametrosPesquisarDLQ>> = {
    id: "string",
    topico: "string",
    consumer: "string",
    key: "string",
    mensagemErro: "string",
    dataCriacaoStart: "Date",
    dataCriacaoEnd: "Date",
    dataUltimaTentativaStart: "Date",
    dataUltimaTentativaEnd: "Date",
    idStatusMensagem: "number",
};

export const parseParametrosPesquisarDLQ = (queryString: string): ParametrosPesquisarDLQ =>
    RequestApi.parseQueryString(queryString, tiposParametros);
