import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "services/client-id";
import { FiltroCliente } from "./models/filtro-cliente";
import { FiltroContrato } from "./models/filtro-contrato";
import { FiltroEmpresa } from "./models/filtro-empresa";

const clienteUrl = process.env.REACT_APP_BFF_ADMINISTRATIVO_API_URL + "/api/Template";

const requestApi = new RequestApi(CLIENT_ID);

export const FiltrosClienteService = {
    listarClientes: () => {
        const url = `${clienteUrl}/v1/Cliente/ListarCliente`;
        return requestApi.get<FiltroCliente[]>(url);
    },
    listarContratos: (idCliente?: string) => {
        const url = new URL(`${clienteUrl}/Contrato/ObterContratoPorCliente`);
        idCliente && url.searchParams.set("idCliente", idCliente);
        return requestApi.get<FiltroContrato[]>(url.toString());
    },
    listarEmpresas: (idContrato?: string) => {
        const url = new URL(`${clienteUrl}/EmpresaCliente/ListarEmpresasContrato`);
        idContrato && url.searchParams.set("idContrato", idContrato);
        return requestApi.get<FiltroEmpresa[]>(url.toString());
    },
};
