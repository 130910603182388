import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { IdStatusCadastro } from "services/onboarding/models/id-status-cadastro";
import { ParametrosPesquisarCadastro } from "services/onboarding/models/parametros-pesquisa-cadastro";
import { OmnijusCampoCelular } from "shared/form/custom-fields/omnijus-campo-celular";
import { OmnijusCampoCPF } from "shared/form/custom-fields/omnijus-campo-cpf";
import { OmnijusCampoEtapaCadastro } from "shared/form/custom-fields/omnijus-campo-etapa-cadastro";
import { OmnijusCampoStatusCadastro } from "shared/form/custom-fields/omnijus-campo-status-cadastro";
import { OmnijusRangeDateField } from "shared/form/fields/omnijus-range-date-field";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";

export const FiltrosListagemCadastros = () => {
    const { values, setFieldValue } = useFormikContext<ParametrosPesquisarCadastro>();

    useEffect(() => {
        if (values.IdEtapa && String(values.IdStatus) !== String(IdStatusCadastro.EmCadastro)) {
            setFieldValue("IdEtapa", undefined);
        }
    }, [values.IdEtapa, values.IdStatus, setFieldValue]);

    return (
        <>
            <OmnijusTextField name="Nome" label="Nome" />
            <OmnijusCampoCPF name="Cpf" label="CPF" />
            <OmnijusTextField name="Email" label="Email" />
            <OmnijusCampoCelular name="Telefone" label="Telefone" />
            <OmnijusRangeDateField nameDe="DataInicio" nameAte="DataFim" label="Data de cadastro" />
            <OmnijusCampoStatusCadastro name="IdStatus" label="Status" />
            {String(values.IdStatus) === String(IdStatusCadastro.EmCadastro) && (
                <OmnijusCampoEtapaCadastro name="IdEtapa" label="Etapa" />
            )}
        </>
    );
};
