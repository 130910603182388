import { dateToDDMMYYYY, formatDateAndTime, getTimeHHmm } from "lib/time";
import React from "react";
import { HistoricoCadastro } from "services/onboarding/models/historico-cadastro";
import { CampoCadastro } from "../campo-cadastro/campo-cadastro";
import { CardCadastro } from "../card-cadastro/card-cadastro";
import styles from "./historico-de-cadastro.module.scss";

export const HistoricoDeCadastro = ({
    historicoAtual,
    historico,
    etapa,
}: {
    historicoAtual: HistoricoCadastro;
    historico: HistoricoCadastro[];
    etapa: string;
}) => {
    const historicoInicial = historico.sort((a, b) => a.data.getTime() - b.data.getTime())[0];
    return (
        <CardCadastro
            header="Histórico de cadastro"
            body={
                <div className={styles.historicoCadastro}>
                    <div>
                        <CampoCadastro
                            rotulo="Data de início do cadastro"
                            valor={formatDateAndTime(historicoInicial.data)}
                        />
                        <CampoCadastro
                            rotulo="Data da última alteração"
                            valor={historicoAtual ? formatDateAndTime(historicoAtual.data) : "-"}
                        />
                        <CampoCadastro rotulo="Etapa atual" valor={etapa} />
                    </div>
                    <div className={styles.tabelaHistorico}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Status</th>
                                    <th>Usuário</th>
                                </tr>
                            </thead>
                            <tbody>
                                {historico
                                    .sort((a, b) => b.data.getTime() - a.data.getTime())
                                    .map((h) => (
                                        <tr key={h.id}>
                                            <td>
                                                <span>{dateToDDMMYYYY(h.data)}</span>
                                                <span className={styles.hora}>{getTimeHHmm(h.data)}</span>
                                            </td>
                                            <td>{h.statusCadastro.descricao}</td>
                                            <td>{h.nomeUsuario || "-"}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        />
    );
};
