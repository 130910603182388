import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "services/client-id";
import { DetalheEntrevistaGuiada } from "./detalhe-entrevista-guiada";
import { EntrevistaGuiada } from "./entrevista-guiada";
import {
    ParametroAtualizarEntrevistaGuiada,
    ParametroCriarEntrevistaGuiada,
} from "./parametro-criar-editar-entrevista-guiada";
import { ParametroListarEntrevistaGuiada } from "./parametro-listar-entrevista-guiada";

const templateUrl = process.env.REACT_APP_BFF_ADMINISTRATIVO_API_URL! + "/Template";

const requestApi = new RequestApi(CLIENT_ID);

export const EntrevistaGuiadaService = {
    obterEntrevistaGuiada: async (idEntrevistaGuiada: number) => {
        if (idEntrevistaGuiada) {
            const url = `${templateUrl}/${idEntrevistaGuiada}`;
            return await requestApi.get<DetalheEntrevistaGuiada>(url);
        } else {
            return undefined;
        }
    },
    listarEntrevistaGuiada: async (filtros?: ParametroListarEntrevistaGuiada) => {
        const url = `${templateUrl}/v1/EntrevistaGuiada`;
        let queryString = "";

        if (filtros) {
            queryString = `?${RequestApi.objectToQueryString(filtros)}`;
        }

        return await requestApi.get<EntrevistaGuiada[]>(`${url}${queryString}`);
    },
    criarEntrevistaGuiada: async (filtros: ParametroCriarEntrevistaGuiada) => {
        const url = `${templateUrl}/CriarEntrevistaGuiada`;
        return await requestApi.post(url, filtros);
    },
    atualizarEntrevistaGuiada: async (filtros?: ParametroAtualizarEntrevistaGuiada) => {
        const url = `${templateUrl}/AtualizarEntrevistaGuiada`;
        return await requestApi.put(url, filtros);
    },
};
