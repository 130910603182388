import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FiltrosClienteService } from "services/filtros/filtros-cliente-service";
import { FiltroContrato } from "services/filtros/models/filtro-contrato";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";
import { Loading } from "shared/loading/loading";

const filtroClienteName = "IdCliente";

interface OmnijusFiltroContratoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    reloadOnChangeName?: string;
    isDisabled?: boolean;
}

export const OmnijusFiltroContrato = (props: OmnijusFiltroContratoProps) => {
    const formikContext = useFormikContext<any>();
    const { setFieldValue } = formikContext;
    const idCliente = formikContext.values[props.reloadOnChangeName || filtroClienteName];
    const [promiseOpcoesContrato, setPromiseOpcoesContrato] = useState<Promise<FiltroContrato[] | undefined>>();
    const name = props.name || "IdContrato";
    const isFirstRun = useRef(true);

    useEffect(() => {
        isFirstRun.current || setFieldValue(name, "");
        setPromiseOpcoesContrato(FiltrosClienteService.listarContratos(idCliente));
        isFirstRun.current = false;
    }, [idCliente, name, setFieldValue]);

    return (
        <Loading promise={promiseOpcoesContrato}>
            {(opcoesEmpresas) => (
                <OmnijusSelectField
                    isDisabled={props.isDisabled}
                    name={name}
                    label={props.hideLabel ? undefined : props.label || "Contrato"}
                    options={opcoesEmpresas
                        ?.map((o) => ({
                            value: o.id,
                            label: o.numero || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
