import React from "react";

export interface InfoParametro {
    label?: string;
    idValue?: Record<string | number, string>;
    format?: (value: any) => string;
}
export type ValoresCombos = Record<string, InfoParametro>;

export interface ValoresComboContextParams {
    valoresCombos: ValoresCombos;
    setValoresCombos: (v: ValoresCombos) => void;
}

export const ValoresComboContext = React.createContext<ValoresComboContextParams>({
    valoresCombos: {},
    setValoresCombos: () => {},
});
