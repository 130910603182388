import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "services/client-id";
import { FiltroAlegacao } from "./filtro-alegacao";
import { FiltroArea } from "./filtro-area";
import { FiltroClasse } from "./filtro-classe";
import { FiltroRito } from "./filtro-rito";

const processoFiltroUrl = process.env.REACT_APP_PROCESSO_API_URL + "/Processo";

const requestApi = new RequestApi(CLIENT_ID);

export const ProcessoFiltroService = {
    listarArea: async () => {
        let url = processoFiltroUrl + "/ListarArea";
        return await requestApi.get<FiltroArea[]>(url);
    },
    listarRito: async (idArea?: number) => {
        let url = processoFiltroUrl + "/ListarRitoPorIdArea" + (idArea ? `?idArea=${idArea}` : "");
        return await requestApi.get<FiltroRito[]>(url);
    },
    listarClasse: async (idRito?: number) => {
        let url =
            processoFiltroUrl + "/ListarClasseProcessoPorIdRitoProcesso" + (idRito ? `?idRitoProcesso=${idRito}` : "");
        return await requestApi.get<FiltroClasse[]>(url);
    },
    listarAlegacao: async (idRito?: number) => {
        let url =
            processoFiltroUrl + "/ListarAlegacaoMeritoPorIdRitoProcesso" + (idRito ? `?idRitoProcesso=${idRito}` : "");
        return await requestApi.get<FiltroAlegacao[]>(url);
    },
};
