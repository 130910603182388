import React, { useEffect, useState } from "react";
import { FiltroTemplateEntrevistaGuiadaParceiro } from "services/filtros/filtro-template-entrevista-guiada-parceiro";
import { FiltroEntrevistaGuiadaService } from "services/filtros/filtros-entrevista-guiada";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";
import { Loading } from "shared/loading/loading";

interface OmnijusFiltroTemplateParceiroProps {
  hideLabel?: boolean;
  name?: string;
  label?: string;
}

export const OmnijusFiltroTemplateParceiro = (props: OmnijusFiltroTemplateParceiroProps) => {
  const [promiseTemplateParceiro, setPromiseTemplateParceiro] = useState<
    Promise<FiltroTemplateEntrevistaGuiadaParceiro[] | undefined>
  >();

  useEffect(() => {
      setPromiseTemplateParceiro(FiltroEntrevistaGuiadaService.listarTemplateEntrevistaGuiadaParceiro());
  }, []);

  return (
    <Loading promise={promiseTemplateParceiro}>
      {(opcoesTemplateParceiro) => (
        <OmnijusSelectField
          name={props.name || "IdTemplateParceiro"}
          label={props.hideLabel ? undefined : props.label || "Template parceiro"}
          options={opcoesTemplateParceiro
            ?.map((o) => ({
                value: o.id.toString(),
                label: o.value.toString(),
            }))
            .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
        />
      )}
    </Loading>
  );
};
