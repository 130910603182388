import React, { useEffect, useState } from "react";
import { Loading } from "shared/loading/loading";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";
import { FiltroArea } from "services/filtros/filtro-area";
import { ProcessoFiltroService } from "services/filtros/filtros-processos-service";

interface OmnijusFiltroAreaProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
}

export const OmnijusFiltroArea = (props: OmnijusFiltroAreaProps) => {
    const [promiseOpcoesArea, setPromiseOpcoesArea] = useState<Promise<FiltroArea[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoesArea(ProcessoFiltroService.listarArea());
    }, []);

    return (
        <Loading promise={promiseOpcoesArea}>
            {(opcoesArea) => (
                <OmnijusSelectField
                    name={props.name || "IdAreaAtuacaoOrgao"}
                    label={props.hideLabel ? undefined : props.label || "Área"}
                    options={opcoesArea
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
