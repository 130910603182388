import { format } from "date-fns";
import { RequestApi } from "@omnijus/common";
import { DetalheResolucaoPendenciaAdmin } from "./models/detalhe-resolucao-pendencia-admin";
import { FiltroStatusResolucaoPendencia } from "./models/filtro-status-resolucao-pendencia";
import { CLIENT_ID } from "services/client-id";

const pendenciaUrl = process.env.REACT_APP_PROCESSO_API_URL + "/ProcessoResolucaoPendencia";
const pagamentoGuiaUrl = process.env.REACT_APP_PROCESSO_API_URL + "/ProcessoPagamentoGuia";

const requestApi = new RequestApi(CLIENT_ID);

export enum IdTipoPendencia {
    Subsidio = 1,
    PagamentoGuia = 2,
    AlcadaAcordo = 3,
}

export interface ParametrosListarPendencias {
    IdCliente?: string;
    NumeroProcessoPendencias?: string;
    IdTipoPendencia?: IdTipoPendencia;
    IdStatusResolucaoPendencia?: number;
    DataHoraLimiteResolucaoInicio?: Date;
    DataHoraLimiteResolucaoFim?: Date;
    DataHoraSolicitacaoInicio?: Date;
    DataHoraSolicitacaoFim?: Date;
}

export const listarPendencias = async (
    parametros?: ParametrosListarPendencias
): Promise<DetalheResolucaoPendenciaAdmin[] | undefined> => {
    const url = new URL(`${pendenciaUrl}/Admin/ListarResolucaoPendencia`);
    if (parametros) {
        url.search = RequestApi.objectToQueryString(parametros);
    }
    const res = await requestApi.get<DetalheResolucaoPendenciaAdmin[]>(url.toString());
    return (
        res &&
        res.map((pendencia) => ({
            ...pendencia,
            dataHoraLimiteResolucao: pendencia.dataHoraLimiteResolucao && new Date(pendencia.dataHoraLimiteResolucao),
            dataHoraSolicitacao: pendencia.dataHoraSolicitacao && new Date(pendencia.dataHoraSolicitacao),
        }))
    );
};

export const listarStatusResolucaoPendencia = async () => {
    const url = pendenciaUrl + "/ListarStatusResolucaoPendencia";
    return await requestApi.get<FiltroStatusResolucaoPendencia[]>(url);
};

export const gerarPendenciaGuiaPagamento = (
    NumeroProcesso: string,
    PrazoExecucao: Date,
    Descricao: string,
    DocumentoGuia: FileList
) => {
    const url = pagamentoGuiaUrl;

    return requestApi.postFormdata(
        url,
        {
            NumeroProcesso,
            PrazoExecucao: format(PrazoExecucao, "yyyy-MM-dd"),
            Descricao,
            DocumentoGuia,
        },
        (res, json) => ({ res: res, json: json })
    );
};
