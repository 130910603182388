import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as EditIcon } from "assets/images/edit-pen.svg";
import { ReactComponent as DeleteIcon } from "assets/images/trash-icon.svg";
import { FieldArray, Form, useField } from "formik";
import { dateToDDMMYYYY } from "lib/time";
import React from "react";
import { downloadCertificado } from "services/cadastro-prestadores/cadastro-prestadores-service";
import { SituacaoCurso } from "services/filtros/models/situacao-curso";
import { TipoCurso } from "services/filtros/models/tipo-curso";
import { TipoPeriodoFormacao } from "services/filtros/models/tipo-periodo-formacao";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { OmnijusCampoSituacaoCurso } from "shared/form/custom-fields/omnijus-campo-situacao-curso";
import { OmnijusCampoTipoCurso } from "shared/form/custom-fields/omnijus-campo-tipo-curso";
import { OmnijusCampoTipoPeriodoFormacao } from "shared/form/custom-fields/omnijus-campo-tipo-periodo-formacao";
import { OmnijusDateField } from "shared/form/fields/omnijus-date-field";
import { DataUrlFile, OmnijusFileField } from "shared/form/fields/omnijus-file-field";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import { showModalForm } from "shared/form/modal-form/modal-form";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import { date as YupDate, number as YupNumber, object as YupObject, string as YupString } from "yup";
import styles from "../cadastro-prestadores.module.scss";

export interface FormacaoAcademica {
    id?: string;
    tipo: TipoCurso;
    nomeInstituicao: string;
    nomeCurso: string;
    situacao: SituacaoCurso;
    tipoDePeriodo?: TipoPeriodoFormacao;
    periodosCursados?: number;
    dataInicio?: Date;
    dataTermino?: Date;
    certificado?: DataUrlFile & { id?: string };
}

export const formacaoAcademicaValidation = YupObject().shape({
    tipo: YupObject().shape({ id: YupNumber().min(0, "Informe o tipo da formação") }),
    nomeInstituicao: YupString().required("Informe o nome da instituição"),
    nomeCurso: YupString().required("Informe o nome do curso"),
    situacao: YupObject().shape({ id: YupNumber().min(0, "Informe a situação do curso") }),
    tipoDePeriodo: YupObject().shape({ id: YupNumber().nullable() }),
    periodosCursados: YupNumber()
        .nullable()
        .typeError("Informe um número inteiro")
        .integer("Informe um número inteiro")
        .min(0, "Informe um número positivo"),
    dataInicio: YupDate()
        .typeError("Data inválida")
        .required("Informe a data de início")
        .max(new Date(), "A data não pode ser futura"),
    dataTermino: YupDate()
        .nullable()
        .typeError("Data inválida")
        .when(["situacao"], (value: SituacaoCurso | undefined, schema: any) => {
            if (value?.id === 1)
            {
                return schema;
            }

            return schema.max(new Date(), "A data não pode ser futura");
        })
        .when(["dataInicio"], (di: Date, schema: any) => {
            return di ? schema.min(di, "A data de término deve ser posterior à data de início") : schema;
        }),
    certificado: YupString().typeError("O certificado é obrigatório"),
});

export const modalNovaFormacaoAcademica = (nomeAcao: string, valorInicial: FormacaoAcademica) =>
    showModalForm(
        nomeAcao,
        valorInicial,
        <Form className={`${styles.modalForm}`}>
            <OmnijusCampoTipoCurso name="tipo" label="Tipo" />
            <OmnijusTextField name="nomeInstituicao" label="Instituição" />
            <OmnijusTextField name="nomeCurso" label="Curso" />
            <OmnijusCampoSituacaoCurso name="situacao" label="Situação" />
            <OmnijusCampoTipoPeriodoFormacao name="tipoDePeriodo" label="Tipo de Período" />
            <OmnijusTextField name="periodosCursados" label="Períodos cursados" />
            <OmnijusDateField name="dataInicio" label="Início" />
            <OmnijusDateField name="dataTermino" label="Término" />
            <OmnijusFileField
                name="certificado"
                label="Certificado"
                accept="application/pdf, image/png, image/jpeg"
                maxSizeMB={5}
            />

            <div className={styles.linhaSubmit}>
                <ButtonPrimary type="submit">{nomeAcao}</ButtonPrimary>
            </div>
        </Form>,
        "50rem",
        formacaoAcademicaValidation
    );

export const FormacoesAcademicas = ({ values }: { values: { formacoesAcademicas: FormacaoAcademica[] } }) => {
    const name = "formacoesAcademicas";
    const [, meta] = useField({ name });
    return (
        <OmnijusCard
            header={<h3>Formação Acadêmica</h3>}
            body={
                <FieldArray name={name}>
                    {(arrayHelpers) => (
                        <>
                            <table className={styles.table}>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col className={styles.colTipoPeriodo} />
                                    <col className={styles.colPeriodosCursados} />
                                    <col className={styles.colData} />
                                    <col className={styles.colData} />
                                    <col />
                                    <col className={styles.colIcones} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <td>Tipo</td>
                                        <td>Instituição</td>
                                        <td>Curso</td>
                                        <td>Situação</td>
                                        <td>Tipo de período</td>
                                        <td>Períodos cursados</td>
                                        <td>Início</td>
                                        <td>Término</td>
                                        <td>Certificado</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {values.formacoesAcademicas.map((formacao, idx) => (
                                        <tr key={idx}>
                                            <td>{formacao.tipo.descricao}</td>
                                            <td>{formacao.nomeInstituicao}</td>
                                            <td>{formacao.nomeCurso}</td>
                                            <td>{formacao.situacao.descricao}</td>
                                            <td>{formacao.tipoDePeriodo?.descricao}</td>
                                            <td>{formacao.periodosCursados}</td>
                                            <td>{formacao.dataInicio && dateToDDMMYYYY(formacao.dataInicio)}</td>
                                            <td>{formacao.dataTermino && dateToDDMMYYYY(formacao.dataTermino)}</td>
                                            <td>
                                                {formacao.certificado?.id ? (
                                                    <button
                                                        className={styles.botaoDownload}
                                                        type="button"
                                                        onClick={() => {
                                                            if (formacao.certificado?.id) {
                                                                downloadCertificado(
                                                                    formacao.certificado.id,
                                                                    "Certificado.pdf"
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Certificado.pdf
                                                    </button>
                                                ) : (
                                                    formacao.certificado?.name
                                                )}
                                            </td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className={styles.botaoIcone}
                                                    onClick={async () => {
                                                        const novaFormacaoAcademica = await modalNovaFormacaoAcademica(
                                                            "Editar Formação Acadêmica",
                                                            formacao
                                                        );
                                                        novaFormacaoAcademica &&
                                                            arrayHelpers.replace(idx, novaFormacaoAcademica);
                                                    }}
                                                >
                                                    <EditIcon title="Ícone de editar Formação Acadêmica" />
                                                </button>
                                                <button
                                                    type="button"
                                                    className={styles.botaoIcone}
                                                    onClick={() => {
                                                        arrayHelpers.remove(idx);
                                                    }}
                                                >
                                                    <DeleteIcon title="Ícone de excluir Formação Acadêmica" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <button
                                type="button"
                                className={styles.botaoIcone}
                                onClick={async () => {
                                    const novaFormacaoAcademica = await modalNovaFormacaoAcademica(
                                        "Adicionar Formação Acadêmica",
                                        {
                                            tipo: { id: -1, descricao: null },
                                            nomeInstituicao: "",
                                            nomeCurso: "",
                                            situacao: { id: -1, descricao: null },
                                            tipoDePeriodo: { id: -1, descricao: null },
                                            dataInicio: undefined,
                                            periodosCursados: undefined,
                                            dataTermino: undefined,
                                            certificado: undefined,
                                        }
                                    );
                                    novaFormacaoAcademica && arrayHelpers.push(novaFormacaoAcademica);
                                }}
                            >
                                <AddIcon title="Ícone de adicionar novo meio de comunicação" />
                            </button>
                            {meta.touched && meta.error && (
                                <div className={styles.error}>
                                    {Array.isArray(meta.error)
                                        ? meta.error.map((er) => Object.values(er).join(","))
                                        : typeof meta.error === "object"
                                        ? Object.values(meta.error).join(",")
                                        : meta.error}
                                </div>
                            )}
                        </>
                    )}
                </FieldArray>
            }
        />
    );
};
