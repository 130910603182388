import { RequestApi } from "@omnijus/common";
import { Orgao } from "./models/orgao";
import { FiltroQualificacao } from "./models/filtro-qualificacao";
import { TipoEndereco } from "./models/tipo-endereco";
import { Escritorio } from "./models/escritorio";
import { TipoContato } from "./models/tipo-contato";
import { TipoCurso } from "./models/tipo-curso";
import { SituacaoCurso } from "./models/situacao-curso";
import { TipoPeriodoFormacao } from "./models/tipo-periodo-formacao";
import { Banco } from "./models/banco";
import { TipoContaBancaria } from "./models/tipo-conta-bancaria";
import { TipoMeioComunicacao } from "./models/tipo-meio-comunicacao";
import { CLIENT_ID } from "services/client-id";

const prestadorFiltroUrl = process.env.REACT_APP_PRESTADOR_API_URL + "/BFF";
const requestApi = new RequestApi(CLIENT_ID);

export const PrestadorFiltroService = {
    listarQualificacao: () => {
        const url = prestadorFiltroUrl + "/PrestadorServicoUsuario/ListarQualificacao";
        return requestApi.get<FiltroQualificacao[]>(url);
    },
    listarEscritorios: () => {
        const url = `${prestadorFiltroUrl}/PrestadorServico/escritorios`;
        return requestApi.get<Escritorio[]>(url);
    },
    listarEntidadesUsuario: () => {
        const url = `${prestadorFiltroUrl}/PrestadorServicoUsuario/entidades`;
        return requestApi.get<Orgao[]>(url);
    },
    listarTiposEndereco: () => {
        const url = `${prestadorFiltroUrl}/PrestadorServico/tipos-enderecos`;
        return requestApi.get<TipoEndereco[]>(url);
    },
    listarTiposContato: () => {
        const url = `${prestadorFiltroUrl}/PrestadorServico/tipos-contatos`;
        return requestApi.get<TipoContato[]>(url);
    },
    listarTiposMeioComunicacao: () => {
        const url = `${prestadorFiltroUrl}/PrestadorServico/tipos-meio-comunicacao`;
        return requestApi.get<TipoMeioComunicacao[]>(url);
    },
    listarTiposCurso: () => {
        const url = `${prestadorFiltroUrl}/graduacoes`;
        return requestApi.get<TipoCurso[]>(url);
    },
    listarSituacoesCurso: () => {
        const url = `${prestadorFiltroUrl}/graduacoes/situacoes`;
        return requestApi.get<SituacaoCurso[]>(url);
    },
    listarTiposPeriodoFormacao: () => {
        const url = `${prestadorFiltroUrl}/graduacoes/tipos-periodos`;
        return requestApi.get<TipoPeriodoFormacao[]>(url);
    },
    listarBancos: () => {
        const url = `${prestadorFiltroUrl}/dados-bancarios`;
        return requestApi.get<Banco[]>(url);
    },
    listarTiposContaBancaria: () => {
        const url = `${prestadorFiltroUrl}/dados-bancarios/tipos-contas`;
        return requestApi.get<TipoContaBancaria[]>(url);
    },
};
