import React, { useEffect, useState } from "react";
import LoadingIcon from "assets/images/loading.svg";
import styles from "./loading.module.scss";

interface LoadingProps<T> {
    promise?: Promise<T>;
    children: (v?: T) => React.ReactNode;
    explicitReload?: boolean;
    inline?: boolean;
}

export function Loading<T>(props: LoadingProps<T>) {
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [value, setValue] = useState<T>();

    const className = `${styles.loading} ${props.inline ? styles.loadingInline : ""}`;

    useEffect(() => {
        let mounted = true;
        if (props.promise !== undefined) {
            if (props.explicitReload || props.explicitReload === undefined) {
                setIsLoading(true);
            }

            setErrorMessage(null);

            props.promise
                .then((v) => mounted && setValue(v))
                .catch((err) => mounted && setErrorMessage(err.toString()))
                .finally(() => mounted && setIsLoading(false));
        }
        return () => {
            mounted = false;
        };
    }, [props.promise, props.explicitReload]);

    if (errorMessage) {
        return <div className={`${className} ${styles.errorMessage}`}>{errorMessage}</div>;
    }

    return isLoading ? (
        <div className={className}>
            <img alt="Ícone de carregando" src={LoadingIcon} />
        </div>
    ) : (
        <>{props.children(value)}</>
    );
}
