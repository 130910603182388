import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "services/client-id";
import { AlteracaoPorAnexo } from "./models/alteracao-anexo";
import { AlteracaoPorPropriedade } from "./models/alteracao-propriedade";
import { IdTipoAnexo } from "./models/id-tipo-anexo";

const onboardingUrl = process.env.REACT_APP_BFF_ADMINISTRATIVO_API_URL + "/api/Onboarding/";

const requestApi = new RequestApi(CLIENT_ID);

export const getListarHistoricoAlteracoes = async (
    idCadastroPrestador: string,
    propriedade: string,
    idEntidade?: string
): Promise<AlteracaoPorPropriedade[] | undefined> => {
    const url = new URL(`${onboardingUrl}v1/Historico/${idCadastroPrestador}/ListarHistoricoAlteracoes`);
    url.searchParams.set("propriedade", propriedade);
    idEntidade && url.searchParams.set("IdEntidade", idEntidade);
    const res = await requestApi.get<AlteracaoPorPropriedade[]>(url.toString());
    return res?.map((alteracao) => ({
        ...alteracao,
        dataAlteracao: alteracao.dataAlteracao && new Date(alteracao.dataAlteracao),
    }));
};

export const getListarHistoricoAlteracoesPorAnexo = async (
    idCadastroPrestador: string,
    idTipoAnexo: IdTipoAnexo,
    idFormacaoAcademica?: string
): Promise<AlteracaoPorAnexo[] | undefined> => {
    const url = new URL(`${onboardingUrl}v1/Historico/${idCadastroPrestador}/ListarAlteracoesPorAnexo`);
    url.searchParams.set("IdTipoAnexo", idTipoAnexo.toString());
    idFormacaoAcademica && url.searchParams.set("IdFormacaoAcademica", idFormacaoAcademica);
    const res = await requestApi.get<AlteracaoPorAnexo[]>(url.toString());
    return res?.map((alteracao) => ({
        ...alteracao,
        dataAlteracao: alteracao.dataAlteracao && new Date(alteracao.dataAlteracao),
    }));
};
