import { FieldArray, useField, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { FiltroArea } from "services/filtros/filtro-area";
import { ProcessoFiltroService } from "services/filtros/filtros-processos-service";
import fieldStyles from "shared/form/field.module.scss";
import { Loading } from "shared/loading/loading";
import styles from "./omnijus-checkbox-areas.module.scss";

export const OmnijusCheckboxAreas = (props: { name?: string }) => {
    const name = props.name || "idsArea";
    const [promiseOpcoesArea, setPromiseOpcoesArea] = useState<Promise<FiltroArea[] | undefined>>();
    const value = useFormikContext<{ [key: string]: number[] }>().values[name];
    const [, meta] = useField({ name });
    useEffect(() => {
        setPromiseOpcoesArea(ProcessoFiltroService.listarArea());
    }, []);

    return (
        <Loading promise={promiseOpcoesArea}>
            {(opcoesArea) =>
                opcoesArea ? (
                    <div className={`${styles.checkboxAreas} ${fieldStyles["field-control"]}`}>
                        <div>Área:</div>
                        <div className={styles.opcoes}>
                            <FieldArray name={name}>
                                {(arrayHelpers) =>
                                    opcoesArea.map((area) => (
                                        <div key={area.id}>
                                            <label>
                                                <input
                                                    name={name}
                                                    type="checkbox"
                                                    value={area.id}
                                                    checked={value?.includes(area.id)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            arrayHelpers.push(area.id);
                                                        } else {
                                                            value && arrayHelpers.remove(value.indexOf(area.id));
                                                        }
                                                    }}
                                                />{" "}
                                                {area.value}
                                            </label>
                                        </div>
                                    ))
                                }
                            </FieldArray>
                        </div>

                        {meta.touched && meta.error ? <p className={fieldStyles["error"]}>{meta.error}</p> : null}
                    </div>
                ) : (
                    <></>
                )
            }
        </Loading>
    );
};
