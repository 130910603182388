import Cleave from "cleave.js/react";
import { Field, FieldProps } from "formik";
import styles from "../field.module.scss";
import React from "react";

export const OmnijusCampoCPF = (props: {
    name: string;
    placeholder?: string;
    label?: string;
    className?: string;
    disabled?: boolean;
    onChange?: (e: React.ChangeEvent<any>) => void;
}) => {
    return (
        <div className={`${styles["field-control"]} ${props.className || ""}`}>
            {props.label ? (
                <label className={styles["label"]} htmlFor={props.name}>
                    {props.label}
                </label>
            ) : null}
            <Field name={props.name} id={props.name}>
                {({ field, meta }: FieldProps) => (
                    <>
                        <Cleave
                            disabled={props.disabled}
                            options={{ blocks: [3, 3, 3, 2], delimiters: [".", ".", "-"], numericOnly: true }}
                            className={styles["input"]}
                            id={props.name}
                            {...field}
                            onChange={(e) => {
                                props.onChange && props.onChange(e);
                                field.onChange(e);
                            }}
                            value={field.value || ""}
                        />
                        <div className={styles["error"]}>{meta.touched && meta.error ? meta.error : ""}</div>
                    </>
                )}
            </Field>
        </div>
    );
};
