import { useAuth } from "@omnijus/common";
import DownIcon from "assets/images/down-icon.png";
import React from "react";
import { Link, NavLink } from "react-router-dom";
// import { IdStatusMensagem } from "services/dead-letter/models/dead-letter";
import { Dropdown } from "shared/dropdown/dropdown";
import { OmnijusLogo } from "shared/omnijus-logo/omnijus-logo";
import { NavLinkControleAcesso, WrapperAcessoFuncionalidadeAdmnistrativo } from "./acessos-helper";
import styles from "./navigation.module.scss";

export const Navigation = () => {
    const auth = useAuth();

    return (
        <nav className={styles.navBar}>
            <Link className={styles.navLinkDashboard} to="/">
                <OmnijusLogo />
            </Link>
            <div className={styles.navItems}>
                {/* <NavLinkControleAcesso tags={["atividade"]} to="/atividades">
                    Atividades
                </NavLinkControleAcesso> */}

                <NavLinkControleAcesso tags={["template"]} to="/templates">
                    Templates
                </NavLinkControleAcesso>
                <NavLinkControleAcesso tags={["configuracao_de_template"]} to="/configuracao-templates">
                    Configuração de Templates
                </NavLinkControleAcesso>

                {/* <NavLinkControleAcesso tags={["cadastro_de_prestadores"]} to="/cadastro-legal-ops">
                    Cadastro de Prestadores
                </NavLinkControleAcesso>
                <NavLinkControleAcesso tags={["pendencias"]} to="/pendencias">
                    Pendências
                </NavLinkControleAcesso> */}

                {/* <WrapperAcessoFuncionalidadeAdmnistrativo tags={["configuracoes"]}>
                    <Dropdown
                        items={
                            <ul className={styles.submenu}>
                                <li>
                                    <NavLinkControleAcesso
                                        tags={["configuracoes"]}
                                        className={styles.link}
                                        to="/configuracoes/parametros"
                                    >
                                        Parâmetros
                                    </NavLinkControleAcesso>
                                </li>
                            </ul>
                        }
                    >
                        <div className={styles.navLink}>Configurações</div>
                    </Dropdown>
                </WrapperAcessoFuncionalidadeAdmnistrativo> */}
                <WrapperAcessoFuncionalidadeAdmnistrativo tags={["onboarding"]}>
                    <Dropdown
                        items={
                            <ul className={styles.submenu}>
                                <li>
                                    <NavLink
                                        activeClassName={styles.active}
                                        className={styles.link}
                                        exact
                                        to="/onboarding"
                                    >
                                        Dashboard
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        activeClassName={styles.active}
                                        className={styles.link}
                                        exact
                                        to="/onboarding/cadastro"
                                    >
                                        Cadastros
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        activeClassName={styles.active}
                                        className={styles.link}
                                        exact
                                        to="/onboarding/convites"
                                    >
                                        Convites
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        activeClassName={styles.active}
                                        className={styles.link}
                                        exact
                                        to="/onboarding/leads"
                                    >
                                        Fila de leads
                                    </NavLink>
                                </li>
                            </ul>
                        }
                    >
                        <div className={styles.navLink}>Onboarding</div>
                    </Dropdown>
                </WrapperAcessoFuncionalidadeAdmnistrativo>

                {/* <OmnijusNavLink to="/relatorios/detalhamento-atividades-executores">Relatórios</OmnijusNavLink> */}
                <NavLinkControleAcesso tags={["dead_letters"]} to="/dead-letter">
                    Dead Letters
                </NavLinkControleAcesso>
            </div>
            <div className={styles.navRight}>
                {/* <div className={styles.item}>
                    <div className={styles.notification}>
                        <img alt="Ícone de mensagens" src={InboxIcon} />
                        <i className={styles.circle} />
                    </div>
                    <div className={styles.notification}>
                        <img alt="Ícone de notificações" src={BellIcon} />
                        <i className={styles.circle} />
                    </div>
                </div> */}
                <div className={styles.item}>
                    <Dropdown
                        options={[
                            {
                                label: "Alterar senha",
                                onClick: async () => {
                                    window.open(`${process.env.REACT_APP_AUTH_URL}/Manage/ChangePassword`);
                                },
                            },
                            {
                                label: "Sair",
                                onClick: () => {
                                    auth?.userManager.signoutRedirect();
                                },
                            },
                        ]}
                    >
                        <div className={styles.perfil}>
                            <div className={styles.user}>{String(auth?.user?.profile.name).charAt(0)}</div>
                            <img alt="Ícone de menu do perfil" src={DownIcon} />
                        </div>
                    </Dropdown>
                </div>
            </div>
        </nav>
    );
};
