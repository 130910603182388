import { ReactComponent as ErrorIconFilled } from "assets/images/error-icon-filled.svg";
import { ReactComponent as ErrorIconOutline } from "assets/images/error-icon-outline.svg";
import { ReactComponent as HistoryIcon } from "assets/images/history-icon.svg";
import { ReactComponent as ImageErrorIcon } from "assets/images/image-error-icon.svg";
import { ReactComponent as DocumentIcon } from "assets/images/document-icon.svg";
import classNames from "classnames";
import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Anexo } from "services/onboarding/models/anexo";
import { CriarInconsistenciaProps, exibirModalCriarInconsistencia } from "../criar-inconsistencia/criar-inconsistencia";
import { useDetalhesCadastroInfoContext } from "../detalhes-cadasatro-info-context";
import { useEtapaCadastroContext } from "../etapa-cadastro-context";
import {
    exibirModalHistoricoCampo,
    HistoricoCampoCadastroProps,
} from "../historico-campo-cadastro/historico-campo-cadastro";
import styles from "./campo-cadastro.module.scss";

// export enum TipoCampo {
//     Texto,
//     Imagem,
//     Anexo,
//     Data,
// }

export interface CampoCadastroProps {
    nome?: string;
    rotulo: string;
    valor?: string;
    idFormacaoAcademica?: string;
    idExperienciaProfissional?: string;
    link?: boolean;
    format?: (valor?: string) => React.ReactNode;
    className?: string;
}

const BotaoHistorico = (props: HistoricoCampoCadastroProps) => {
    return (
        <button className={styles.botaoAcao} onClick={() => exibirModalHistoricoCampo(props)} title="Exibir histórico">
            <HistoryIcon />
        </button>
    );
};

const BotaoInconsistencia = (props: CriarInconsistenciaProps) => {
    const { inconsistencias, dataUltimoEmAnalise } = useDetalhesCadastroInfoContext();
    const inconsistenciaPendente = inconsistencias?.find(
        (inc) =>
            inc.pendente &&
            inc.idEtapaCadastro === props.etapa.id &&
            inc.campos === props.nome &&
            (inc.idTipoAnexo || undefined) === props.anexo?.idTipoAnexo &&
            (inc.idFormacaoAcademica || undefined) === props.idFormacaoAcademica &&
            (inc.idExperienciaProfissional || undefined) === props.idExperienciaProfissional
    );
    return (
        <button
            className={classNames(styles.botaoAcao, styles.botaoInconsistencia)}
            onClick={() => exibirModalCriarInconsistencia({ ...props, inconsistencia: inconsistenciaPendente })}
            title="Inconsistência"
        >
            {inconsistenciaPendente ? (
                <ErrorIconFilled className={styles.iconeVermelho} />
            ) : inconsistencias?.some(
                  (inc) =>
                      dataUltimoEmAnalise &&
                      inc.dataCriacao.getTime() >= dataUltimoEmAnalise.getTime() &&
                      inc.idEtapaCadastro === props.etapa.id &&
                      inc.campos === props.nome &&
                      (inc.idTipoAnexo || undefined) === props.anexo?.idTipoAnexo &&
                      (inc.idFormacaoAcademica || undefined) === props.idFormacaoAcademica &&
                      (inc.idExperienciaProfissional || undefined) === props.idExperienciaProfissional
              ) ? (
                <ErrorIconFilled className={styles.iconeAmarelo} />
            ) : (
                <ErrorIconOutline />
            )}
        </button>
    );
};

type AcoesCampoProps = Omit<CampoCadastroProps, "valor"> & {
    anexo?: Anexo;
};

const AcoesCampo = (props: AcoesCampoProps) => {
    const { nome, anexo } = props;
    const { analiseInconsistencias, idCadastroPrestador, atualizaInconsistencias } = useDetalhesCadastroInfoContext();
    const etapa = useEtapaCadastroContext();
    return (nome || anexo) && idCadastroPrestador && etapa ? (
        <span className={styles.acoesCampo}>
            {analiseInconsistencias && (
                <BotaoInconsistencia
                    {...props}
                    idCadastroPrestador={idCadastroPrestador}
                    atualizaInconsistencias={atualizaInconsistencias}
                    etapa={etapa}
                />
            )}
            <BotaoHistorico
                {...props}
                idCadastroPrestador={idCadastroPrestador}
                etapa={etapa}
                idTipoAnexo={anexo?.idTipoAnexo}
            />
        </span>
    ) : (
        <></>
    );
};

export const CampoCadastro = (props: CampoCadastroProps) => {
    const { rotulo, valor, link, format } = props;
    const valorFormatado = format ? format(valor) : valor;
    return (
        <div className={classNames(styles.campo, styles.campoCadastro, props.className)}>
            <span className={styles.rotulo}>{rotulo}:</span>
            <div className={styles.valorAcoes}>
                <span className={styles.valor}>
                    {link
                        ? valor && (
                              <a href={valor} target="_blank" rel="noopener noreferrer">
                                  {valor}
                              </a>
                          )
                        : valorFormatado}
                </span>
                <AcoesCampo {...props} />
            </div>
        </div>
    );
};

export const CampoAnexo = (props: {
    nome?: string;
    rotulo: string;
    idFormacaoAcademica?: string;
    idExperienciaProfissional?: string;
    anexo?: Anexo;
}) => {
    const { rotulo, anexo } = props;
    return (
        <div className={classNames(styles.campo, styles.campoCadastro)}>
            <span className={styles.rotulo}>{rotulo}:</span>
            <div className={styles.valorAcoes}>
                <span className={styles.valor}>
                    <a href={anexo?.urlArquivo || undefined} target="_blank" rel="noopener noreferrer">
                        {anexo?.nomeArquivoOriginal}
                    </a>
                </span>
                <AcoesCampo {...props} />
            </div>
        </div>
    );
};

const imageExtensions = ["tif", "tiff", "bmp", "jpg", "jpeg", "gif", "png"];
const isImage = (filename: string) => imageExtensions.some((ext) => filename.endsWith("." + ext));

export interface CampoImagemProps {
    rotulo: string;
    idFormacaoAcademica?: string;
    idExperienciaProfissional?: string;
    nome?: string;
    anexo?: Anexo;
}
export const CampoImagem = (props: CampoImagemProps) => {
    const { rotulo, anexo } = props;
    return (
        <div className={styles.campo}>
            <div className={styles.rotulo}>{rotulo}:</div>
            <div className={styles.campoImagem}>
                <div className={styles.quadroImagem}>
                    {anexo && anexo.nomeArquivoOriginal ? (
                        isImage(anexo.nomeArquivoOriginal) ? (
                            <Zoom image={{ src: anexo.urlArquivo || "", alt: rotulo, className: styles.imagem }} />
                        ) : (
                            <a
                                href={anexo.urlArquivo || undefined}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.anexoDocumento}
                            >
                                <DocumentIcon className={styles.iconeDocumento} title="Download documento" />
                                <div className={styles.nomeArquivoAnexo}>{anexo.nomeArquivoOriginal}</div>
                            </a>
                        )
                    ) : (
                        <ImageErrorIcon title="Sem imagem" />
                    )}
                </div>
                <AcoesCampo nome="urlArquivo" {...props} link />
            </div>
        </div>
    );
};
export interface CampoCheckboxProps {
    nome?: string;
    idFormacaoAcademica?: string;
    idExperienciaProfissional?: string;
    rotulo: string;
    valor: boolean;
    format?: (valor?: string) => React.ReactNode;
}
const formatPadraoCampoCheckbox = (valor?: string) => (valor ? (valor.toUpperCase() === "TRUE" ? "Sim" : "Não") : "");

export const CampoCheckbox = (props: CampoCheckboxProps) => {
    const { rotulo, valor, format } = props;
    return (
        <div className={classNames(styles.campo, styles.campoCheckbox)}>
            <div>
                <label>
                    <input type="checkbox" checked={valor} readOnly />
                    {rotulo}
                </label>
                <AcoesCampo {...props} format={format || formatPadraoCampoCheckbox} />
            </div>
        </div>
    );
};
