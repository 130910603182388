import React from "react";
import { OmnijusCampoCPF } from "shared/form/custom-fields/omnijus-campo-cpf";
import { OmnijusCampoEscritorio } from "shared/form/custom-fields/omnijus-campo-escritorio";
import { OmnijusCampoOrgaoClasse } from "shared/form/custom-fields/omnijus-campo-orgao-classe";
import { OmnijusCampoQualificacaoPrestador } from "shared/form/custom-fields/omnijus-campo-qualificacao-prestador";
import { OmnijusCampoUF } from "shared/form/custom-fields/omnijus-campo-uf";
import { OmnijusFiltroCliente } from "shared/form/custom-fields/omnijus-filtro-cliente";
import { OmnijusDateField } from "shared/form/fields/omnijus-date-field";
import { OmnijusPictureField } from "shared/form/fields/omnijus-picture-field";
import { OmnijusRadioField } from "shared/form/fields/omnijus-radio-field";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import styles from "./identificacao.module.scss";

export interface CamposIdentificacao {
    associado?: "associadoCliente" | "associadoEscritorio" | "comunidade";
    nome: string;
    username: string;
    idEntidadeClasse: number;
    numeroRegistroEntidadeClasse: string;
    dataRegistroEntidadeClasse?: Date;
    cpf?: string;
    rgNumero: string;
    rgOrgaoExpeditor: string;
    rgDataExpedicao?: Date;
    rgUf: string;
    idQualificacao: number;
    idEscritorio?: string;
    idCliente?: string;
    foto?: { dataUrl?: Blob; id?: string };
}

export const Identificacao = (props: { values: CamposIdentificacao; edicao?: boolean }) => (
    <OmnijusCard
        header={<h3>Identificação</h3>}
        body={
            <div className={styles.formularioIdentificacao}>
                <OmnijusCampoQualificacaoPrestador name="idQualificacao" label="Perfil" />
                <OmnijusTextField label="Usuário" name="username" />
                <OmnijusCampoCPF label="CPF" name="cpf" disabled={props.edicao} />
                <OmnijusPictureField
                    title="foto"
                    name="foto"
                    loadImageText="Upload de Foto"
                    width={83}
                    height={82}
                    maxSizeMB={5}
                />

                <OmnijusTextField label="Nome" name="nome" />

                <OmnijusTextField
                    label="Registro Profissional"
                    name="numeroRegistroEntidadeClasse"
                    disabled={props.edicao}
                />
                <OmnijusCampoOrgaoClasse label="Órgão" name="idEntidadeClasse" disabled={props.edicao} />
                <OmnijusDateField label="Data de Expedição" name="dataRegistroEntidadeClasse" disabled={props.edicao} />

                <OmnijusTextField label="RG" name="rgNumero" disabled={props.edicao} />
                <OmnijusTextField label="Órgão expeditor" name="rgOrgaoExpeditor" disabled={props.edicao} />
                <OmnijusCampoUF label="UF" name="rgUf" disabled={props.edicao} />
                <OmnijusDateField label="Data de Expedição" name="rgDataExpedicao" disabled={props.edicao} />

                <div className={styles.associado}>
                    <OmnijusRadioField
                        name="associado"
                        options={[
                            { label: "Associado a um Cliente", value: "associadoCliente" },
                            { label: "Associado a um Escritório", value: "associadoEscritorio" },
                            { label: "Comunidade Omnijus", value: "comunidade" },
                        ]}
                    />
                </div>
                <div>
                    {props.values.associado === "associadoCliente" ? (
                        <OmnijusFiltroCliente label="Selecionar Cliente" name="idCliente" />
                    ) : props.values.associado === "associadoEscritorio" ? (
                        <OmnijusCampoEscritorio label="Selecionar Escritório" name="idEscritorio" />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        }
    />
);
