import { Loading } from "@omnijus/common";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import { ActionFeedback } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { DeadLetterService } from "services/dead-letter/dead-letter-service";
import { EnvioResponse, ErroResponse, ItemTimeLine } from "services/dead-letter/models/dead-letter";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { OmnijusTextAreaField } from "shared/form/fields/omnijus-textarea-field";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormatJson } from "../forms/forms";
import styles from "./modal-detalhar-item.module.scss";

const formataData = (input: any) => {
    if (!input) {
        return <div>--</div>;
    }
    const data = new Date(input);
    return (
        <div>
            {format(new Date(data), "dd/MM/yyyy")} <br />
            <small>{format(new Date(data), "HH:mm:ss")}</small>
        </div>
    );
};

const MySwal = withReactContent(Swal);

export const exibirModalDetalharItemTimeLine = async (item: ItemTimeLine, idMensagem: string) => {
    return new Promise<void>((resolve) => {
        MySwal.fire({
            title: item.tipo === "Erro" ? "Detalhes do erro" : "Detalhes do envio",
            html:
                item.tipo === "Erro" ? (
                    <ModalDetalharErro idItem={item.id} idMensagem={idMensagem} onReenvio={resolve} />
                ) : (
                    <ModalDetalharEnvio idItem={item.id} idMensagem={idMensagem} />
                ),
            showCancelButton: false,
            showConfirmButton: false,
            cancelButtonText: "Voltar",
            showCloseButton: true,
            width: "900px",
            customClass: { closeButton: styles.closeButton },
        });
    });
};

export const ModalDetalharErro = (props: { idItem: string; idMensagem: string; onReenvio: () => void }) => {
    const [promiseErro, setPromiseErro] = useState<Promise<ErroResponse | undefined>>();

    useEffect(() => {
        setPromiseErro(DeadLetterService.obterHistoricoErro(props.idMensagem, props.idItem));
    }, [props]);

    return (
        <Loading promise={promiseErro}>
            {(erro) => (
                <div>
                    <div className={styles.cabecalho}>
                        <div>
                            <span className={styles.label}>Código:</span>
                            <span>{erro?.id}</span>
                        </div>
                        <div>
                            <span className={styles.label}>Data/Hora:</span>
                            <span>{formataData(erro?.data)}</span>
                        </div>
                    </div>
                    <div className={styles.cabecalho}>
                        <div>
                            <span className={styles.label}>Tipo de Erro:</span>
                            <span>{erro?.tipoErro || "Tipo não especificado"}</span>
                        </div>
                    </div>
                    <div className={styles.cabecalho}>
                        <div>
                            <span className={styles.label}>Mensagem de erro:</span>
                            <span>{erro?.mensagemErro}</span>
                        </div>
                    </div>
                    <div className={styles.cabecalho}>
                        <div>
                            <span className={styles.label}>Key:</span>
                            <span>{erro?.key}</span>
                        </div>
                    </div>
                    <div className={styles.cabecalho}>
                        <div>
                            <span className={styles.label}>Timestamp Erro:</span>
                            <span>{formataData(erro?.timeStampErro)}</span>
                        </div>
                    </div>
                    <div className={styles.cabecalho}>
                        <span className={styles.label}>Stack Trace:</span>
                        <span>{erro?.stackTrace}</span>
                    </div>
                    <div className={styles.table}>
                        <Formik
                            initialValues={{
                                valor: erro?.mensagem || "",
                            }}
                            onSubmit={async (values) => {
                                await ActionFeedback.processing({
                                    title: "Reenviando Mensagem",
                                    execution: DeadLetterService.reenviarMensagem(erro?.idMensagem || "", values.valor),
                                });
                                props.onReenvio();
                            }}
                            // validationSchema={validationSchema}
                        >
                            <Form>
                                <label className={styles.campoForm}>
                                    <span className={styles.label}>Mensagem enviada:</span>
                                </label>

                                <OmnijusTextAreaField rows={15} name="valor" />
                                <div className={styles.spacer} />
                                <ButtonPrimary type="submit">Reenviar mensagem</ButtonPrimary>
                            </Form>
                        </Formik>
                    </div>
                </div>
            )}
        </Loading>
    );
};

export const ModalDetalharEnvio = (props: { idItem: string; idMensagem: string }) => {
    const [promiseEnvio, setPromiseEnvio] = useState<Promise<EnvioResponse | undefined>>();

    useEffect(() => {
        setPromiseEnvio(DeadLetterService.obterEnvio(props.idMensagem, props.idItem));
    }, [props]);

    return (
        <Loading promise={promiseEnvio}>
            {(envio) => (
                <div>
                    <div className={styles.cabecalho}>
                        <div>
                            <span className={styles.label}>Código:</span>
                            <span>{envio?.id}</span>
                        </div>
                        <div>
                            <span className={styles.label}>Data/Hora:</span>
                            <span>{formataData(envio?.data)}</span>
                        </div>
                    </div>
                    <div className={styles.cabecalho}>
                        <div>
                            <span className={styles.label}>Numero do reenvio:</span>
                            <span>{envio?.numeroReenvio || "Tipo não especificado"}</span>
                        </div>
                        <div>
                            <span className={styles.label}>Usuario:</span>
                            <span>{envio?.usuario}</span>
                        </div>
                    </div>
                    <div className={styles.cabecalho}>
                        <span className={styles.label}>Mensagem enviada:</span>
                    </div>
                    <div className={styles.cabecalho}>
                        <textarea disabled={true} rows={15} cols={120} value={FormatJson(envio?.mensagem || "")} />
                    </div>
                </div>
            )}
        </Loading>
    );
};
