import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { PrestadorFiltroService } from "services/filtros/filtros-prestador-service";
import { TipoPeriodoFormacao } from "services/filtros/models/tipo-periodo-formacao";
import { OmnijusSelectField, SelectObject } from "shared/form/fields/omnijus-select-field";
import { Loading } from "shared/loading/loading";

export const OmnijusCampoTipoPeriodoFormacao = (props: { hideLabel?: boolean; name?: string; label?: string }) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<TipoPeriodoFormacao[] | undefined>>();
    const name = props.name || "tipoPeriodo";
    const [field, , helpers] = useField({ name });

    useEffect(() => {
        setPromiseOpcoes(PrestadorFiltroService.listarTiposPeriodoFormacao());
    }, []);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    name={name}
                    label={props.hideLabel ? undefined : props.label || "Tipo de período"}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.descricao || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                    onChange={(o) => {
                        const aux = o as SelectObject;
                        helpers.setValue(aux?{ id: parseInt(aux.value), descricao: aux.label }:{id:-1});
                    }}
                    getValue={() => String(field.value.id)}
                />
            )}
        </Loading>
    );
};
