import { dateToDDMMYYYY } from "lib/time";
import React from "react";
import { Anexo } from "services/onboarding/models/anexo";
import { IdEtapaCadastro } from "services/onboarding/models/id-etapa-cadastro";
import { CampoCadastro, CampoCheckbox, CampoImagem } from "../campo-cadastro/campo-cadastro";
import { CardCadastro } from "../card-cadastro/card-cadastro";
import styles from "./registro-profissional.module.scss";

interface RegistroProfissionalProps {
    possuiRegistroProfissional: boolean;
    entidadeClasse?: string;
    dataRegistroEntidadeClasse?: Date;
    registroEntidadeClasse?: string;
    tipoDocumentoEntidadeClasse?: string;
    frenteRegistroProfissional?: Anexo;
    versoRegistroProfissional?: Anexo;
}

const etapa = { descricao: "Registro profissional", id: IdEtapaCadastro.RegistroProfissional };

export const RegistroProfissional = (props: RegistroProfissionalProps) => {
    return (
        <CardCadastro
            header={etapa.descricao}
            body={
                <div>
                    <CampoCheckbox
                        nome="possuiRegistroProfissional"
                        rotulo="Não possui registro profissional"
                        valor={!props.possuiRegistroProfissional}
                        format={(valor?: string) =>
                            valor?.toUpperCase() === "FALSE" ? "Sim" : valor?.toUpperCase() === "TRUE" ? "Não" : ""
                        }
                    />
                    {
                        //props.possuiRegistroProfissional &&
                        <div className={styles.grupoCampos}>
                            <div>
                                <CampoCadastro
                                    rotulo="Entidade de classe"
                                    valor={props.entidadeClasse}
                                    nome="idEntidadeClasse"
                                />
                                <CampoCadastro
                                    rotulo="Data de registro"
                                    valor={props.dataRegistroEntidadeClasse?.toString()}
                                    nome="dataRegistroEntidadeClasse"
                                    format={(dataRegistro?: string) =>
                                        dataRegistro ? dateToDDMMYYYY(new Date(dataRegistro)) : ""
                                    }
                                />
                                <CampoCadastro
                                    rotulo="Numero"
                                    valor={props.registroEntidadeClasse}
                                    nome="registroEntidadeClasse"
                                />
                                <CampoCadastro
                                    rotulo="Tipo de documentação"
                                    valor={props.tipoDocumentoEntidadeClasse}
                                    nome="idTipoDocumentoEntidadeClasse"
                                />
                            </div>
                            <CampoImagem
                                rotulo={`Frente${props.tipoDocumentoEntidadeClasse ? ' ' + props.tipoDocumentoEntidadeClasse : ''}`}
                                anexo={props.frenteRegistroProfissional}
                            />
                            <CampoImagem
                                rotulo={`Verso${props.tipoDocumentoEntidadeClasse ? ' ' + props.tipoDocumentoEntidadeClasse : ''}`}
                                anexo={props.versoRegistroProfissional}
                            />
                        </div>
                    }
                </div>
            }
            etapa={etapa}
        />
    );
};
