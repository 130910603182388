import { RequestApi } from "@omnijus/common";
import { AtividadeService } from "services/atividade/atividade-service";
import { CLIENT_ID } from "services/client-id";
import { FiltroTipoAtividade } from "./models/filtro-tipo-atividade";
import { TipoAtividadePrincipal } from "./models/tipo-atividade-principal";

const atividadesFiltroUrl = process.env.REACT_APP_ATIVIDADE_API_URL!;

const requestApi = new RequestApi(CLIENT_ID);

export const AtividadeFiltroService = {
    listarTiposAtividade: () => {
        const url = `${atividadesFiltroUrl}/Atividades/ListarTiposAtividade`;
        return requestApi.get<FiltroTipoAtividade[]>(url);
    },
    listarAtividades: (IdArea?: number, IdTipoAtividade?: number) => {
        return AtividadeService.listarAtividades({ EstaAtivo: true, IdArea, IdTipoAtividade });
    },
    listarAtividadesPrincipais: () => {
        const url = atividadesFiltroUrl + "/Atividades/TiposAtividades/Principais";
        return requestApi.get<TipoAtividadePrincipal[]>(url);
    },
};
