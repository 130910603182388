import { ReactComponent as EditPen } from "assets/images/edit-pen.svg";
import { useFormikContext } from "formik";
import { exibirModalEnviarNotificacoes } from "pages/onboarding/cadastro/enviar-notificacoes/enviar-notificacoes";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import {
    listarCadastroPrestadores,
    postNotificacaoCadastroPrestadores,
} from "services/cadastro-prestadores/cadastro-prestadores-service";
import { DetalheCadastroPrestadores } from "services/cadastro-prestadores/models/detalhe-cadastro-prestadores";
import { IdTipoCadastro } from "services/cadastro-prestadores/models/id-tipo-cadastro";
import { ParametrosListarCadastroPrestadores } from "services/cadastro-prestadores/models/parametros-listar-cadastro-prestadores";
import { CardFiltros } from "shared/form/card-filtros/card-filtros";
import { OmnijusCampoCPF } from "shared/form/custom-fields/omnijus-campo-cpf";
import { OmnijusCampoEscritorio } from "shared/form/custom-fields/omnijus-campo-escritorio";
import { OmnijusCampoQualificacaoPrestador } from "shared/form/custom-fields/omnijus-campo-qualificacao-prestador";
import { OmnijusFiltroCliente } from "shared/form/custom-fields/omnijus-filtro-cliente";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import { FiltrosAplicados } from "shared/form/filtros-aplicados/filtros-aplicados";
import { ValoresComboContext, ValoresCombos } from "shared/form/valores-combo-context";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import * as Yup from "yup";
import { getDescricaoTipoCadastro } from "./descricao-tipo-cadastro";
import { getDescricaoTipoPrestador } from "./descricao-tipo-prestador";
import styles from "./listagem-cadastro-prestadores.module.scss";

const FiltrosCadastroPrestadores = () => {
    const formikContext = useFormikContext<any>();
    const tipoCadastro = formikContext.values["TipoCadastro"];
    const tipoCadastroRef = useRef(tipoCadastro);

    const setPJ = useCallback(() => {
        if (tipoCadastro !== IdTipoCadastro.PessoaJuridica) {
            formikContext.setFieldValue("TipoCadastro", IdTipoCadastro.PessoaJuridica);
        }
    }, [formikContext, tipoCadastro]);
    const setPF = useCallback(() => {
        if (tipoCadastro !== IdTipoCadastro.PessoaFisica) {
            formikContext.setFieldValue("TipoCadastro", IdTipoCadastro.PessoaFisica);
        }
    }, [formikContext, tipoCadastro]);

    useEffect(() => {
        const { setValues, values } = formikContext;
        if (tipoCadastro !== tipoCadastroRef.current) {
            tipoCadastroRef.current = tipoCadastro;
            switch (tipoCadastro) {
                case IdTipoCadastro.PessoaFisica:
                    setValues({
                        ...values,
                        razaoSocial: "",
                        nomeFantasia: "",
                        cnpj: "",
                    });
                    break;
                case IdTipoCadastro.PessoaJuridica:
                    setValues({
                        ...values,
                        nome: "",
                        cpf: "",
                    });
                    break;
                case "":
                    setValues({
                        ...values,
                        nome: "",
                        cpf: "",
                        razaoSocial: "",
                        nomeFantasia: "",
                        cnpj: "",
                    });
            }
        }
    }, [tipoCadastro, formikContext]);

    return (
        <>
            <OmnijusSelectField
                name="TipoCadastro"
                label="Tipo de Cadastro"
                options={[
                    { label: "Pessoa Física", value: IdTipoCadastro.PessoaFisica },
                    { label: "Pessoa Jurídica", value: IdTipoCadastro.PessoaJuridica },
                ]}
            />
            <OmnijusCampoQualificacaoPrestador name="IdQualificacao" label="Perfil" />
            <OmnijusFiltroCliente name="ClienteAssociado" label="Associado a um Cliente" />
            <OmnijusCampoEscritorio name="EscritorioAssociado" label="Associado a um Escritório" />
            {tipoCadastro !== IdTipoCadastro.PessoaFisica && (
                <>
                    <OmnijusTextField name="RazaoSocial" label="Razão Social" onChange={setPJ} />
                    <OmnijusTextField name="NomeFantasia" label="Nome Fantasia" onChange={setPJ} />
                    <OmnijusTextField name="Cnpj" label="CNPJ" onChange={setPJ} />
                </>
            )}
            {tipoCadastro !== IdTipoCadastro.PessoaJuridica && (
                <>
                    <OmnijusTextField name="Nome" label="Nome" onChange={setPF} />
                    <OmnijusCampoCPF name="Cpf" label="CPF" onChange={setPF} />
                </>
            )}
            <OmnijusSelectField
                name="Ativo"
                label="Status"
                options={[
                    { label: "Ativo", value: "true" },
                    { label: "Inativo", value: "false" },
                ]}
                className={styles.filtroStatus}
            />
        </>
    );
};

export const ListagemCadastroLegalOps = () => {
    const history = useHistory();
    const initialValues: ParametrosListarCadastroPrestadores = {};
    const validationSchema = Yup.object().shape({});
    const [promiseListaCadastros, setPromiseListaCadastros] =
        useState<Promise<DetalheCadastroPrestadores[] | undefined>>();
    const [parametrosBusca, setParametrosBusca] = useState<ParametrosListarCadastroPrestadores>();
    const [infoFiltros, setInfoFiltros] = useState<ValoresCombos>({
        IdQualificacao: { label: "Qualificação" },
        TipoCadastro: { label: "Tipo de Cadastro" },
    });

    useEffect(() => {
        setPromiseListaCadastros(listarCadastroPrestadores(parametrosBusca));
    }, [parametrosBusca]);

    return (
        <div className={styles.listagemCadastroPrestadores}>
            <h2>Cadastros de Prestadores</h2>

            <ValoresComboContext.Provider value={{ valoresCombos: infoFiltros, setValoresCombos: setInfoFiltros }}>
                <CardFiltros
                    onBuscar={(values) => {
                        setParametrosBusca(values);
                    }}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    botaoFiltrarInline
                >
                    <FiltrosCadastroPrestadores />
                </CardFiltros>
                <div className={styles.barraAcao}>
                    <FiltrosAplicados
                        parametros={parametrosBusca || {}}
                        onRemove={(nome) =>
                            setParametrosBusca({
                                ...parametrosBusca,
                                [nome]: undefined,
                            })
                        }
                    />
                    <div className={styles.adicionar}>
                        <Link to="/cadastro-legal-ops/criar">Adicionar Pessoa Física</Link>
                        <Link to="#">Adicionar Pessoa Jurídica</Link>
                        <button
                            onClick={() =>
                                exibirModalEnviarNotificacoes(
                                    parametrosBusca || {},
                                    infoFiltros,
                                    postNotificacaoCadastroPrestadores
                                )
                            }
                        >
                            Enviar Notificações
                        </button>
                    </div>
                </div>
            </ValoresComboContext.Provider>

            <Loading promise={promiseListaCadastros}>
                {(politicasTemplate) => (
                    <OmnijusCard
                        body={
                            <DataTable
                                noHeader={true}
                                customStyles={{
                                    rows: {
                                        style: {
                                            cursor: "pointer",
                                        },
                                    },
                                }}
                                pagination={true}
                                paginationPerPage={20}
                                noDataComponent={<p>Nenhum registro encontrado!</p>}
                                onRowClicked={(p) => {
                                    history.push(`/cadastro-legal-ops/${p.tipoCadastro}/${p.id}`);
                                }}
                                data={politicasTemplate || []}
                                columns={[
                                    {
                                        name: "Nome / Razão Social",
                                        selector: (row) => row.nomeOuRazaoSocial,
                                        sortable: true,
                                    },
                                    {
                                        name: "CPF / CNPJ",
                                        selector: (row) => row.cpfCnpj,
                                        sortable: true,
                                        maxWidth: "12rem",
                                    },
                                    {
                                        name: "Vínculo",
                                        selector: (row) => getDescricaoTipoPrestador(row.idTipo),
                                        maxWidth: "15rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "Perfil",
                                        selector: (row) => row.qualificacoes?.join(", "),
                                        sortable: true,
                                        maxWidth: "15rem",
                                    },
                                    {
                                        name: "Tipo de Cadastro",
                                        selector: (row) => getDescricaoTipoCadastro(row.tipoCadastro),
                                        maxWidth: "15rem",
                                        sortable: true,
                                    },
                                    {
                                        name: "",
                                        button: true,
                                        cell: (row) => (
                                            <Link
                                                className={styles.editarConfig}
                                                to={`/cadastro-legal-ops/editar/${row.tipoCadastro === 0 ? "PF" : "PJ"
                                                    }/${row.id}`}
                                            >
                                                <EditPen />
                                            </Link>
                                        ),
                                        maxWidth: "3rem",
                                        minWidth: "3rem",
                                    },
                                ]}
                            />
                        }
                    />
                )}
            </Loading>
        </div>
    );
};
