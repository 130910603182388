import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CadastroPrestadorPessoaJuridica } from "services/cadastro-prestadores/models/cadastro-prestador-pessoa-juridica";
import { ButtonSecondary } from "shared/buttons/button-secondary/button-secondary";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import styles from "./detalhar-cadastro-prestador-pj.module.scss";
import { detalharPrestadorServicoPJ } from "services/cadastro-prestadores/cadastro-prestadores-service";
import DataTable from "react-data-table-component";
import { getDescricaoTipoPrestador } from "../descricao-tipo-prestador";

export const DetalharCadastroPrestadorPJ = () => {
    const { idPrestador } = useParams<{ idPrestador: string }>();
    const [promiseDetalharPrestador, setPromiseDetalharPrestador] = useState<Promise<CadastroPrestadorPessoaJuridica | undefined>>();
    const history = useHistory();

    useEffect(() => {
        setPromiseDetalharPrestador(
            detalharPrestadorServicoPJ(idPrestador).then((r) => {                             
                return r;
            })            
        );
    }, [idPrestador]);

    return (
        <div className={styles["detalhar-cadastro-prestador-pj"]}>
            { <Loading promise={promiseDetalharPrestador}>
                {prestadorDetalhado => (
                    prestadorDetalhado ?
                        <>
                            <OmnijusCard
                                header={<h2>Identificação</h2>}
                                body={
                                    <div className={styles["identificacao"]}>
                                        <div className={styles["dados"]}>
                                            <p>
                                                <span className={styles["prefixo"]}>Razão Social</span>
                                                {prestadorDetalhado.razaoSocial}
                                            </p>
                                            <p>
                                                <span className={styles["prefixo"]}>Nome Fantasia</span>
                                                {prestadorDetalhado.nomeFantasia}
                                            </p>
                                            <p>
                                                <span className={styles["prefixo"]}>CNPJ</span>
                                                {prestadorDetalhado.cnpj}
                                            </p>
                                            <p>
                                                <span className={styles["prefixo"]}>Inscrição Estadual</span>
                                                {prestadorDetalhado.inscricaoEstadual}
                                            </p>
                                            <p>
                                                <span className={styles["prefixo"]}>Associação</span>
                                                {getDescricaoTipoPrestador(prestadorDetalhado.idTipoPrestadorServico)}
                                            </p>
                                        </div>
                                    </div>
                                }
                            />
                            <OmnijusCard
                                header={<h2>Endereços</h2>}
                                body={
                                    <DataTable
                                        noHeader={true}
                                        pagination={false}
                                        noDataComponent={<p>Nenhum registro encontrado!</p>}
                                        columns={[
                                            {
                                                name: "CEP",
                                                selector: "cep",
                                            },
                                            {
                                                name: "Logradouro",
                                                selector: "logradouro",
                                            },
                                            {
                                                name: "Nº",
                                                selector: "numero",
                                            },
                                            {
                                                name: "Complemento",
                                                selector: "complemento",
                                            },
                                            {
                                                name: "Bairro",
                                                selector: "bairro",
                                            },
                                            {
                                                name: "Cidade",
                                                selector: "cidade",
                                            },
                                            {
                                                name: "UF",
                                                selector: "uf",
                                            },
                                            {
                                                name: "Tipos",
                                                selector: (r) => (r.tipos !== null ? r.tipos.join(", ") : ""),
                                            },
                                        ]}
                                        data={prestadorDetalhado.enderecos}
                                    />
                                }
                            />
                            <OmnijusCard
                                header={<h2>Meios de comunicação</h2>}
                                body={
                                    <DataTable
                                        noHeader={true}
                                        pagination={false}
                                        noDataComponent={<p>Nenhum registro encontrado!</p>}
                                        columns={[
                                            {
                                                name: "Tipo",
                                                selector: "descricaoTipo",
                                            },
                                            {
                                                name: "Contato",
                                                selector: "valor",
                                            },
                                            {
                                                name: "Preferencial",
                                                selector: (r) => (r.preferencial ? "Sim" : "Não"),
                                            },
                                        ]}
                                        data={prestadorDetalhado.meiosComunicacao}
                                    />
                                }
                            />
                            <OmnijusCard
                                header={<h2>Dados bancários</h2>}
                                body={
                                    <div className={styles["dados-bancarios"]}>
                                        <p>
                                            <span className={styles["prefixo"]}>Banco</span>
                                            {prestadorDetalhado.numeroBanco}
                                        </p>
                                        <p>
                                            <span className={styles["prefixo"]}>Agência</span>
                                            {prestadorDetalhado.agencia}
                                        </p>
                                        <p>
                                            <span className={styles["prefixo"]}>Conta</span>
                                            {prestadorDetalhado.conta}
                                        </p>
                                        <p>
                                            <span className={styles["prefixo"]}>Tipo</span>
                                            {prestadorDetalhado.descricaoTipoConta}
                                        </p>
                                    </div>
                                }
                            />
                        </>
                        :
                        <OmnijusCard
                            body={<p className="text-center">Prestador não encontrado</p>}
                        />
                )}
            </Loading>}
            <div className={styles["acao-voltar"]}>
                <ButtonSecondary onClick={() => history.goBack()}>Voltar</ButtonSecondary>
            </div>
        </div>
    );
}