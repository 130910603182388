import { Form, Formik } from "formik";
import { ActionFeedback } from "@omnijus/common";
import React from "react";
import { IdTipoParametro } from "services/configuracoes/enums/id-tipo-parametro";
import { ParametroListado } from "services/configuracoes/models/parametro-listado";
import { atualizarParametro } from "services/configuracoes/parametros-service";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { object as YupObject, string as YupString } from "yup";
import styles from "./modal-editar-parametros.module.scss";

const validationSchema = YupObject().shape({
    valor: YupString().required("O valor é obrigatório"),
});

export interface ModalEditarParametrosProps {
    parametro: ParametroListado;
    atualizar: () => void;
}

const MySwal = withReactContent(Swal);

export const exibirModalEditarParametros = async (parametro: ParametroListado, atualizar: () => void) => {
    return MySwal.fire({
        title: "Editar Parâmetro",
        html: <ModalEditarParametros parametro={parametro} atualizar={atualizar} />,
        showCancelButton: false,
        showConfirmButton: false,
        cancelButtonText: "Voltar",
        showCloseButton: true,
        width: "600px",
        customClass: { closeButton: styles.closeButton },
    });
};

export const ModalEditarParametros = ({ parametro, atualizar }: ModalEditarParametrosProps) => {
    return (
        <div>
            <div className={styles.cabecalho}>
                <div>
                    <span className={styles.label}>Código:</span>
                    <span>{parametro.id}</span>
                </div>
                <div>
                    <span className={styles.label}>Descrição:</span>
                    <span>{parametro.descricao}</span>
                </div>
            </div>
            <div className={styles.table}>
                <Formik
                    initialValues={{
                        valor: parametro?.valor || "",
                    }}
                    onSubmit={async (values) => {
                        await ActionFeedback.processing({
                            title: "Editando parâmetro",
                            execution: atualizarParametro(parametro.id, values.valor).then(() => atualizar()),
                        });
                        MySwal.close();
                    }}
                    validationSchema={validationSchema}
                >
                    <Form>
                        <label className={styles.campoForm}>
                            <span className={styles.label}>Valor:</span>
                            {parametro.tipoParametro.id === IdTipoParametro.Booleano ? (
                                <OmnijusSelectField
                                    name="valor"
                                    options={[
                                        { label: "Sim", value: "true" },
                                        { label: "Não", value: "false" },
                                    ]}
                                />
                            ) : parametro.tipoParametro.id === IdTipoParametro.Numero ? (
                                <OmnijusTextField name="valor" masks={[{ mask: "########", charType: "numeric" }]} />
                            ) : (
                                <OmnijusTextField name="valor" />
                            )}
                        </label>

                        <ButtonPrimary type="submit">Editar parâmetro</ButtonPrimary>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};
