import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "services/client-id";
import { ConvitesEnviadosResult } from "./models/convites-enviados-result";
import { FilaLead } from "./models/fila-leads";
import { ParametrosPesquisaConvite } from "./models/parametros-pesquisa-convite";

const onboardingUrl = process.env.REACT_APP_BFF_ADMINISTRATIVO_API_URL + "/api/Onboarding/";

const requestApi = new RequestApi(CLIENT_ID);

export const getPesquisaFilaLeads = async (parametros: ParametrosPesquisaConvite) => {
    const url = new URL(`${onboardingUrl}v1/Fila/Pesquisa`);
    url.search = RequestApi.objectToQueryString(parametros);
    const res = await requestApi.get<FilaLead[]>(url.toString());
    return res?.map((filaLead) => ({
        ...filaLead,
        dataCadastro: filaLead.dataCadastro && new Date(filaLead.dataCadastro),
    }));
};

export const enviaConvitesListaFilaLeads = (leads: FilaLead[]) => {
    const url = new URL(`${onboardingUrl}v1/Fila/EnviarConvites`);
    return requestApi.put<ConvitesEnviadosResult>(
        url.toString(),
        leads.map((lead) => lead.id)
    );
};

export const enviaConviteFilaLead = (lead: FilaLead) => {
    const url = new URL(`${onboardingUrl}v1/Fila/${lead.id}/EnviarConvite`);
    return requestApi.put(url.toString());
};
