import React, { useEffect, useState } from "react";
import { listarEtapasCadastro } from "services/onboarding/filtros-onboarding-service";
import { EtapaCadastro } from "services/onboarding/models/etapa-cadastro";
import { Loading } from "shared/loading/loading";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

interface OmnijusCampoEtapaCadastroProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isDisabled?: boolean;
}

export const OmnijusCampoEtapaCadastro = (props: OmnijusCampoEtapaCadastroProps) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<EtapaCadastro[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoes(listarEtapasCadastro());
    }, []);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    isDisabled={props.isDisabled}
                    name={props.name || "IdEtapa"}
                    label={props.hideLabel ? undefined : props.label || "Etapa"}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.descricao || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
