export enum IdTipoMeioComunicacao {
    Celular = 1,
    Telefone = 2,
    Email = 3,
}

export interface PrestadorServicoUsuarioMeioComunicacao {
    idTipo: IdTipoMeioComunicacao;
    contato: string;
    principal: boolean;
}