import { ReactComponent as EditPen } from "assets/images/edit-pen.svg";
import { ReactComponent as TrashIcon } from "assets/images/trash-icon.svg";
import { ActionFeedback } from "@omnijus/common";
import { Format } from "lib/format";
import { PromiseState } from "@omnijus/common";
import { RequestApi } from "@omnijus/common";
import { dateToDDMMYYYY, getTimeHHmm } from "lib/time";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { useHistory, useLocation } from "react-router-dom";
import {
    deletarConvite,
    getPesquisaConvite,
    importarConvitesCSV,
    reenviarNotificacaoConvite,
} from "services/onboarding/convite-service";
import { Convite } from "services/onboarding/models/convite";
import { IdStatusConvite } from "services/onboarding/models/id-status-convite";
import {
    ParametrosPesquisaConvite,
    parseParametrosPesquisaConvite,
} from "services/onboarding/models/parametros-pesquisa-convite";
import { CardFiltros } from "shared/form/card-filtros/card-filtros";
import { OmnijusCampoCelular } from "shared/form/custom-fields/omnijus-campo-celular";
import { OmnijusCampoCPF } from "shared/form/custom-fields/omnijus-campo-cpf";
import { OmnijusCampoStatusConvite } from "shared/form/custom-fields/omnijus-campo-status-convite";
import { OmnijusRangeDateField } from "shared/form/fields/omnijus-range-date-field";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import { FiltrosAplicados } from "shared/form/filtros-aplicados/filtros-aplicados";
import { ValoresComboContext, ValoresCombos } from "shared/form/valores-combo-context";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import Swal from "sweetalert2";
import { exibirModalAdicionarConvite } from "../adicionar-convite/adicionar-convite";
import { downloadExportar } from "./download-exportar";
import styles from "./listagem-convites.module.scss";

export const ListagemConvites = () => {
    const query = useLocation().search;
    const history = useHistory();
    const [promiseListaConvites, setPromiseListaConvites] = useState<Promise<Convite[] | undefined>>();
    const parametrosBusca = useMemo(() => parseParametrosPesquisaConvite(query), [query]);
    const [infoFiltros, setInfoFiltros] = useState<ValoresCombos>({
        DataInicio: { label: "Data inicial de cadastro do convite", format: dateToDDMMYYYY },
        DataFim: { label: "Data final de cadastro do convite", format: dateToDDMMYYYY },
    });
    const [selecionados, setSelecionados] = useState<Convite[]>([]);

    const buscar = useCallback(
        (filtros: ParametrosPesquisaConvite) => {
            // TODO (inserir método format:string=>TipoParametro no filtro select)
            const search = RequestApi.objectToQueryString(filtros);
            history.push({
                search,
            });
        },
        [history]
    );

    const atualiza = useCallback(() => {
        if (parametrosBusca) {
            const promiseConvites = getPesquisaConvite({
                ...parametrosBusca,
                Telefone: parametrosBusca.Telefone?.replace(/\D/g, ""),
            });
            setPromiseListaConvites(promiseConvites);
            return promiseConvites;
        }
    }, [parametrosBusca]);

    useEffect(() => {
        atualiza();
    }, [atualiza]);

    const columns: IDataTableColumn<Convite>[] = useMemo(
        () => [
            {
                name: "Nome",
                selector: (c) => c.nome,
                sortable: true,
            },
            {
                name: "CPF",
                selector: (c) => c.cpf,
                maxWidth: "10rem",
            },
            {
                name: "Email",
                selector: (c) => c.email,
            },
            {
                name: "Telefone",
                selector: (c) => c.telefone,
                maxWidth: "10rem",
                format: (c) => c.telefone && Format.formatCellphoneNumber(c.telefone),
            },
            {
                name: "Status",
                selector: (c) => c.status,
                format: (c) => (
                    <div className={styles.statusConvite}>
                        <div>{c.status}</div>
                        {c.idStatusConvite === IdStatusConvite.Utilizado && c.dataHoraUtilizacao && (
                            <div className={styles.dataHoraUtilizacao}>
                                <span>{dateToDDMMYYYY(c.dataHoraUtilizacao)}</span>
                                <span className={styles.horaUtilizacao}>{getTimeHHmm(c.dataHoraUtilizacao)}</span>
                            </div>
                        )}
                    </div>
                ),
                maxWidth: "10rem",
                sortable: true,
                center: true,
            },
            {
                name: "Data Cadastro do Convite",
                selector: (c) => c.dataCadastroConvite,
                format: (c) =>
                    c.dataCadastroConvite ? (
                        <>
                            <span>{dateToDDMMYYYY(c.dataCadastroConvite)}</span>
                            <span className={styles.horaAlteracao}>{getTimeHHmm(c.dataCadastroConvite)}</span>
                        </>
                    ) : (
                        ""
                    ),
                maxWidth: "13rem",
                sortable: true,
            },
            {
                name: "",
                cell: (c) => (
                    <div className={styles.acoesConvite}>
                        <button
                            disabled={c.idStatusConvite === IdStatusConvite.Utilizado}
                            className={styles.iconeAcao}
                            title={
                                c.idStatusConvite === IdStatusConvite.Utilizado
                                    ? "O convite já foi utilizado"
                                    : "Editar convite"
                            }
                            onClick={async () => {
                                if (c.idStatusConvite !== IdStatusConvite.Utilizado) {
                                    if (await exibirModalAdicionarConvite(c)) {
                                        await atualiza();
                                    }
                                }
                            }}
                        >
                            <EditPen />
                        </button>
                        <button
                            disabled={c.idStatusConvite === IdStatusConvite.Utilizado}
                            className={styles.iconeAcao}
                            title={
                                c.idStatusConvite === IdStatusConvite.Utilizado
                                    ? "O convite já foi utilizado"
                                    : "Apagar convite"
                            }
                            onClick={async () => {
                                if (c.idStatusConvite !== IdStatusConvite.Utilizado) {
                                    if (
                                        await ActionFeedback.confirm("Tem certeza que deseja apagar o convite?", "Sim")
                                    ) {
                                        await ActionFeedback.processing({
                                            title: "Apagando convite",
                                            execution: deletarConvite(c.id),
                                        });
                                        await ActionFeedback.info({ title: "Convite apagado" });
                                        await atualiza();
                                    }
                                }
                            }}
                        >
                            <TrashIcon />
                        </button>
                    </div>
                ),
                maxWidth: "4rem",
                minWidth: "4rem",
            },
        ],
        [atualiza]
    );

    return (
        <div className={styles.listagemCadastros}>
            <h2>Convites</h2>
            <ValoresComboContext.Provider value={{ valoresCombos: infoFiltros, setValoresCombos: setInfoFiltros }}>
                <CardFiltros
                    onBuscar={async (filtros) => {
                        if (!promiseListaConvites || !(await PromiseState.isPending(promiseListaConvites))) {
                            buscar(filtros);
                        } else {
                            Swal.fire({
                                icon: "warning",
                                text: "Uma busca já está sendo realizada no momento! Aguarde...",
                            });
                        }
                    }}
                    initialValues={parametrosBusca}
                >
                    <OmnijusTextField name="Nome" label="Nome" />
                    <OmnijusCampoCPF name="Cpf" label="CPF" />
                    <OmnijusTextField name="Email" label="Email" />
                    <OmnijusCampoCelular name="Telefone" label="Telefone" />
                    <OmnijusCampoStatusConvite name="Status" label="Status" />
                    <OmnijusRangeDateField nameDe="DataInicio" nameAte="DataFim" label="Data cadastro do convite" />
                </CardFiltros>
                <div className={styles.barraAcao}>
                    <div className={styles.filtrosAplicados}>
                        {Object.entries(parametrosBusca).length > 0 && (
                            <FiltrosAplicados
                                parametros={parametrosBusca}
                                onRemove={(nome) => buscar({ ...parametrosBusca, [nome]: undefined })}
                            />
                        )}
                    </div>
                    <div className={styles.botoesAcao}>
                        <button
                            className={styles.botaoAcao}
                            onClick={async () => {
                                var convites = await promiseListaConvites;
                                if (convites) {
                                    await ActionFeedback.processing({
                                        title: "Buscando convites",
                                        execution: downloadExportar(convites, "csv"),
                                    });
                                }
                            }}
                        >
                            Exportar CSV
                        </button>
                        <div className={styles.importarConvites}>
                            <div>
                                <a
                                    className={styles.linkModelo}
                                    href={`${process.env.REACT_APP_BFF_ADMINISTRATIVO_API_URL}/Content/modelo-convite.csv`}
                                    download
                                >
                                    Modelo CSV
                                </a>
                            </div>
                            <label className={styles.botaoAcao}>
                                <input
                                    className={styles.fileInput}
                                    type="file"
                                    onChange={async (e) => {
                                        const files = e.target.files;
                                        if (files && files.length > 0) {
                                            const r = await ActionFeedback.processing({
                                                title: "Importanto convites",
                                                execution: importarConvitesCSV(files[0]),
                                            });
                                            if (!r || r.totalConvitesNaoImportados !== 0) {
                                                await ActionFeedback.error({
                                                    title:
                                                        !r || r.totalConvitesImportados === 0
                                                            ? "Nenhum convite pode ser importado"
                                                            : "Erro ao importar convites. " +
                                                              `${r.totalConvitesNaoImportados} convites não foram importados`,
                                                });
                                            } else {
                                                await ActionFeedback.info({
                                                    title: "Todos os convites foram importados",
                                                });
                                            }
                                            await atualiza();
                                        }
                                    }}
                                    accept=".csv,text/csv"
                                />
                                Importar Convites
                            </label>
                        </div>
                        <button
                            className={styles.botaoAcao}
                            onClick={async () => {
                                await exibirModalAdicionarConvite();
                                await atualiza();
                            }}
                        >
                            Adicionar Convite
                        </button>
                        <button
                            className={styles.botaoAcao}
                            onClick={async () => {
                                if (selecionados && selecionados.length > 0) {
                                    const result = await ActionFeedback.processing({
                                        title: "Renviando notificações de convites",
                                        execution: reenviarNotificacaoConvite(selecionados),
                                    });
                                    if (result && result.quantidadeDeErros === 0) {
                                        await ActionFeedback.info({ title: "Todos as notificações foram reenviadas" });
                                    } else {
                                        await ActionFeedback.error({
                                            title:
                                                !result || result.quantidadeDeNotificacoesEnviadas === 0
                                                    ? "Nenhuma notificação pode ser enviado"
                                                    : `${result.quantidadeDeErros} notificações não puderam ser enviadas`,
                                        });
                                    }
                                    await atualiza();
                                } else {
                                    await ActionFeedback.error({
                                        title: "Selecione os convites para reenviar notificações",
                                    });
                                }
                            }}
                        >
                            Reenviar Notificações
                        </button>
                    </div>
                </div>
            </ValoresComboContext.Provider>
            <OmnijusCard
                className={styles.tabelaListagem}
                body={
                    <Loading promise={promiseListaConvites}>
                        {(cadastros) =>
                            cadastros ? (
                                <DataTable
                                    selectableRows
                                    selectableRowDisabled={(convite) =>
                                        convite.idStatusConvite === IdStatusConvite.Utilizado
                                    }
                                    onSelectedRowsChange={(a) => setSelecionados(a.selectedRows)}
                                    noHeader={true}
                                    pagination={true}
                                    paginationPerPage={20}
                                    noDataComponent={<p>Nenhum registro encontrado!</p>}
                                    columns={columns}
                                    data={cadastros}
                                    customStyles={{
                                        rows: {
                                            style: {
                                                minHeight: "80px",
                                                padding: "0 20px",
                                            },
                                        },
                                        head: {
                                            style: {
                                                padding: "0 20px",
                                            },
                                        },
                                    }}
                                />
                            ) : (
                                <></>
                            )
                        }
                    </Loading>
                }
            />
        </div>
    );
};
