import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { FiltroClasse } from "services/filtros/filtro-classe";
import { ProcessoFiltroService } from "services/filtros/filtros-processos-service";
import { Loading } from "shared/loading/loading";
import { OmnijusSelectField } from "shared/form/fields/omnijus-select-field";

const filtroRitoName = "IdRitoProcesso";

interface OmnijusFiltroTipoAcaoProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    reloadOnChangeName?: string;
}

export const OmnijusFiltroTipoAcao = (props: OmnijusFiltroTipoAcaoProps) => {
    const idRito = useFormikContext<any>().values[props.reloadOnChangeName || filtroRitoName];
    const [promiseOpcoesTipoAcao, setPromiseOpcoesTipoAcao] = useState<Promise<FiltroClasse[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoesTipoAcao(ProcessoFiltroService.listarClasse(idRito));
    }, [idRito, props.reloadOnChangeName]);

    return (
        <Loading promise={promiseOpcoesTipoAcao}>
            {(opcoesTipoAcao) => (
                <OmnijusSelectField
                    name={props.name || "IdClasseProcesso"}
                    label={props.hideLabel ? undefined : props.label || "Tipo de ação"}
                    options={opcoesTipoAcao
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.value.toString(),
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
