import React, { useEffect, useState } from "react";
import { listarStatusFilaLeads } from "services/onboarding/filtros-onboarding-service";
import { StatusFilaLead } from "services/onboarding/models/status-fila-lead";
import { Loading } from "shared/loading/loading";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

interface OmnijusCampoStatusFilaLeadsProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isDisabled?: boolean;
}

export const OmnijusCampoStatusFilaLeads = (props: OmnijusCampoStatusFilaLeadsProps) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<StatusFilaLead[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoes(listarStatusFilaLeads());
    }, []);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    isDisabled={props.isDisabled}
                    name={props.name || "Status"}
                    label={props.hideLabel ? undefined : props.label || "Status"}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.descricao || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
