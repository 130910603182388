export interface DeadLetterViewModel {
    id: string
    topico: string
    consumer: string
    maximoRetentativas: number
    totalErros: number
    totalEnvios: number
    tipoErro: string
    mensagemErro: string
    dataPrimeiraEmissao: Date
    dataCriacao: Date
    dataUltimaTentativa?: Date
    dataProximaTentativa?: Date
    idStatusMensagem: IdStatusMensagem
    statusMensagem: string
}

export interface DeadLetterResponse {
    mensagem: DeadLetterViewModel
    timeline: ItemTimeLine[]
    mensagemAtual: string
}

export interface ItemTimeLine {
    id: string
    tipo: string
    data: string
    titulo: string
    subtitulo?: string
}

export interface ReenviarMensagemRequest {
    mensagem: string
}

export enum IdStatusMensagem {
    EmProcessamento = 1,
    Processada = 2,
    Ignorada = 3,
    Erro = 9,
}

export interface StatusDeadLetter {
    id: string
    descricao: string
}

export interface DeadLetterResponse {
    mensagem: DeadLetterViewModel
    timeline: ItemTimeLine[]
    mensagemAtual: string
}

export interface DeadLetterResponse {
    mensagem: DeadLetterViewModel
    timeline: ItemTimeLine[]
    mensagemAtual: string
}

export interface EnvioResponse {
    id: string;
    data: string;
    numeroReenvio: number;
    mensagem: string;
    usuario: string;
}

export interface ErroResponse {
    id: string;
    idMensagem: string;
    data: string;
    mensagem: string;
    mensagemErro: string;
    tipoErro: string;
    timeStampErro: string;
    key: string;
    stackTrace: string;
}