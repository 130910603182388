import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as EditIcon } from "assets/images/edit-pen.svg";
import { ReactComponent as DeleteIcon } from "assets/images/trash-icon.svg";
import { Form, FieldArray, useField } from "formik";
import React from "react";
import { Banco } from "services/filtros/models/banco";
import { TipoContaBancaria } from "services/filtros/models/tipo-conta-bancaria";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { OmnijusCampoBanco } from "shared/form/custom-fields/omnijus-campo-banco";
import { OmnijusCampoTipoContaBancaria } from "shared/form/custom-fields/omnijus-campo-tipo-conta-bancaria";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import { showModalForm } from "shared/form/modal-form/modal-form";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import styles from "../cadastro-prestadores.module.scss";
import { object as YupObject, number as YupNumber, string as YupString } from "yup";

export interface DadoBancario {
    id?: string;
    banco: Banco;
    agencia: string;
    conta: string;
    tipo: TipoContaBancaria;
}

export const dadoBancarioValidation = YupObject().shape({
    banco: YupObject()
        .shape({ numero: YupNumber().min(0, "Selecione um banco") })
        .required("Selecione um banco"),
    agencia: YupString()
        .matches(/^[0-9]*(-[0-9xX])?$/, { message: "Agência inválida" })
        .required("Informe a agência"),
    conta: YupString()
        .matches(/^[0-9]*(-[0-9xX])?$/, { message: "Conta inválida" })
        .required("Informe a conta"),
    tipo: YupObject().shape({ id: YupNumber().min(0, "Informe o tipo da conta") }),
});

export const modalNovoBanco = (nomeAcao: string, valorInicial: DadoBancario) =>
    showModalForm(
        nomeAcao,
        valorInicial,
        <Form className={styles.modalForm}>
            <OmnijusCampoBanco name="banco" label="Banco" />
            <OmnijusTextField name="agencia" label="Agência" />
            <OmnijusTextField name="conta" label="Conta" />
            <OmnijusCampoTipoContaBancaria name="tipo" label="Tipo" />
            <div className={styles.linhaSubmit}>
                <ButtonPrimary type="submit">{nomeAcao}</ButtonPrimary>
            </div>
        </Form>,
        "70rem",
        dadoBancarioValidation
    );

export const DadosBancarios = ({ values }: { values: { dadosBancarios: DadoBancario[] } }) => {
    const name = "dadosBancarios";
    const [, meta] = useField({ name });
    return (
        <OmnijusCard
            header={<h3>Dados bancários</h3>}
            body={
                <FieldArray name={name}>
                    {(arrayHelpers) => (
                        <>
                            <table className={styles.table}>
                                <colgroup>
                                    <col />
                                    <col className={styles.colAgencia} />
                                    <col />
                                    <col className={styles.colTipoBanco} />
                                    <col className={styles.colIcones} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <td>Banco</td>
                                        <td>Agência</td>
                                        <td>Conta</td>
                                        <td>Tipo</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {values.dadosBancarios.map((dadoBancario, idx) => (
                                        <tr key={idx}>
                                            <td>{dadoBancario.banco.descricao}</td>
                                            <td>{dadoBancario.agencia}</td>
                                            <td>{dadoBancario.conta}</td>
                                            <td>{dadoBancario.tipo.descricao}</td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className={styles.botaoIcone}
                                                    onClick={async () => {
                                                        const novaFormacaoExpProfissional = await modalNovoBanco(
                                                            "Editar Dado Bancário",
                                                            dadoBancario
                                                        );
                                                        novaFormacaoExpProfissional &&
                                                            arrayHelpers.replace(idx, novaFormacaoExpProfissional);
                                                    }}
                                                >
                                                    <EditIcon title="Ícone de editar Dado Bancário" />
                                                </button>
                                                <button
                                                    type="button"
                                                    className={styles.botaoIcone}
                                                    onClick={() => {
                                                        arrayHelpers.remove(idx);
                                                    }}
                                                >
                                                    <DeleteIcon title="Ícone de excluir Dado Bancário" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {values.dadosBancarios.length < 1 && (
                                <button
                                    type="button"
                                    className={styles.botaoIcone}
                                    onClick={async () => {
                                        const novoBanco = await modalNovoBanco("Adicionar Dado Bancário", {
                                            banco: { numero: -1, descricao: "" },
                                            agencia: "",
                                            conta: "",
                                            tipo: { id: -1, descricao: "" },
                                        });
                                        novoBanco && arrayHelpers.push(novoBanco);
                                    }}
                                >
                                    <AddIcon title="Ícone de adicionar novo dado bancário" />
                                </button>
                            )}
                            {meta.touched && meta.error && (
                                <div className={styles.error}>
                                    {Array.isArray(meta.error)
                                        ? meta.error.map((er) => Object.values(er).join(","))
                                        : typeof meta.error === "object"
                                        ? Object.values(meta.error).join(",")
                                        : meta.error}
                                </div>
                            )}
                        </>
                    )}
                </FieldArray>
            }
        />
    );
};
