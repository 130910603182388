import React from "react";
import { ReactComponent as LogoSvg } from "assets/images/omnijus-logo.svg";
import styles from "./omnijus-logo.module.scss";

interface OmnijusLogoProps {
    pulse?: boolean;
}

export const OmnijusLogo = (props: OmnijusLogoProps) => {
    return <LogoSvg className={ styles.omnijusLogo+ (props.pulse ? ` ${styles.pulse}` : "")} />;
};
