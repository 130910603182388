import { IdTipoPrestadorServico } from "services/cadastro-prestadores/models/id-tipo-prestador-servico";

export function getDescricaoTipoPrestador(idTipo: number) {
    switch(idTipo){
        case IdTipoPrestadorServico.DepartamentoJuridico:
            return "Associado a um escritório";
        case IdTipoPrestadorServico.Uberizado:
            return "Associado a um cliente";
        case IdTipoPrestadorServico.Interno:
            return "Comunidade Omnijus";
        default:
            return "Desconhecido";
    }
}