import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { PrestadorFiltroService } from "services/filtros/filtros-prestador-service";
import { Orgao } from "services/filtros/models/orgao";
import { OmnijusSelectField, SelectObject } from "shared/form/fields/omnijus-select-field";
import { Loading } from "shared/loading/loading";

export const OmnijusCampoOrgaoClasse = (props: {
    hideLabel?: boolean;
    name: string;
    label?: string;
    disabled?: boolean;
}) => {
    const [promiseOpcoesOrgao, setPromiseOpcoesOrgao] = useState<Promise<Orgao[] | undefined>>();
    const [, , helpers] = useField({ name: props.name });
    useEffect(() => {
        setPromiseOpcoesOrgao(PrestadorFiltroService.listarEntidadesUsuario());
    }, []);

    return (
        <Loading promise={promiseOpcoesOrgao}>
            {(opcoes) => (
                <OmnijusSelectField
                    isDisabled={props.disabled}
                    name={props.name}
                    label={props.hideLabel ? undefined : props.label || "Órgão"}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label:
                                o.sigla && o.descricao ? `${o.sigla} - ${o.descricao}` : o.sigla || o.descricao || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                    onChange={(o) => {
                        let aux = o as SelectObject;
                        helpers.setValue(parseInt(aux?.value) || "");
                    }}
                />
            )}
        </Loading>
    );
};
