import { useField } from "formik";
import React from "react";
import styles from "./omnijus-file-field.module.scss";
import fieldStyles from "../field.module.scss";
import { ReactComponent as PublishIcon } from "assets/images/publish-icon.svg";
import { ActionFeedback } from "@omnijus/common";

export interface DataUrlFile {
    name: string;
    dataUrl?: Blob;
}

export const OmnijusFileField = (props: {
    name: string;
    label?: string;
    className?: string;
    accept?: string;
    asDataUrl?: boolean;
    maxSizeMB?: number;
    fileNameComponent?: (name: string) => React.ReactNode;
}) => {
    const [field, meta, helpers] = useField({ name: props.name });
    const file = field.value;
    return (
        <div className={`${fieldStyles["field-control"]} ${props.className || ""}`}>
            {props.label ? (
                <label className={fieldStyles["label"]} htmlFor={props.name}>
                    {props.label}
                </label>
            ) : null}
            {(props.asDataUrl && file?.dataUrl?.length > 0) || (!props.asDataUrl && file) ? (
                <div className={styles.fileName}>
                    {props.fileNameComponent ? props.fileNameComponent(file?.name) : file?.name}
                    <button
                        className={styles.clearButton}
                        onClick={() => {
                            helpers.setValue(null);
                        }}
                    >
                        x
                    </button>
                </div>
            ) : (
                <label className={styles.loadFileLabel}>
                    Escolher arquivo
                    <PublishIcon className={styles.loadFileIcon} title="Ícone de carregar imagem" />
                    <input
                        type="file"
                        name={field.name}
                        onBlur={field.onBlur}
                        value={undefined}
                        className={fieldStyles.invisible}
                        accept={props.accept}
                        onChange={(e) => {
                            const files = e.target.files;
                            if (files && files.length > 0) {
                                if (props.maxSizeMB) {
                                    const size = files[0].size / 1024 / 1024;
                                    if (size > props.maxSizeMB) {
                                        ActionFeedback.error({
                                            title: `O tamanho do arquivo excede ${props.maxSizeMB}MB`,
                                        });
                                        return;
                                    }
                                }
                                if (props.asDataUrl) {
                                    const fr = new FileReader();
                                    fr.onload = () => {
                                        helpers.setValue({ name: files[0].name, dataUrl: fr.result });
                                    };
                                    fr.readAsDataURL(files[0]);
                                } else {
                                    helpers.setValue(files[0]);
                                }
                            }
                        }}
                    />
                </label>
            )}
            <div className={fieldStyles["error"]}>{meta.touched && meta.error ? meta.error : ""}</div>
        </div>
    );
};

const GetFileNames = (files: FileList | null) => {
    if (!files) {
        return;
    }

    let fileNames: string[] = [];

    Array.from(files).forEach((file) => {
        fileNames.push(file.name);
    });

    return fileNames.map((file) => (
        <>
            {file}
            <br />
        </>
    ));
};

export const OmnijusFileFieldMultiple = (props: {
    name: string;
    label?: string;
    className?: string;
    accept?: string;
    maxSizeMB?: number;
}) => {
    const [field, meta, helpers] = useField({ name: props.name });
    const files = field.value as FileList | null;
    return (
        <div className={`${fieldStyles["field-control"]} ${props.className || ""}`}>
            {props.label ? (
                <label className={fieldStyles["label"]} htmlFor={props.name}>
                    {props.label}
                </label>
            ) : null}
            {files && files.length > 0 ? (
                <div className={styles.fileName}>
                    {GetFileNames(files)}
                    <button
                        className={styles.clearButton}
                        onClick={() => {
                            helpers.setValue(null);
                        }}
                    >
                        x
                    </button>
                </div>
            ) : (
                <label className={styles.loadFileLabel}>
                    Escolher arquivos
                    <PublishIcon className={styles.loadFileIcon} title="Ícone de carregar imagem" />
                    <input
                        multiple
                        type="file"
                        name={field.name}
                        onBlur={field.onBlur}
                        value={undefined}
                        className={fieldStyles.invisible}
                        accept={props.accept}
                        onChange={(e) => {
                            const files = e.target.files;
                            if (files) {
                                Array.from(files).forEach((file) => {
                                    const size = file.size / 1024 / 1024;

                                    if (props.maxSizeMB && size > props.maxSizeMB) {
                                        ActionFeedback.error({
                                            title: `O tamanho do arquivo ${file.name} excede ${props.maxSizeMB}MB`,
                                        });
                                        return;
                                    }
                                });

                                helpers.setValue(files);
                            }
                        }}
                    />
                </label>
            )}
            <div className={fieldStyles["error"]}>{meta.touched && meta.error ? meta.error : ""}</div>
        </div>
    );
};
