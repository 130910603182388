import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { PrestadorFiltroService } from "services/filtros/filtros-prestador-service";
import { TipoEndereco } from "services/filtros/models/tipo-endereco";
import { OmnijusSelectField, SelectObject } from "shared/form/fields/omnijus-select-field";
import { Loading } from "shared/loading/loading";

export const OmnijusCampoTipoEndereco = (props: { hideLabel?: boolean; name?: string; label?: string }) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<TipoEndereco[] | undefined>>();
    const name = props.name || "tipoEndereco";
    const [field, , helpers] = useField({ name });

    useEffect(() => {
        setPromiseOpcoes(PrestadorFiltroService.listarTiposEndereco());
    }, []);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    name={name}
                    label={props.hideLabel ? undefined : props.label || "Tipo"}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.descricao || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                    isMulti
                    onChange={(o) => {
                        const aux = o as SelectObject[];
                        helpers.setValue(aux?.map((aux) => ({ id: parseInt(aux.value), descricao: aux.label })) || []);
                    }}
                    getValue={() => field.value?.map((v: { id: string }) => String(v.id))}
                />
            )}
        </Loading>
    );
};
