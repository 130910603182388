import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AtividadeService } from "services/atividade/atividade-service";
import { AtividadeBase, ObterAtividade } from "services/atividade/models/obter-atividade";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import styles from "./detalhar-atividade.module.scss";

const CardDetalheAtividade = ({ atividade, revisao }: { atividade: AtividadeBase; revisao?: boolean }) => (
    <OmnijusCard
        header={<h2>{revisao ? "Revisão" : "Atividade"}</h2>}
        body={
            <div className={styles.cardAtividade}>
                {revisao || (
                    <>
                        <p>
                            <span className={styles["prefixo"]}>Atividade:</span>
                            {atividade.descricaoAtividade}
                        </p>

                        <p>
                            <span className={styles["prefixo"]}>Tipo:</span>
                            {atividade.descricaoTipoAtividade}
                        </p>
                        <p>
                            <span className={styles["prefixo"]}>Área:</span>
                            {atividade.areaAtividades?.map((area) => area.descricaoArea).join(", ")}
                        </p>
                        <p>
                            <span className={styles["prefixo"]}>Status:</span>
                            {atividade.estaAtivo ? "Ativa" : "Inativa"}
                        </p>
                    </>
                )}
                <p>
                    <span className={styles["prefixo"]}>Tempo de Execução da Atividade:</span>
                    {`${atividade.quantidadeMinutoMaximoDuracao} min`}
                </p>
                <p>
                    <span className={styles["prefixo"]}>Nº de execuções da Atividade:</span>
                    {atividade.numeroMaximoExecucao}
                </p>
                <p>
                    <span className={styles["prefixo"]}>SLA da Atividade</span>
                    {`${atividade.quantidadeHoraLimiteExecucao}h`}
                </p>
                {atividade.qualificacaoLegalOps && (
                    <>
                        <p>
                            <span className={styles["prefixo"]}>Qualificação Legal Ops</span>
                            {atividade.qualificacaoLegalOps.map((quali) => (
                                <div key={quali.idQualificacao} className={styles.qualificacao}>
                                    {quali.descricacaoQualificacao}
                                </div>
                            ))}
                        </p>
                        <p>
                            <span className={styles["prefixo"]}>Tempo de Experiência</span>
                            {atividade.qualificacaoLegalOps.map((quali) => (
                                <div key={quali.idQualificacao} className={styles.qualificacao}>
                                    {quali.tempoExperiencia}
                                </div>
                            ))}
                        </p>
                    </>
                )}
            </div>
        }
    />
);

export const DetalharAtividade = () => {
    const [promiseDetalheAtividade, setPromiseDetalheAtividade] = useState<Promise<ObterAtividade | undefined>>();
    const { idAtividade } = useParams<{ idAtividade: string }>();
    useEffect(() => {
        setPromiseDetalheAtividade(AtividadeService.obterAtividade(idAtividade));
    }, [idAtividade]);
    return (
        <Loading promise={promiseDetalheAtividade}>
            {(atividade) =>
                atividade ? (
                    <div className={styles.detalharAtividade}>
                        <CardDetalheAtividade atividade={atividade} />
                        {atividade.atividadeRevisao.idAtividade !== "00000000-0000-0000-0000-000000000000" && (
                            <CardDetalheAtividade atividade={atividade.atividadeRevisao} revisao />
                        )}
                    </div>
                ) : (
                    <OmnijusCard body={<p className="text-center">Atividade não encontrada</p>} />
                )
            }
        </Loading>
    );
};
