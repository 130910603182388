import { Field, FieldProps, Form, Formik } from "formik";
import { ActionFeedback } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DetalheEntrevistaGuiada } from "services/entrevista-guiada/detalhe-entrevista-guiada";
import { EntrevistaGuiadaService } from "services/entrevista-guiada/entrevista-guiada-service";
import {
    ParametroAtualizarEntrevistaGuiada,
    ParametroCriarEntrevistaGuiada,
} from "services/entrevista-guiada/parametro-criar-editar-entrevista-guiada";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { ButtonSecondary } from "shared/buttons/button-secondary/button-secondary";
import { OmnijusFiltroAtividade } from "shared/form/custom-fields/omnijus-filtro-atividade";
import { OmnijusFiltroTemplateParceiro } from "shared/form/custom-fields/omnijus-filtro-template-parceiro";
import { OmnijusFiltroTipoTemplate } from "shared/form/custom-fields/omnijus-filtro-tipo-template";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import { OmnijusToggleField } from "shared/form/fields/omnijus-toggle-field";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import * as Yup from "yup";
import styles from "./criar-editar-template.module.scss";
import { TemplateUtilizacaoField } from "./template-utilizacao-field";

export const CriarEditarTemplate = () => {
    const { templateId } = useParams<{ templateId: string }>();
    const [promiseTemplate, setPromiseTemplate] = useState<Promise<DetalheEntrevistaGuiada | undefined>>();
    const history = useHistory();

    useEffect(() => {
        setPromiseTemplate(EntrevistaGuiadaService.obterEntrevistaGuiada(Number(templateId)));
    }, [templateId]);

    return (
        <div className={styles["criar-editar-template"]}>
            <Loading promise={promiseTemplate}>
                {(entrevistaGuiada) => (
                    <Formik
                        validationSchema={Yup.object().shape({
                            nomeEntrevistaGuiada: Yup.string().required("Campo necessário"),
                            descricaoEntrevistaGuiada: Yup.string().required("Campo necessário"),
                            idTipoEntrevistaGuiada: Yup.number()
                                .positive("Campo necessário")
                                .required("Campo necessário"),
                            idTemplateEntrevistaGuiadaParceiro: Yup.string().required("Campo necessário"),
                            entrevistaGuiadaStatusAtivo: Yup.bool(),
                            listaIdsAtividades: Yup.array()
                                .min(1, "É necessário ao menos 1 item")
                                .required("Campo necessário"),
                            listaUtilizacoes: Yup.array()
                                .min(1, "É necessário ao menos 1 item")
                                .required("Campo necessário"),
                        })}
                        onSubmit={async (filtros) => {
                            let promise: any;

                            if (templateId) {
                                promise = EntrevistaGuiadaService.atualizarEntrevistaGuiada(
                                    filtros as ParametroAtualizarEntrevistaGuiada
                                );
                            } else {
                                promise = EntrevistaGuiadaService.criarEntrevistaGuiada(
                                    filtros as ParametroCriarEntrevistaGuiada
                                );
                            }

                            await ActionFeedback.processing({
                                title: "Salvando template",
                                execution: promise,
                            });

                            await ActionFeedback.info({
                                title: "Sucesso",
                                text: "Template savo com sucesso",
                            });

                            history.goBack();
                        }}
                        initialValues={
                            !entrevistaGuiada
                                ? ({
                                      descricaoEntrevistaGuiada: "",
                                      idTemplateEntrevistaGuiadaParceiro: "",
                                      idTipoEntrevistaGuiada: 0,
                                      listaIdsAtividades: [],
                                      listaUtilizacoes: [],
                                      nomeEntrevistaGuiada: "",
                                      entrevistaGuiadaStatusAtivo: true,
                                  } as ParametroCriarEntrevistaGuiada)
                                : ({
                                      idEntrevistaGuiada: entrevistaGuiada.idEntrevistaGuiada,
                                      listaIdsAtividades: entrevistaGuiada.atividadesEntrevistaGuiada.map(
                                          (a) => a.idAtividade
                                      ),
                                      listaUtilizacoes: entrevistaGuiada.utilizacaoEntrevistaGuiada.map((u) => ({
                                          id: u.id,
                                          idArea: u.areaAtividade.idArea,
                                          idAssunto: u.alegacaoMeritoAtividade.id,
                                          idRito: u.ritoAtividade.id,
                                          idTipoAcao: u.classeProcessoAtividade.id,
                                      })),
                                      descricaoEntrevistaGuiada: entrevistaGuiada.descricaoEntrevistaGuiada,
                                      entrevistaGuiadaStatusAtivo: entrevistaGuiada.estaAtivo,
                                      idTemplateEntrevistaGuiadaParceiro:
                                          entrevistaGuiada.identificacaoTemplateParceiro,
                                      idTipoEntrevistaGuiada: entrevistaGuiada.idTipoEntrevistaGuiada,
                                      nomeEntrevistaGuiada: entrevistaGuiada.nomeEntrevistaGuiada,
                                  } as ParametroAtualizarEntrevistaGuiada)
                        }
                    >
                        <Form className={styles["cards-wrapper"]}>
                            <OmnijusCard
                                header={<h2>Template</h2>}
                                body={
                                    <div className={styles["formulario"]}>
                                        <OmnijusTextField
                                            label="Nome do template"
                                            name="nomeEntrevistaGuiada"
                                            placeholder="Digite aqui"
                                        />
                                        <OmnijusTextField
                                            label="Descição do template"
                                            name="descricaoEntrevistaGuiada"
                                            placeholder="Digite aqui"
                                        />
                                        <OmnijusFiltroTipoTemplate
                                            label="Tipo de template"
                                            name="idTipoEntrevistaGuiada"
                                        />
                                        <OmnijusFiltroTemplateParceiro
                                            label="Template parceiro"
                                            name="idTemplateEntrevistaGuiadaParceiro"
                                        />
                                        <OmnijusFiltroAtividade
                                            label="Atividade"
                                            name="listaIdsAtividades"
                                            isMulti={true}
                                        />
                                        <OmnijusToggleField
                                            label="Status do template"
                                            name="entrevistaGuiadaStatusAtivo"
                                            activeLabel="Ativo"
                                        />
                                    </div>
                                }
                            />
                            <OmnijusCard
                                header={<h2>Utilizações</h2>}
                                body={
                                    <Field name="listaUtilizacoes">
                                        {({ field, form, meta }: FieldProps) => (
                                            <div>
                                                <TemplateUtilizacaoField
                                                    values={meta.value || []}
                                                    onChangeValues={(v) => {
                                                        form.setFieldTouched(field.name, true);
                                                        form.setFieldValue(field.name, v);
                                                    }}
                                                />
                                                {meta.touched && meta.error ? (
                                                    <div className={styles["error"]}>{meta.error}</div>
                                                ) : null}
                                            </div>
                                        )}
                                    </Field>
                                }
                            />
                            <div className={styles["acoes"]}>
                                <ButtonSecondary type="button" onClick={() => history.goBack()}>
                                    Cancelar
                                </ButtonSecondary>
                                <ButtonPrimary type="submit">Salvar template</ButtonPrimary>
                            </div>
                        </Form>
                    </Formik>
                )}
            </Loading>
        </div>
    );
};
