import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as TrashIcon } from "assets/images/trash-icon.svg";
import { ErrorMessage, FieldArray, Form, Formik, useFormikContext } from "formik";
import { ActionFeedback } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FiltrosClienteService } from "services/filtros/filtros-cliente-service";
import { FiltroEmpresa } from "services/filtros/models/filtro-empresa";
import { InsercaoPoliticaTemplate } from "services/politica-template/models/insercao-politica-template";
import { PoliticaTemplateDetalhada } from "services/politica-template/models/politica-template-detalhada";
import { ModeloPoliticaTemplateEntrevistaGuiada } from "services/politica-template/models/politica-template-entrevista-guiada";
import { PoliticaTemplateService } from "services/politica-template/politica-template-service";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { ButtonSecondary } from "shared/buttons/button-secondary/button-secondary";
import { OmnijusFiltroArea } from "shared/form/custom-fields/omnijus-filtro-area";
import { OmnijusFiltroAssunto } from "shared/form/custom-fields/omnijus-filtro-assunto";
import { OmnijusFiltroAtividade } from "shared/form/custom-fields/omnijus-filtro-atividade";
import { OmnijusFiltroCliente } from "shared/form/custom-fields/omnijus-filtro-cliente";
import { OmnijusFiltroContrato } from "shared/form/custom-fields/omnijus-filtro-contrato";
import { OmnijusFiltroRito } from "shared/form/custom-fields/omnijus-filtro-rito";
import { OmnijusFiltroTemplate } from "shared/form/custom-fields/omnijus-filtro-template";
import { OmnijusFiltroTipoAcao } from "shared/form/custom-fields/omnijus-filtro-tipo-acao";
import { OmnijusErrorMessage } from "shared/form/error-message/omnijus-error-message";
import { OmnijusToggleField } from "shared/form/fields/omnijus-toggle-field";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import * as Yup from "yup";
import styles from "./criar-editar-config-template.module.scss";

type NomesParametros<T extends object> = { [key in keyof T]: keyof T };
const formNames: NomesParametros<Required<InsercaoPoliticaTemplate>> & { idCliente: string } = {
    idCliente: "idCliente",
    idContrato: "idContrato",
    idArea: "idArea",
    ativo: "ativo",
    idsEmpresas: "idsEmpresas",
    templates: "templates",
};
const templatesFormNames: NomesParametros<Required<ModeloPoliticaTemplateEntrevistaGuiada>> = {
    idAtividade: "idAtividade",
    idRito: "idRito",
    idTipoAcao: "idTipoAcao",
    idAssunto: "idAssunto",
    idEntrevistaGuiada: "idEntrevistaGuiada",
};

const ListaEmpresas = (props: { nomeCampoContrato: string; name: string }) => {
    const [promiseListaEmpresas, setPromiseListaEmpresas] = useState<Promise<FiltroEmpresa[] | undefined>>();
    const formikContext = useFormikContext<any>();
    const idContrato = formikContext.values[props.nomeCampoContrato];
    const value: string[] = formikContext.values[props.name];
    const { setFieldValue } = formikContext;

    useEffect(() => {
        setPromiseListaEmpresas(FiltrosClienteService.listarEmpresas(idContrato));
    }, [idContrato]);

    return (
        <Loading promise={promiseListaEmpresas}>
            {(listaEmpresas) =>
                listaEmpresas && listaEmpresas.length > 0 ? (
                    <table className={styles.tabelaEmpresas}>
                        <colgroup>
                            <col span={1} />
                            <col span={1} />
                        </colgroup>
                        <thead>
                            <tr>
                                <td></td>
                                <td>CNPJ</td>
                                <td>Empresa</td>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                                {listaEmpresas.map((empresa) => (
                                    <tr key={empresa.idContratoClienteServico}>
                                        <td className={styles.checkboxEmpresa}>
                                            <input
                                                type="checkbox"
                                                onChange={(e) => {
                                                    const newValue = value.filter(
                                                        (id) => id !== empresa.idContratoClienteServico
                                                    );
                                                    setFieldValue(
                                                        props.name,
                                                        e.target.checked
                                                            ? [...newValue, empresa.idContratoClienteServico]
                                                            : newValue
                                                    );
                                                }}
                                                checked={value.includes(empresa.idContratoClienteServico)}
                                            />
                                        </td>
                                        <td>{empresa.cnpj}</td>
                                        <td>{empresa.razaoSocial}</td>
                                    </tr>
                                ))}
                            </>
                        </tbody>
                    </table>
                ) : (
                    <div>Selecione um contrato</div>
                )
            }
        </Loading>
    );
};

const initialValuesCriacao: Partial<InsercaoPoliticaTemplate> = {
    [formNames.idCliente]: "",
    [formNames.idContrato]: "",
    [formNames.idArea]: "",
    [formNames.ativo]: true,
    [formNames.templates]: [],
    [formNames.idsEmpresas]: [],
};
const initialValuesTemplate: Partial<ModeloPoliticaTemplateEntrevistaGuiada> = {
    [templatesFormNames.idAtividade]: "",
    [templatesFormNames.idEntrevistaGuiada]: "",
};
const validationSchema = Yup.object().shape({
    [formNames.idCliente]: Yup.string().required("Campo obrigatório"),
    [formNames.idContrato]: Yup.string().required("Campo obrigatório"),
    [formNames.templates]: Yup.array()
        .of(
            Yup.object().shape({
                [templatesFormNames.idAtividade]: Yup.string().required("Campo obrigatório"),
                [templatesFormNames.idEntrevistaGuiada]: Yup.string().required("Campo obrigatório"),
            })
        )
        .min(1, "É obrigatório selecionar o template")
        .required("É obrigatório selecionar o template"),
});

export const CriarEditarConfigTemplateCliente = () => {
    const history = useHistory();
    const { idConfiguracao } = useParams<{ idConfiguracao: string }>();
    const [promisePoliticaTemplate, setPromisePoliticaTemplate] =
        useState<Promise<PoliticaTemplateDetalhada | undefined>>();

    useEffect(() => {
        if (idConfiguracao) {
            setPromisePoliticaTemplate(PoliticaTemplateService.obterPoliticaTemplate(idConfiguracao));
        } else {
            setPromisePoliticaTemplate(Promise.resolve(undefined));
        }
    }, [idConfiguracao]);

    return (
        <div className={styles.configurarTemplateCliente}>
            <Loading promise={promisePoliticaTemplate}>
                {(configuracao) =>
                    idConfiguracao && !configuracao ? (
                        <>Erro</>
                    ) : (
                        <Formik<Partial<InsercaoPoliticaTemplate & { idCliente: string }>>
                            onSubmit={async (values) => {
                                try {
                                    idConfiguracao
                                        ? await ActionFeedback.processing({
                                              title: "Alterando configuração",
                                              execution: PoliticaTemplateService.alterarPoliticaTemplate({
                                                  idPoliticaTemplate: idConfiguracao,
                                                  ativo: values.ativo!,
                                                  templates: values.templates!,
                                                  idArea: values.idArea!,
                                                  idsEmpresas: values.idsEmpresas,
                                              }),
                                          })
                                        : await ActionFeedback.processing({
                                              title: "Adicionando configuração",
                                              execution: PoliticaTemplateService.criarPoliticaTemplate({
                                                  ativo: values.ativo!,
                                                  templates: values.templates!,
                                                  idArea: values.idArea!,
                                                  idContrato: values.idContrato!,
                                                  idsEmpresas: values.idsEmpresas,
                                              }),
                                          });
                                    await ActionFeedback.info({ title: "Configuração salva com sucesso!" });
                                    history.goBack();
                                } catch (e) {
                                    ActionFeedback.error({ titleText: "Erro salvando configuração" });
                                }
                            }}
                            initialValues={
                                configuracao
                                    ? {
                                          ativo: configuracao.estaAtiva,
                                          idArea: configuracao.idAreaAtuacaoOrgao,
                                          idContrato: configuracao.idContrato,
                                          idCliente: configuracao.idCliente,
                                          templates: configuracao.listaAtividades.map((config) => ({
                                              idAtividade: config.idAtividade,
                                              idEntrevistaGuiada: config.idTemplate,
                                              idAssunto: config.idAssunto,
                                              idRito: config.idRito,
                                              idTipoAcao: config.idTipoAcao,
                                          })),
                                          idsEmpresas: configuracao.listaEmpresas.map((empresa) => empresa.idEmpresa),
                                      }
                                    : initialValuesCriacao
                            }
                            validationSchema={validationSchema}
                        >
                            {({ values }) => (
                                <Form className={styles.cards}>
                                    <OmnijusCard
                                        header={<h3>Adicionar Configuração de Template para o Cliente</h3>}
                                        body={
                                            <div className={styles.formulario}>
                                                <OmnijusFiltroCliente
                                                    label="Cliente"
                                                    name={formNames.idCliente}
                                                    isDisabled={idConfiguracao ? true : false}
                                                />
                                                <OmnijusFiltroContrato
                                                    label="Contrato"
                                                    name={formNames.idContrato}
                                                    reloadOnChangeName={formNames.idCliente}
                                                    isDisabled={idConfiguracao ? true : false}
                                                />
                                                <OmnijusFiltroArea label="Área" name={formNames.idArea} />
                                                <OmnijusToggleField
                                                    label="Status"
                                                    activeLabel="Ativo"
                                                    name={formNames.ativo}
                                                />
                                            </div>
                                        }
                                    />
                                    <OmnijusCard
                                        header={<h3>Empresas</h3>}
                                        body={
                                            <ListaEmpresas
                                                name={formNames.idsEmpresas}
                                                nomeCampoContrato={formNames.idContrato}
                                            />
                                        }
                                    />
                                    <OmnijusCard
                                        header={<h3>Templates</h3>}
                                        body={
                                            <div>
                                                <FieldArray name="templates">
                                                    {(arrayHelpers) => (
                                                        <>
                                                            {values.templates?.map((template, idx) => (
                                                                <div
                                                                    className={styles.formularioTemplate}
                                                                    key={`${idx}`}
                                                                >
                                                                    <OmnijusFiltroAtividade
                                                                        label="Atividade"
                                                                        name={`templates[${idx}].${templatesFormNames.idAtividade}`}
                                                                        idArea={
                                                                            parseInt(
                                                                                values[formNames.idArea] as string
                                                                            ) || undefined
                                                                        }
                                                                    />
                                                                    <OmnijusFiltroRito
                                                                        label="Rito"
                                                                        name={`templates[${idx}].${templatesFormNames.idRito}`}
                                                                        reloadOnChangeName={formNames.idArea}
                                                                    />
                                                                    <OmnijusFiltroTipoAcao
                                                                        reloadOnChangeName={formNames.idArea}
                                                                        label="Tipo de Ação"
                                                                        name={`templates[${idx}].${templatesFormNames.idTipoAcao}`}
                                                                    />
                                                                    <OmnijusFiltroAssunto
                                                                        label="Assunto"
                                                                        name={`templates[${idx}].${templatesFormNames.idAssunto}`}
                                                                        reloadOnChange={(values) =>
                                                                            values.templates[idx][
                                                                                templatesFormNames.idRito
                                                                            ]
                                                                        }
                                                                    />
                                                                    <OmnijusFiltroTemplate
                                                                        label="Template"
                                                                        name={`templates[${idx}].${templatesFormNames.idEntrevistaGuiada}`}
                                                                    />
                                                                    <div className={styles.iconeExcluir}>
                                                                        <TrashIcon
                                                                            onClick={() => arrayHelpers.remove(idx)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className={styles.iconeAdicionar}>
                                                                <AddIcon
                                                                    onClick={() =>
                                                                        arrayHelpers.push(initialValuesTemplate)
                                                                    }
                                                                />
                                                            </div>
                                                            <ErrorMessage
                                                                name="templates"
                                                                render={(message) => (
                                                                    <OmnijusErrorMessage errorMessage={message} />
                                                                )}
                                                            />
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </div>
                                        }
                                    />
                                    <div className={styles.linhaSubmit}>
                                        <ButtonPrimary type="submit">Salvar</ButtonPrimary>
                                        <ButtonSecondary onClick={() => history.goBack()}>Cancelar</ButtonSecondary>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )
                }
            </Loading>
        </div>
    );
};
