import CPF from "cpf";
import { Form, Formik } from "formik";
import { ActionFeedback } from "@omnijus/common";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams } from "react-router-dom";
import {
    atualizarArquivoPrestador,
    atualizarPrestadorServicoPF,
    buscarFotoPrestador,
    criarPrestadorServicoPF,
    detalharPrestadorServicoPF,
    downloadCurriculo
} from "services/cadastro-prestadores/cadastro-prestadores-service";
import { TipoArquivoAtualizarPrestadorCommand } from "services/cadastro-prestadores/models/atualizar-arquivo-prestador-command";
import {
    AtualizarPrestadorServicoUsuarioCommand,
    AtualizarPrestadorServicoUsuarioFormacaoAcademica
} from "services/cadastro-prestadores/models/atualizar-prestador-servico-command";
import { CadastroPrestadorPessoaFisica } from "services/cadastro-prestadores/models/cadastro-prestador-pessoa-fisica";
import { IdTipoPrestadorServico } from "services/cadastro-prestadores/models/id-tipo-prestador-servico";
import { PrestadorServicoUsuarioEndereco } from "services/cadastro-prestadores/models/prestador-servico-usuario-endereco";
import { PrestadorServicoUsuarioExperienciaProfissional } from "services/cadastro-prestadores/models/prestador-servico-usuario-experiencia-profissional";
import {
    IdTipoMeioComunicacao,
    PrestadorServicoUsuarioMeioComunicacao
} from "services/cadastro-prestadores/models/prestador-servico-usuario-meio-comunicacao";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { DataUrlFile, OmnijusFileField } from "shared/form/fields/omnijus-file-field";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import { array as YupArray, date as YupDate, number as YupNumber, object as YupObject, string as YupString } from "yup";
import styles from "../cadastro-prestadores.module.scss";
import { DadoBancario, dadoBancarioValidation, DadosBancarios } from "../forms/dados-bancarios";
import { Endereco, Enderecos, enderecoValidation } from "../forms/enderecos";
import {
    ExperienciaProfissional,
    experienciaProfissionalValidation,
    ExperienciasProfissionais
} from "../forms/experiencias-profissionais";
import { FormacaoAcademica, formacaoAcademicaValidation, FormacoesAcademicas } from "../forms/formacoes-academicas";
import { CamposIdentificacao, Identificacao } from "../forms/identificacao";
import { MeioDeComunicacao, meioDeComunicacaoValidation, MeiosComunicacao } from "../forms/meios-comunicacao";

type TipoValores = CamposIdentificacao & {
    enderecos: Endereco[];
    meiosDeComunicacao: MeioDeComunicacao[];
    formacoesAcademicas: FormacaoAcademica[];
    dadosBancarios: DadoBancario[];
    experienciasProfissionais: ExperienciaProfissional[];
    curriculo?: DataUrlFile & { id?: string };
    linkedIn?: string;
};

const valoresIniciais: TipoValores = {
    associado: "comunidade",
    nome: "",
    username: "",
    idEntidadeClasse: -1,
    numeroRegistroEntidadeClasse: "",
    dataRegistroEntidadeClasse: undefined,
    cpf: "",
    rgNumero: "",
    rgOrgaoExpeditor: "",
    rgDataExpedicao: undefined,
    idQualificacao: -1,
    idEscritorio: "",
    idCliente: "",
    foto: { dataUrl: undefined },
    rgUf: "",
    enderecos: new Array<Endereco>(),
    meiosDeComunicacao: new Array<MeioDeComunicacao>(),
    formacoesAcademicas: new Array<FormacaoAcademica>(),
    dadosBancarios: new Array<DadoBancario>(),
    experienciasProfissionais: new Array<ExperienciaProfissional>(),
    curriculo: undefined,
};

type CadastroPrestadorPessoaFisicaFoto = CadastroPrestadorPessoaFisica & { fotoDataUrl?: Blob };

const valoresIniciaisEdicaoPrestador = (prestador: CadastroPrestadorPessoaFisicaFoto): TipoValores => ({
    associado: prestador.idCliente
        ? "associadoCliente"
        : prestador.idPrestadorEscritorio
            ? "associadoEscritorio"
            : "comunidade",
    nome: prestador.nome || "",
    username: prestador.usuario || "",
    idEntidadeClasse: prestador.idOrgao,
    numeroRegistroEntidadeClasse: prestador.registroProfissional || "",
    dataRegistroEntidadeClasse: prestador.dataExpedicaoRegistroProfissional || undefined,
    cpf: prestador.cpf || "",
    rgNumero: prestador.rg || "",
    rgOrgaoExpeditor: prestador.orgaoExpeditor || "",
    rgDataExpedicao: prestador.dataExpedicaoRG || undefined,
    idQualificacao: prestador.idPerfil || -1,
    idEscritorio: prestador.idPrestadorEscritorio || "",
    idCliente: prestador.idCliente || "",
    foto: prestador.foto?.idArquivo
        ? { dataUrl: prestador.fotoDataUrl, id: prestador.foto.idArquivo }
        : { dataUrl: undefined },
    rgUf: prestador.uf || "",
    enderecos: prestador.enderecos.map((end) => ({
        id: end.id,
        bairro: end.bairro || "",
        cep: end.cep || "",
        localidade: end.cidade || "",
        complemento: end.complemento || "",
        logradouro: end.logradouro || "",
        numero: end.numero || "",
        uf: end.uf || "",
        tipos: end.tipos?.map((t) => ({ id: t.idTipoEndereco, descricao: t.descricao })) || [],
    })),
    meiosDeComunicacao:
        prestador.meiosComunicacao.map((meio) => ({
            id: meio.idMeioComunicacao,
            contato: meio.valor || "",
            preferencial: meio.preferencial,
            tipo: { id: meio.idTipo, descricao: meio.descricaoTipo },
        })) || [],
    formacoesAcademicas: prestador.formacoes.map((formacao) => ({
        id: formacao.idFormacaoAcademia,
        nomeCurso: formacao.curso || "",
        nomeInstituicao: formacao.instituicao || "",
        certificado: { dataUrl: undefined, name: formacao.certificado?.nomeArquivo || "", id: formacao.certificado?.idArquivo },
        situacao: { id: formacao.idSituacaoCurso, descricao: formacao.descricaoSituacaoCurso },
        tipo: { id: formacao.idTipo, descricao: formacao.descricaoTipo },
        tipoDePeriodo: { id: formacao.idTipoPeriodo, descricao: formacao.descricaoTipoPeriodo },
        periodosCursados: formacao.periodosCursados,
        dataInicio: formacao.inicio,
        dataTermino: formacao.dataTermino,
    })),
    dadosBancarios: prestador.numeroBanco
        ? [
            {
                agencia: prestador.agencia || "",
                banco: { numero: prestador.numeroBanco, descricao: "" },
                conta: prestador.conta || "",
                tipo: { id: prestador.idTipoConta || -1, descricao: null },
            },
        ]
        : [],
    experienciasProfissionais: prestador.experiencias.map((experiencia) => ({
        descricaoAtividades: experiencia.descricao || "",
        nomeCargo: experiencia.cargo || "",
        nomeEmpresa: experiencia.empresa || "",
        dataInicio: experiencia.inicio,
        dataTermino: experiencia.termino || undefined,
    })),
    curriculo: prestador.curriculo?.idArquivo
        ? { dataUrl: undefined, name: "curriculo.pdf", id: prestador.curriculo.idArquivo }
        : { dataUrl: undefined, name: "" },
});

const validationSchema = YupObject().shape({
    nome: YupString()
        .trim()
        .matches(/^[A-zÀ-ÿ' ]*$/, "Nome inválido")
        .required("Informe o nome"),
    cpf: YupString()
        .required("Informe o CPF")
        .test("cpf", "CPF inválido", (cpf) => (cpf ? CPF.isValid(cpf) : false)),
    rgNumero: YupString().required("Informe o número do RG"),
    dadosBancarios: YupArray().of(dadoBancarioValidation),
    numeroRegistroEntidadeClasse: YupString().required("Informe o número de registro"),
    idEntidadeClasse: YupNumber().min(1, "Informe o órgão de classe").required("Informe o órgão de classe"),
    dataRegistroEntidadeClasse: YupDate()
        .max(new Date(), "A data não pode ser futura")
        .typeError("Informe a data de expedição")
        .required("Informe a data de expedição"),
    rgDataExpedicao: YupDate()
        .max(new Date(), "A data não pode ser futura")
        .typeError("Informe a data de expedição")
        .required("Informe a data de expedição"),
    rgOrgaoExpeditor: YupString().required("Informe o órgão expeditor"),
    rgUf: YupString().required("Informe a UF do RG"),
    // email: YupString().email("Email inválido").required("Informe o email"),
    username: YupString()
        .matches(/^[A-z0-9_]{2}[A-z0-9_]*$/, "Username inválido"),
    idQualificacao: YupNumber().min(1, "Informe o perfil").required("Informe o perfil"),
    idCliente: YupString().when("associado", {
        is: "associadoCliente",
        then: YupString().required("Selecione o cliente associado"),
    }),
    idEscritorio: YupString().when("associado", {
        is: "associadoEscritorio",
        then: YupString().required("Selecione o escritório associado"),
    }),
    associado: YupString().required("Selecione uma opção"),
    enderecos: YupArray().of(enderecoValidation).min(1, "Informe pelo menos um endereço"),
    meiosDeComunicacao: YupArray()
        .of(meioDeComunicacaoValidation)
        .min(1, "Informe pelo menos um meio de comunicação")
        .test("preferencial", "Deve ser selecionado um meio de comunicação preferencial", (meios) =>
            meios
                ? meios.filter((meioComunicacao) => (meioComunicacao as MeioDeComunicacao).preferencial).length > 0
                : false
        )
        .test("preferencial", "Deve ser selecionado apenas um meio de comunicação preferencial", (meios) =>
            meios
                ? meios.filter((meioComunicacao) => (meioComunicacao as MeioDeComunicacao).preferencial).length <= 1
                : false
        )
        .test("email", "Deve ser informado um email", (meios) =>
            meios
                ? meios.filter(
                    (meioComunicacao) =>
                        (meioComunicacao as MeioDeComunicacao).tipo.id === IdTipoMeioComunicacao.Email
                ).length > 0
                : false
        )
        .test("email", "Deve ser informado apenas um email", (meios) =>
            meios
                ? meios.filter(
                    (meioComunicacao) =>
                        (meioComunicacao as MeioDeComunicacao).tipo.id === IdTipoMeioComunicacao.Email
                ).length <= 1
                : false
        ),
    formacoesAcademicas: YupArray().of(formacaoAcademicaValidation).min(1, "Informe pelo menos uma formação acadêmica"),
    experienciasProfissionais: YupArray()
        .of(experienciaProfissionalValidation)
        .min(1, "Informe pelo menos uma experiência profissional"),
    linkedIn: YupString().url("A página LinkedIn deve ser um link inválido"),
});

export const AdicionarCadastroPrestador = () => {
    const { id } = useParams<{ id: string }>();
    const [detalhePrestador, setDetalhePrestador] = useState<Promise<CadastroPrestadorPessoaFisicaFoto | undefined>>();

    useEffect(() => {
        if (id) {
            setDetalhePrestador(
                detalharPrestadorServicoPF(id)
                    .then((p) => {
                        if (p?.foto?.idArquivo) {
                            return buscarFotoPrestador(p.foto.idArquivo)
                                .then((fotoBlob) => {
                                    if (fotoBlob) {
                                        return { ...p, fotoDataUrl: fotoBlob } as CadastroPrestadorPessoaFisicaFoto;
                                    }
                                    return p;
                                });
                        }
                        return p;
                    })
            );
        } else {
            setDetalhePrestador(Promise.resolve(undefined));
        }
    }, [id]);

    const history = useHistory();

    return (
        <Loading promise={detalhePrestador}>
            {(prestador) =>
                !id || prestador ? (
                    <Formik
                        initialValues={id && prestador ? valoresIniciaisEdicaoPrestador(prestador) : valoresIniciais}
                        onSubmit={async (values) => {
                            const contaBancaria = values.dadosBancarios[0];
                            const enderecos: PrestadorServicoUsuarioEndereco[] = values.enderecos.map((end) => ({
                                id: end.id,
                                bairro: end.bairro,
                                cep: end.cep,
                                logradouro: end.logradouro,
                                uf: end.uf,
                                complemento: end.complemento,
                                localidade: end.localidade,
                                numero: parseInt(end.numero),
                                listaIdTipo: end.tipos.map((t) => t.id),
                            }));
                            const experienciasProfissionais: PrestadorServicoUsuarioExperienciaProfissional[] = values.experienciasProfissionais.map(
                                (exp) => ({
                                    ...exp,
                                    dataInicio: exp.dataInicio!,
                                })
                            );
                            const formacoesAcademicas: AtualizarPrestadorServicoUsuarioFormacaoAcademica[] = [];
                            for (const formacao of values.formacoesAcademicas) {
                                let arquivoAtualizado = undefined;

                                if (formacao.certificado?.dataUrl) {
                                    arquivoAtualizado = await ActionFeedback.processing({
                                        title: "Atualizando certificado de formação acadêmica",
                                        execution: atualizarArquivoPrestador({
                                            arquivo: formacao.certificado.dataUrl,
                                            tipoArquivo: TipoArquivoAtualizarPrestadorCommand.CertificadoAcademico
                                        })
                                    });
                                }

                                formacoesAcademicas.push({
                                    id: formacao.id,
                                    idTipo: formacao.tipo.id,
                                    idSituacao: formacao.situacao.id,
                                    idTipoPeriodoFormacao: formacao.tipoDePeriodo?.id && formacao.tipoDePeriodo?.id > 0 ? formacao.tipoDePeriodo.id : undefined,
                                    nomeCurso: formacao.nomeCurso,
                                    nomeInstituicao: formacao.nomeInstituicao,
                                    dataInicio: formacao.dataInicio!,
                                    dataTermino: formacao.dataTermino,
                                    quantidadePeriodosCursados: formacao.periodosCursados,
                                    certificado: arquivoAtualizado?.path
                                });
                            }
                            const meiosDeComunicacao: PrestadorServicoUsuarioMeioComunicacao[] = values.meiosDeComunicacao.map(
                                (meioComunicacao) => ({
                                    id: meioComunicacao.id,
                                    contato: meioComunicacao.contato,
                                    idTipo: meioComunicacao.tipo.id,
                                    principal: meioComunicacao.preferencial,
                                })
                            );
                            const parametrosCriarPrestador: AtualizarPrestadorServicoUsuarioCommand = {
                                id: id,
                                nome: values.nome,
                                idTipo:
                                    values.associado === "associadoCliente"
                                        ? IdTipoPrestadorServico.Uberizado
                                        : values.associado === "associadoEscritorio"
                                            ? IdTipoPrestadorServico.DepartamentoJuridico
                                            : IdTipoPrestadorServico.Interno,
                                dataRegistroEntidadeClasse: values.dataRegistroEntidadeClasse!,
                                idEntidadeClasse: values.idEntidadeClasse,
                                numeroRegistroEntidadeClasse: values.numeroRegistroEntidadeClasse,
                                idQualificacao: [values.idQualificacao],
                                rgNumero: values.rgNumero,
                                rgOrgaoExpeditor: values.rgOrgaoExpeditor,
                                rgDataExpedicao: values.rgDataExpedicao!,
                                rgUf: values.rgUf,
                                cpf: values.cpf,
                                username: values.username,
                                idCliente: values.associado === "associadoCliente" ? values.idCliente : undefined,
                                idEscritorio:
                                    values.associado === "associadoEscritorio" ? values.idEscritorio : undefined,
                                listaEndereco: enderecos,
                                listaExperienciaProfissional: experienciasProfissionais,
                                listaFormacaoAcademica: formacoesAcademicas,
                                listaMeioComunicacao: meiosDeComunicacao,
                                linkedIn: values.linkedIn,
                                contaBancaria: contaBancaria ? {
                                    idBanco: contaBancaria.banco.numero,
                                    agencia: contaBancaria.agencia,
                                    conta: contaBancaria.conta,
                                    idTipo: contaBancaria.tipo.id,
                                } : undefined,
                            };

                            if (values.foto?.dataUrl) {
                                let arquivoAtualizado = await ActionFeedback.processing({
                                    title: "Atualizando foto",
                                    execution: atualizarArquivoPrestador({
                                        arquivo: values.foto.dataUrl,
                                        tipoArquivo: TipoArquivoAtualizarPrestadorCommand.Foto
                                    })
                                });

                                parametrosCriarPrestador.foto = arquivoAtualizado?.path;
                            }

                            if (values.curriculo?.dataUrl) {
                                let arquivoAtualizado = await ActionFeedback.processing({
                                    title: "Atualizando currículo",
                                    execution: atualizarArquivoPrestador({
                                        arquivo: values.curriculo.dataUrl,
                                        tipoArquivo: TipoArquivoAtualizarPrestadorCommand.Curriculo
                                    })
                                });

                                parametrosCriarPrestador.curriculo = arquivoAtualizado?.path;
                            }

                            await ActionFeedback.processing({
                                title: id ? "Atualizando prestador" : "Adicionando prestador",
                                execution: id ? atualizarPrestadorServicoPF(parametrosCriarPrestador) : criarPrestadorServicoPF(parametrosCriarPrestador),
                            });

                            await ActionFeedback.info({
                                title: id ? "Prestador atualizado" : "Prestador adicionado",
                            });

                            history.goBack();
                        }}
                        validationSchema={validationSchema}
                    >
                        {({ values }) => (
                            <Form className={styles.adicionarCadastroPrestador}>
                                <h2>{id ? "Editar" : "Novo"} Prestador - Pessoa Física</h2>
                                <Identificacao values={values} edicao={id !== undefined} />
                                <Enderecos values={values} />
                                <MeiosComunicacao values={values} />
                                <FormacoesAcademicas values={values} />
                                <ExperienciasProfissionais values={values} />
                                <OmnijusFileField
                                    name="curriculo"
                                    label="Currículo"
                                    accept="application/pdf"
                                    maxSizeMB={5}
                                    fileNameComponent={(name) => {
                                        return (
                                            <button
                                                className={styles.botaoDownload}
                                                type="button"
                                                onClick={() => {
                                                    if (values.curriculo?.id) {
                                                        downloadCurriculo(values.curriculo.id, "curriculo.pdf");
                                                    }
                                                }}
                                            >
                                                {name}
                                            </button>
                                        );
                                    }}
                                />

                                <OmnijusCard
                                    header={<h3>LinkedIn</h3>}
                                    body={<OmnijusTextField label="Página Linkedin" name="linkedIn" />}
                                />
                                <DadosBancarios values={values} />
                                <div className={styles.linhaSubmit}>
                                    <ButtonPrimary type="submit">Salvar</ButtonPrimary>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <>Erro carregando prestador</>
                )
            }
        </Loading>
    );
};
