import Cleave from "cleave.js/react";
import { Field, FieldProps } from "formik";
import styles from "../field.module.scss";
import React from "react";

export const OmnijusCampoNumeroProcesso = (props: {
    name: string;
    placeholder?: string;
    label?: string;
    className?: string;
    disabled?: boolean;
    onChange?: (e: React.ChangeEvent<any>) => void;
}) => {
    return (
        <div className={`${styles["field-control"]} ${props.className || ""}`}>
            {props.label ? (
                <label className={styles["label"]} htmlFor={props.name}>
                    {props.label}
                </label>
            ) : null}
            <Field name={props.name} id={props.name}>
                {({ field, meta }: FieldProps) => (
                    <>
                        <Cleave
                            disabled={props.disabled}
                            options={{
                                blocks: [7, 2, 4, 1, 2, 4],
                                delimiters: ["-", ".", ".", ".", "."],
                                numericOnly: true,
                            }}
                            className={styles["input"]}
                            id={props.name}
                            {...field}
                            onChange={(e) => {
                                props.onChange && props.onChange(e);
                                field.onChange(e);
                            }}
                        />
                        <p className={styles["error"]}>{meta.touched && meta.error ? meta.error : ""}</p>
                    </>
                )}
            </Field>
        </div>
    );
};
