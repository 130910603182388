import { ActionFeedback, PromiseState, RequestApi } from "@omnijus/common";
import { ReactComponent as ManageSearchIcon } from "assets/images/manage_search_icon.svg";
import classNames from "classnames";
import { Format } from "lib/format";
import { dateToDDMMYYYY, getTimeHHmm } from "lib/time";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { useHistory, useLocation } from "react-router-dom";
import { Cadastro } from "services/onboarding/models/cadastro";
import { IdStatusCadastro } from "services/onboarding/models/id-status-cadastro";
import {
    ParametrosPesquisarCadastro,
    parseParametrosPesquisarCadastro,
} from "services/onboarding/models/parametros-pesquisa-cadastro";
import { getPesquisaCadastro, postNotificacaoCadastro } from "services/onboarding/onboarding-service";
import { CardFiltros } from "shared/form/card-filtros/card-filtros";
import { FiltrosAplicados } from "shared/form/filtros-aplicados/filtros-aplicados";
import { ValoresComboContext, ValoresCombos } from "shared/form/valores-combo-context";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import Swal from "sweetalert2";
import { exibirModalEnviarNotificacoes } from "../enviar-notificacoes/enviar-notificacoes";
import { downloadExportar } from "./download-exportar";
import { FiltrosListagemCadastros } from "./filtros-listagem-cadastros";
import styles from "./listagem-cadastros.module.scss";

const columns: IDataTableColumn<Cadastro>[] = [
    {
        name: "Nome",
        selector: (c) => c.nome,
        sortable: true,
    },
    {
        name: "CPF",
        selector: (c) => c.cpf,
        maxWidth: "10rem",
    },
    {
        name: "Email",
        selector: (c) => c.email,
    },
    {
        name: "Telefone",
        selector: (c) => c.telefone,
        maxWidth: "10rem",
        format: (c) => c.telefone && Format.formatCellphoneNumber(c.telefone),
    },
    {
        name: "Status",
        selector: (c) => c.status,
        format: (c) => (
            <div>
                <div>{c.status}</div>
                {c.idStatus === IdStatusCadastro.EmAnalise && c.nomeUsuarioAnalise && (
                    <div className={styles.nomeUsuarioAnalise}>({c.nomeUsuarioAnalise})</div>
                )}
            </div>
        ),
        sortable: true,
        maxWidth: "9rem",
    },
    {
        name: "Etapa",
        selector: (c) => c.etapa,
        sortable: true,
        maxWidth: "12rem",
    },
    {
        name: "Ult.Alteração",
        selector: (c) => c.dataUltimaAlteracao,
        format: (c) =>
            c.dataUltimaAlteracao ? (
                <>
                    <span>{dateToDDMMYYYY(c.dataUltimaAlteracao)}</span>
                    <span className={styles.horaAlteracao}>{getTimeHHmm(c.dataUltimaAlteracao)}</span>
                </>
            ) : (
                ""
            ),
        sortable: true,
        maxWidth: "11rem",
    },
    {
        name: "",
        cell: (c) => (
            <BotaoAnalisarCadastro
                disabled={
                    c.idStatus !== IdStatusCadastro.EmAnalise ||
                    (c.nomeUsuarioAnalise !== null && !c.ehUsuarioResponsavel)
                }
                idCadastro={c.id}
            />
        ),
        maxWidth: "4rem",
        minWidth: "4rem",
    },
];

const BotaoAnalisarCadastro = (props: { disabled?: boolean; idCadastro: string }) => {
    const history = useHistory();
    return (
        <button
            className={classNames(styles.botaoAnalisar, { [styles.disabled]: props.disabled })}
            onClick={() => {
                !props.disabled &&
                    history.push({
                        pathname: `/onboarding/cadastro/inconsistencias/${props.idCadastro}`,
                    });
            }}
            title="Analisar cadastro"
        >
            <ManageSearchIcon title="Analisar cadastro" />
        </button>
    );
};

export const ListagemCadastros = () => {
    const query = useLocation().search;
    const history = useHistory();
    const [promiseListaCadastros, setPromiseListaCadastros] = useState<Promise<Cadastro[] | undefined>>();
    const parametrosBusca = useMemo(() => parseParametrosPesquisarCadastro(query), [query]);
    const [infoFiltros, setInfoFiltros] = useState<ValoresCombos>({
        IdStatus: { label: "Status" },
        IdEtapa: { label: "Etapa" },
        DataInicio: { label: "Data inicial de cadastro", format: dateToDDMMYYYY },
        DataFim: { label: "Data final de cadastro", format: dateToDDMMYYYY },
    });

    const buscar = useCallback(
        (filtros: ParametrosPesquisarCadastro) => {
            // TODO (inserir método format:string=>TipoParametro no filtro select)
            const search = RequestApi.objectToQueryString(filtros);
            history.push({
                search,
            });
        },
        [history]
    );

    useEffect(() => {
        if (!!parametrosBusca.IdEtapa && String(parametrosBusca.IdStatus) !== String(IdStatusCadastro.EmCadastro)) {
            buscar({ ...parametrosBusca, IdStatus: IdStatusCadastro.EmCadastro });
        }
        parametrosBusca &&
            setPromiseListaCadastros(
                getPesquisaCadastro({ ...parametrosBusca, Telefone: parametrosBusca.Telefone?.replace(/\D/g, "") })
            );
    }, [parametrosBusca, buscar]);

    return (
        <div className={styles.listagemCadastros}>
            <ValoresComboContext.Provider value={{ valoresCombos: infoFiltros, setValoresCombos: setInfoFiltros }}>
                <CardFiltros
                    onBuscar={async (filtros) => {
                        if (!promiseListaCadastros || !(await PromiseState.isPending(promiseListaCadastros))) {
                            buscar(filtros);
                        } else {
                            Swal.fire({
                                icon: "warning",
                                text: "Uma busca já está sendo realizada no momento! Aguarde...",
                            });
                        }
                    }}
                    initialValues={parametrosBusca}
                >
                    <FiltrosListagemCadastros />
                </CardFiltros>
                <div className={styles.barraAcao}>
                    <div className={styles.filtrosAplicados}>
                        {Object.entries(parametrosBusca).length > 0 && (
                            <FiltrosAplicados
                                parametros={parametrosBusca}
                                onRemove={(nome) =>
                                    buscar({
                                        ...parametrosBusca,
                                        [nome]: undefined,
                                        IdEtapa: nome === "IdStatus" ? undefined : parametrosBusca.IdEtapa,
                                    })
                                }
                            />
                        )}
                    </div>
                    <div className={styles.botoesAcao}>
                        <button
                            onClick={async () => {
                                if (promiseListaCadastros) {
                                    const cadastros = await ActionFeedback.processing({
                                        title: "Buscando cadastros",
                                        execution: promiseListaCadastros,
                                    });
                                    if (cadastros) {
                                        downloadExportar(cadastros, "csv");
                                    }
                                }
                            }}
                        >
                            Exportar CSV
                        </button>
                        <button
                            onClick={() =>
                                exibirModalEnviarNotificacoes(parametrosBusca, infoFiltros, postNotificacaoCadastro)
                            }
                        >
                            Enviar Notificações
                        </button>
                    </div>
                </div>
            </ValoresComboContext.Provider>
            <OmnijusCard
                className={styles.tabelaListagem}
                body={
                    <Loading promise={promiseListaCadastros}>
                        {(cadastros) =>
                            cadastros ? (
                                <DataTable
                                    noHeader={true}
                                    pagination={true}
                                    paginationPerPage={20}
                                    noDataComponent={<p>Nenhum registro encontrado!</p>}
                                    columns={columns}
                                    data={cadastros}
                                    conditionalRowStyles={[
                                        {
                                            when: (c) =>
                                                (c.idStatus === IdStatusCadastro.EmAnalise && c.ehUsuarioResponsavel) ||
                                                false,
                                            style: { backgroundColor: "#ffaf5c33" },
                                        },
                                        {
                                            when: (c) =>
                                                (c.idStatus === IdStatusCadastro.EmAnalise &&
                                                    c.nomeUsuarioAnalise &&
                                                    !c.ehUsuarioResponsavel) ||
                                                false,
                                            style: { backgroundColor: "#3939391A" },
                                        },
                                    ]}
                                    pointerOnHover={true}
                                    onRowClicked={(c) => {
                                        history.push(`/onboarding/cadastro/${c.id}`);
                                    }}
                                    customStyles={{
                                        rows: {
                                            style: {
                                                minHeight: "80px",
                                                padding: "0 20px",
                                            },
                                        },
                                        head: {
                                            style: {
                                                padding: "0 20px",
                                            },
                                        },
                                    }}
                                />
                            ) : (
                                <></>
                            )
                        }
                    </Loading>
                }
            />
        </div>
    );
};
