import React, { useState, useEffect } from "react";
import { DashboardTotaisResponse } from "services/onboarding/models/dashboard-totais-response";
import { getDashboardTotais } from "services/onboarding/onboarding-service";
import { Card, Color } from "../card/card";
import { Loading } from "shared/loading/loading";
import { IdStatusConvite } from "services/onboarding/models/id-status-convite";
import { IdStatusFilaLead } from "services/onboarding/models/id-status-fila-lead";

export const CardsTotais = () => {
    const [promiseTotais, setPromiseTotais] = useState<Promise<DashboardTotaisResponse | undefined>>();

    useEffect(() => {
        setPromiseTotais(getDashboardTotais());
    }, []);

    return (
        <Loading promise={promiseTotais}>
            {(totais) =>
                totais ? (
                    <>
                        <Card
                            color={Color.Orange}
                            quantity={totais.convitesPendentes}
                            topLeft
                            topText="Convites pendentes"
                            big
                            linkTo={{
                                pathname: "/onboarding/convites",
                                search: new URLSearchParams({
                                    Status: IdStatusConvite.Pendente.toString(),
                                }).toString(),
                            }}
                        />
                        <Card
                            color={Color.Blue}
                            quantity={totais.leads}
                            topLeft
                            topText="Fila de leads"
                            big
                            linkTo={{
                                pathname: "/onboarding/leads",
                                search: new URLSearchParams({
                                    Status: IdStatusFilaLead.SemConvite.toString(),
                                }).toString(),
                            }}
                        />
                        <Card
                            color={Color.LimeGreen}
                            quantity={totais.cadastrosAprovados}
                            topLeft
                            topText="Cadastros Concluídos"
                            big
                            linkTo="/cadastro-legal-ops"
                        />
                    </>
                ) : (
                    <></>
                )
            }
        </Loading>
    );
};
