import { objectArrayToCSV, TableHeader } from "lib/export-format";
import { Format } from "lib/format";
import { dateToDDMMYYYY } from "lib/time";
import { TriggerDownload } from "lib/trigger-download";
import { Convite } from "services/onboarding/models/convite";

export const downloadExportar = async (listaConvites: Convite[], type: "xlsx" | "csv") => {
    const headers: TableHeader[] = [
        {
            label: "Nome",
            selector: "nome",
        },
        {
            label: "CPF",
            selector: "cpf",
        },
        {
            label: "Email",
            selector: "email",
        },
        {
            label: "Telefone",
            selector: "telefone",
            format: (v) => Format.formatCellphoneNumber(v),
        },
        {
            label: "Status",
            selector: "status",
        },
        {
            label: "Data de cadastro",
            selector: "dataCadastroConvite",
        },
    ];

    if (type === "csv") {
        TriggerDownload.csv(objectArrayToCSV(listaConvites, headers), `convites-${dateToDDMMYYYY(new Date())}.csv`);
    } 
    // else {
    //     // type === 'xlsx'
    //     TriggerDownload.xlsx(
    //         await arrayObjectToXLSX(listaConvites, headers),
    //         `convites-${dateToDDMMYYYY(new Date())}.xlsx`
    //     );
    // }
};
