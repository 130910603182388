import React from "react";
import styles from "./button-primary.module.scss";

interface ButtonPrimaryProps {
    value?: string;
    onClick?: () => void;
    children?: JSX.Element | string;
    type?: "button" | "submit" | "reset";
    className?: string;
    disabled?: boolean;
}

export const ButtonPrimary = (props: ButtonPrimaryProps) => {
    return (
        <button
            type={props.type}
            onClick={props.onClick}
            className={styles.btnPrimary + (props.className ? ` ${props.className}` : "")}
            value={props.value}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    );
};

export const ButtonPrimarySmall = (props: ButtonPrimaryProps) => {
    return (
        <button
            type={props.type}
            onClick={props.onClick}
            className={styles["btn-primary-small"] + (props.className ? ` ${props.className}` : "")}
            value={props.value}
        >
            {props.children}
        </button>
    );
};
