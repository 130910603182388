import { useField } from "formik";
import React, { useEffect } from "react";
import styles from "../field.module.scss";
import CreatableSelect from "react-select/creatable";

export interface OmnijusSelectWithCreateFieldProps {
    name: string;
    label?: string;
    options?: Array<SelectObject>;
    isClearable?: boolean;
    isDisabled?: boolean;
    className?: string;
    onChange?: (o: SelectObject) => void;
}

export interface ValueType {
    id?: string;
    text?: string;
}

const compareValue = (first: ValueType, second: ValueType) =>
    first && second && ((first.id && first.id === second.id) || (first.text && first.text === second.text));

export interface SelectObject {
    label: string;
    value: ValueType;
}

export const OmnijusSelectWithCreateField = (props: OmnijusSelectWithCreateFieldProps) => {
    const [field, meta, helpers] = useField<ValueType>({ name: props.name });

    useEffect(() => {
        if (field.value?.id && !props.options?.find((o) => compareValue(o.value, field.value))) {
            helpers.setValue({ id: "", text: "" });
        }
    }, [props.options, field.value, helpers]);

    return (
        <div className={`${styles["field-control"]} ${props.className || ""}`}>
            {props.label ? (
                <label className={styles["label"]} htmlFor={props.name}>
                    {props.label}
                </label>
            ) : null}
            <CreatableSelect
                isDisabled={props.isDisabled}
                options={props.options}
                isClearable={props.isClearable === undefined ? true : props.isClearable}
                value={
                    field.value?.text
                        ? [{ label: field.value.text, value: field.value }]
                        : props.options?.filter((o) => compareValue(o.value, field.value))
                }
                onChange={(e) => {
                    helpers.setTouched(true);
                    const aux = e as SelectObject & { __isNew__?: boolean };
                    if (aux && aux.__isNew__) {
                        aux.value = { text: aux.value as string };
                    }
                    if (props.onChange) {
                        props.onChange(aux);
                    } else {
                        helpers.setValue(aux?.value || { id: "", text: "" });
                    }
                }}
                formatCreateLabel={(a) => `Buscar: ${a}`}
                createOptionPosition="first"
            />
            {meta.touched && meta.error ? (
                <p className={styles["error"]}>
                    {typeof meta.error === "object" ? (Object.values(meta.error)[0] as string) : meta.error}
                </p>
            ) : null}
        </div>
    );
};
