import { objectArrayToCSV, TableHeader } from "lib/export-format";
import { Format } from "lib/format";
import { dateToDDMMYYYY } from "lib/time";
import { TriggerDownload } from "lib/trigger-download";
import { FilaLead } from "services/onboarding/models/fila-leads";

export const downloadExportar = (listaFilaLeads: FilaLead[], type: "xlsx" | "csv") => {
    const headers: TableHeader[] = [
        {
            label: "Nome",
            selector: "nome",
        },
        {
            label: "CPF",
            selector: "cpf",
        },
        {
            label: "Email",
            selector: "email",
        },
        {
            label: "Telefone",
            selector: "telefone",
            format: (v) => Format.formatCellphoneNumber(v),
        },
        {
            label: "Data de cadastro",
            selector: "dataCadastro",
        },
        {
            label: "Status",
            selector: "possuiConvite",
            format: (v) => (String(v).toUpperCase() === "TRUE" ? "Com convite" : "Sem convite"),
        },
    ];

    if (type === "csv") {
        TriggerDownload.csv(objectArrayToCSV(listaFilaLeads, headers), `fila-leads-${dateToDDMMYYYY(new Date())}.csv`);
    }
    // else {
    //     // type === 'xlsx'
    //     TriggerDownload.xlsx(
    //         await arrayObjectToXLSX(listaFilaLeads, headers),
    //         `fila-leads-${dateToDDMMYYYY(new Date())}.xlsx`
    //     );
    // }
};
