import { ActionFeedback } from "@omnijus/common";
import { RequestApi } from "@omnijus/common";
import { CLIENT_ID } from "services/client-id";
import { ArquivoPrestadorAtualizado } from "./models/arquivo-prestador-atualizado";
import { AtualizarArquivoPrestadorCommand } from "./models/atualizar-arquivo-prestador-command";
import { AtualizarPrestadorServicoUsuarioCommand } from "./models/atualizar-prestador-servico-command";
import { CadastroPrestadorPessoaFisica } from "./models/cadastro-prestador-pessoa-fisica";
import { CadastroPrestadorPessoaJuridica } from "./models/cadastro-prestador-pessoa-juridica";
import { CriarPrestadorServicoCommand } from "./models/criar-prestador-servico-command";
import { DetalheCadastroPrestadores } from "./models/detalhe-cadastro-prestadores";
import { ParametrosListarCadastroPrestadores } from "./models/parametros-listar-cadastro-prestadores";

const prestadorServicoUrl = process.env.REACT_APP_PRESTADOR_API_URL! + "/BFF/PrestadorServico";
const onboardingUrl = process.env.REACT_APP_BFF_ADMINISTRATIVO_API_URL + "/api/Onboarding/";

const requestApi = new RequestApi(CLIENT_ID);

export const listarCadastroPrestadores = (parametros?: ParametrosListarCadastroPrestadores) => {
    let queryString = "";

    if (parametros) {
        queryString = RequestApi.objectToQueryString(parametros);
    }

    return requestApi.get<DetalheCadastroPrestadores[]>(`${prestadorServicoUrl}?${queryString}`);
};

export const criarPrestadorServicoPF = (parametros: CriarPrestadorServicoCommand) => {
    const url = `${prestadorServicoUrl}/PessoaFisica`;
    return requestApi.post<string>(url, parametros);
};

export const atualizarPrestadorServicoPF = (parametros: AtualizarPrestadorServicoUsuarioCommand) => {
    const url = `${prestadorServicoUrl}/PessoaFisica`;
    return requestApi.put(url, parametros);
};

export const atualizarArquivoPrestador = (parametros: AtualizarArquivoPrestadorCommand) => {
    const url = `${prestadorServicoUrl}/PessoaFisica/Arquivo`;
    return requestApi.putFormdata<ArquivoPrestadorAtualizado>(url, parametros);
};

export const detalharPrestadorServicoPF = async (
    idPrestador: string
): Promise<CadastroPrestadorPessoaFisica | undefined> => {
    const url = `${prestadorServicoUrl}/pessoas-fisicas/${idPrestador}`;
    const res = await requestApi.get<CadastroPrestadorPessoaFisica>(url);
    if (res) {
        return {
            ...res,
            dataExpedicaoRegistroProfissional:
                res.dataExpedicaoRegistroProfissional && new Date(res.dataExpedicaoRegistroProfissional),
            dataExpedicaoRG: res.dataExpedicaoRG && new Date(res.dataExpedicaoRG),
            formacoes: res.formacoes.map((formacao) => ({
                ...formacao,
                inicio: formacao.inicio && new Date(formacao.inicio),
                termino: formacao.dataTermino && new Date(formacao.dataTermino),
            })),
            experiencias: res.experiencias.map((experiencia) => ({
                ...experiencia,
                inicio: experiencia.inicio && new Date(experiencia.inicio),
                dataTermino: experiencia.termino && new Date(experiencia.termino),
            })),
        };
    }
    return res;
};

export const detalharPrestadorServicoPJ = async (
    idPrestador: string
): Promise<CadastroPrestadorPessoaJuridica | undefined> => {
    const url = `${prestadorServicoUrl}/pessoas-juridicas/${idPrestador}`;
    const res = await requestApi.get<CadastroPrestadorPessoaJuridica>(url);

    return res;
};

export const buscarFotoPrestador = (id: string) => {
    const url = `${prestadorServicoUrl}/fotos/${id}`;
    return requestApi.getBlob(url);
};

export const downloadCertificado = async (id: string, fileName: string) => {
    const url = `${prestadorServicoUrl}/certificados/${id}`;
    return requestApi.download(url, fileName).catch((e) =>
        ActionFeedback.info({
            title: `Erro ao baixar certificado`,
            text: e,
        })
    );
};

export const downloadCurriculo = async (id: string, fileName: string) => {
    const url = `${prestadorServicoUrl}/curriculos/${id}`;
    return requestApi.download(url, fileName).catch((e) =>
        ActionFeedback.info({
            title: `Erro ao baixar currículo`,
            text: e,
        })
    );
};

export const postNotificacaoCadastroPrestadores = async (
    filtro: ParametrosListarCadastroPrestadores,
    titulo: string,
    mensagem: string,
    dataAgendamento?: Date
) => {
    const url = new URL(`${onboardingUrl}v1/NotificacaoPrestadorServico`);
    return requestApi.post(url.toString(), {
        titulo,
        mensagem,
        dataAgendamento,
        filtro,
    });
};
