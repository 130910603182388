import { Formik, FormikProps } from "formik";
import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styles from "./modal-form.module.scss";

const MySwal = withReactContent(Swal);

export const showModalForm = <T extends object>(
    title: string,
    initialValues: T,
    form: ((props: FormikProps<T>) => React.ReactNode) | React.ReactNode,
    width?: string,
    validationSchema?: any | (() => any)
) => {
    return new Promise<T | undefined>((resolve, reject) => {
        MySwal.fire({
            title,
            width,
            html: (
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        resolve(values);
                        MySwal.close();
                    }}
                    validationSchema={validationSchema}
                >
                    {form}
                </Formik>
            ),
            customClass: { content: styles.modalContainer },
            willClose: () => {
                resolve(undefined);
            },
            showConfirmButton: false,
        });
    });
};
