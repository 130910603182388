import ptBr from "date-fns/locale/pt-BR";
import { Field, FieldProps, useFormikContext } from "formik";
import React from "react";
import ReactDatePicker from "react-datepicker";
import styles from "../field.module.scss";

export const OmnijusDateField = (props: {
    name: string;
    placeholder?: string;
    onChange?: (value?: Date) => void;
    label?: string;
    className?: string;
    monthOnly?: boolean;
    disabled?: boolean;
    showTimeSelect?: boolean;
    customInput?: React.ReactNode;
    dateFormat?: "dd/MM/yyyy" | "MM/yyyy" | "dd/MM/yyyy HH:mm";
}) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    return (
        <div className={`${styles["field-control"]} ${props.className || ""}`}>
            {props.label ? (
                <label className={styles["label"]} htmlFor={props.name}>
                    {props.label}
                </label>
            ) : null}
            <Field name={props.name} id={props.name} placeholder={props.placeholder}>
                {({ field, meta }: FieldProps) => (
                    <>
                        <ReactDatePicker
                            disabled={props.disabled}
                            autoComplete="off"
                            isClearable={!props.disabled}
                            selected={field.value}
                            onChange={(value) => {
                                setFieldTouched(field.name, true);
                                setFieldValue(field.name, value);

                                if (!(value instanceof Array) && props.onChange)
                                    props.onChange(value ? value : undefined);
                            }}
                            className={(props.className && styles[props.className]) || styles["input"]}
                            name={field.name}
                            placeholderText={props.placeholder}
                            onBlur={field.onBlur}
                            locale={ptBr}
                            monthClassName={() => styles.mesDatePicker}
                            showMonthYearPicker={props.monthOnly}
                            showFullMonthYearPicker={props.monthOnly}
                            dateFormat={
                                props.monthOnly ? "MM/yyyy" : props.dateFormat ? props.dateFormat : "dd/MM/yyyy"
                            }
                            showTimeSelect={props.showTimeSelect}
                            customInput={props.customInput}
                        />
                        <p className={styles["error"]}>{meta.touched && meta.error ? meta.error : ""}</p>
                    </>
                )}
            </Field>
        </div>
    );
};
