import { ActionFeedback, formatDateAndTime } from "@omnijus/common";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DeadLetterService } from "services/dead-letter/dead-letter-service";
import { DeadLetterResponse, DeadLetterViewModel, IdStatusMensagem } from 'services/dead-letter/models/dead-letter';
import { ButtonPrimary } from 'shared/buttons/button-primary/button-primary';
import { ButtonSecondary } from 'shared/buttons/button-secondary/button-secondary';
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import styles from "./detalhar-dead-letter.module.scss";
import { CampoForm, FormatJson } from './forms/forms';
import { HistoricoDeadLetter } from './historico/historico-dead-letter';
import { exibirModalEnviarDeadLetter } from './reenviar-dead-letter/reenviar-dead-letter';

export const DetalharDeadLetter = () => {
    const [promiseDetalheDeadLetter, setPromiseDetalheDeadLetter] = useState<Promise<DeadLetterResponse | undefined>>();
    const [loadDate, setLoadDate] = useState(new Date());
    const { idMensagem } = useParams<{ idMensagem: string }>();

    const atualizar = useCallback(() => {
        setLoadDate(new Date());
    }, []);

    useEffect(() => {
        setPromiseDetalheDeadLetter(DeadLetterService.obterMensagemPorId(idMensagem));
    }, [idMensagem, loadDate]);

    return (
        <Loading promise={promiseDetalheDeadLetter}>
            {(deadLetter) =>
                deadLetter ? (
                    <div className={styles.detalharDeadLetter}>

                        <CardDetalheDeadLetter
                            mensagem={deadLetter.mensagem}
                            mensagemAtual={deadLetter.mensagemAtual}
                            atualizar={atualizar}
                        />
                        <div className={styles.spacer} />
                        <CardEnvioMensagem mensagem={deadLetter.mensagemAtual} />
                        <div className={styles.spacer} />
                        <HistoricoDeadLetter historico={deadLetter.timeline} idMensagem={idMensagem} atualizar={atualizar}/>

                    </div>
                ) : (<OmnijusCard body={<p className="text-center">Mensagem não encontrada</p>} />)
            }
        </Loading>
    );
};


const CardDetalheDeadLetter = ({ mensagem, mensagemAtual, atualizar }: {
    mensagem: DeadLetterViewModel,
    mensagemAtual: string,
    atualizar: () => void
}) => {

    const history = useHistory();

    const reenviarMensagem = useCallback(async () => {
        const res = await exibirModalEnviarDeadLetter(mensagem.id, mensagemAtual);

        if (res) {
            atualizar();
        }
    }, [mensagem, mensagemAtual, atualizar]);

    const ignorarMensagem = useCallback(async () => {
        const aceito = await ActionFeedback.confirm('Atenção, deseja realmente ignorar esta mensagem?');
        if (!aceito) {
            return;
        }

        await ActionFeedback.processing({
            title: 'Aguarde...',
            execution: DeadLetterService.ignorarMensagem(mensagem.id)
        });

        atualizar();

    }, [mensagem.id, atualizar]);

    return <OmnijusCard
        className={styles.cardDetalheDeadLetter}
        header={<h2>Dados da mensagem</h2>}
        body={
            <div className={styles.cardHeaderMensagem}>
                <div className={styles.topico}>
                    <CampoForm readonly label="Tópico" valor={mensagem.topico} />
                    <CampoForm readonly label="Consumer" valor={mensagem.consumer} />
                </div>

                <div className={styles.topico}>
                    <CampoForm readonly label="Status" valor={mensagem.statusMensagem} />
                    <CampoForm readonly label="Máximo tentativas (automaticas)" valor={mensagem.maximoRetentativas?.toString()} />
                    <CampoForm readonly label="Total de erros" valor={mensagem.totalErros.toString()} />
                    <CampoForm readonly label="Total de envios" valor={mensagem.totalEnvios.toString()} />
                </div>

                <div className={styles.topico}>
                    <CampoForm readonly label="Data de criação" valor={formatDateAndTime(mensagem.dataCriacao)} />
                    <CampoForm readonly label="Data da ProximaTentativa" valor={mensagem.dataProximaTentativa && formatDateAndTime(mensagem.dataProximaTentativa)} />
                    <CampoForm readonly label="Data da UltimaTentativa" valor={mensagem.dataUltimaTentativa && formatDateAndTime(mensagem.dataUltimaTentativa)} />
                </div>

                <div className={styles.erro}>
                    <CampoForm readonly label="Tipo de erro" valor={mensagem.tipoErro} />
                    <CampoForm readonly label="Mensagem de erro" valor={mensagem.mensagemErro} />
                </div>

                <div className={styles.buttons}>
                    <ButtonSecondary
                        onClick={() => history.goBack()}
                    >Voltar</ButtonSecondary>
                    <ButtonSecondary
                        disabled={mensagem.idStatusMensagem === IdStatusMensagem.Processada}
                        onClick={ignorarMensagem}
                    >Ignorar</ButtonSecondary>
                    <ButtonPrimary
                        disabled={mensagem.idStatusMensagem === IdStatusMensagem.Processada}
                        onClick={reenviarMensagem}
                    >Reenviar mensagem</ButtonPrimary>
                </div>

            </div>
        }
    />
}

export const CardEnvioMensagem = ({ mensagem }: { mensagem: string }) => {
    return <OmnijusCard
        collapsable
        collapsedByDefault
        className={styles.spacerV}
        header={<h3>Mensagem atual</h3>}
        body={
            <pre className={styles.jsonPre}>{FormatJson(mensagem)}</pre>
        }
    />
}
