import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router-dom";
import { PoliticaTemplateDetalhada } from "services/politica-template/models/politica-template-detalhada";
import { PoliticaTemplateService } from "services/politica-template/politica-template-service";
import { ButtonSecondary } from "shared/buttons/button-secondary/button-secondary";
import { Loading } from "shared/loading/loading";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import * as uuid from "uuid";
import styles from "./detalhar-config-template.module.scss";

export const DetalharConfigTemplate = () => {
    const { politicaTemplateId } = useParams<{ politicaTemplateId: string }>();
    const history = useHistory();
    const [promisePoliticaTemplate, setPromisePoliticaTemplate] =
        useState<Promise<PoliticaTemplateDetalhada | undefined>>();

    useEffect(() => {
        if (uuid.validate(politicaTemplateId))
            setPromisePoliticaTemplate(PoliticaTemplateService.obterPoliticaTemplate(politicaTemplateId));
    }, [politicaTemplateId]);

    return (
        <div className={styles["detalhar-template"]}>
            <Loading promise={promisePoliticaTemplate}>
                {(politicaTemplate) =>
                    politicaTemplate ? (
                        <>
                            <OmnijusCard
                                header={<h2>Política de template</h2>}
                                body={
                                    <div className={styles["politica-template"]}>
                                        <p>
                                            <span className={styles["prefixo"]}>Cliente:</span>
                                            {politicaTemplate.razaoSocialCliente}
                                        </p>
                                        <p>
                                            <span className={styles["prefixo"]}>Contrato:</span>
                                            {politicaTemplate.numeroContrato}
                                        </p>
                                        <p>
                                            <span className={styles["prefixo"]}>Área:</span>
                                            {politicaTemplate.nomeAreaAtuacaoOrgao}
                                        </p>
                                        <p>
                                            <span className={styles["prefixo"]}>Status:</span>
                                            {politicaTemplate.estaAtiva ? "Ativa" : "Inativa"}
                                        </p>
                                    </div>
                                }
                            />
                            <OmnijusCard
                                header={<h2>Empresas</h2>}
                                body={
                                    <DataTable
                                        noHeader={true}
                                        pagination={false}
                                        noDataComponent={<p>Nenhum registro encontrado!</p>}
                                        columns={[
                                            {
                                                name: "CNPJ",
                                                selector: "cnpj",
                                            },
                                            {
                                                name: "Razão social",
                                                selector: "razaoSocial",
                                            },
                                        ]}
                                        data={politicaTemplate.listaEmpresas}
                                    />
                                }
                            />
                            <OmnijusCard
                                header={<h2>Atividades</h2>}
                                body={
                                    <DataTable
                                        noHeader={true}
                                        pagination={false}
                                        noDataComponent={<p>Nenhum registro encontrado!</p>}
                                        columns={[
                                            {
                                                name: "Atividade",
                                                selector: "nomeAtividade",
                                            },
                                            {
                                                name: "Rito",
                                                selector: "rito",
                                            },
                                            {
                                                name: "Tipo de ação",
                                                selector: "tipoAcao",
                                            },
                                            {
                                                name: "Assunto",
                                                selector: "assunto",
                                            },
                                            {
                                                name: "Template",
                                                selector: "template",
                                            },
                                        ]}
                                        data={politicaTemplate.listaAtividades}
                                    />
                                }
                            />
                        </>
                    ) : (
                        <OmnijusCard body={<p className="text-center">Política de template não encontrada</p>} />
                    )
                }
            </Loading>
            <div className={styles["acao-voltar"]}>
                <ButtonSecondary onClick={() => history.goBack()}>Voltar</ButtonSecondary>
            </div>
        </div>
    );
};
