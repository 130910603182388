import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as EditIcon } from "assets/images/edit-pen.svg";
import { ReactComponent as DeleteIcon } from "assets/images/trash-icon.svg";
import { FastField, FieldArray, FieldProps, Form, useField } from "formik";
import { ActionFeedback } from "@omnijus/common";
import { Format } from "lib/format";
import React from "react";
import { IdTipoMeioComunicacao } from "services/cadastro-prestadores/models/prestador-servico-usuario-meio-comunicacao";
import { TipoContato } from "services/filtros/models/tipo-contato";
import { ButtonPrimary } from "shared/buttons/button-primary/button-primary";
import { OmnijusCampoTipoMeioComunicacao } from "shared/form/custom-fields/omnijus-campo-tipo-meio-comunicacao";
import { OmnijusRadioField } from "shared/form/fields/omnijus-radio-field";
import { OmnijusTextField } from "shared/form/fields/omnijus-text-field";
import { showModalForm } from "shared/form/modal-form/modal-form";
import { OmnijusCard } from "shared/omnijus-card/omnijus-card";
import { number as YupNumber, object as YupObject, string as YupString } from "yup";
import styles from "../cadastro-prestadores.module.scss";

export interface MeioDeComunicacao {
    id?: string;
    tipo: TipoContato;
    contato: string;
    preferencial: boolean;
}

export const meioDeComunicacaoValidation = YupObject().shape({
    tipo: YupObject().shape({
        id: YupNumber().min(1, "Selecione o tipo"),
    }),
    contato: YupString()
        .required("Informe o contato")
        .when("tipo.id", {
            is: IdTipoMeioComunicacao.Email,
            then: YupString().email("Email inválido"),
        }),
});

export const modalNovoMeioComunicacao = async (
    nomeAcao: string,
    valorInicial: MeioDeComunicacao
): Promise<MeioDeComunicacao | undefined> => {
    const mc = await showModalForm(
        nomeAcao,
        { ...valorInicial, preferencial: String(valorInicial.preferencial) },
        <Form className={`${styles.modalForm} ${styles.grid3columns}`}>
            <OmnijusCampoTipoMeioComunicacao name="tipo" label="Tipo" />
            <FastField name="tipo">
                {({ field, form, meta }: FieldProps) => (
                    <OmnijusTextField
                        name="contato"
                        label="Contato"
                        masks={
                            meta.value.id === IdTipoMeioComunicacao.Celular
                                ? [{ mask: "(##) #-####-####", charType: "numeric" }]
                                : meta.value.id === IdTipoMeioComunicacao.Telefone
                                ? [{ mask: "(##) ####-####", charType: "numeric" }]
                                : undefined
                        }
                    />
                )}
            </FastField>
            <OmnijusRadioField
                name="preferencial"
                options={[
                    { label: "Sim", value: "true" },
                    { label: "Não", value: "false" },
                ]}
                label="Preferencial"
            />
            <div className={styles.linhaSubmit}>
                <ButtonPrimary type="submit">{nomeAcao}</ButtonPrimary>
            </div>
        </Form>,
        "50rem",
        meioDeComunicacaoValidation
    );
    return mc ? { ...mc, preferencial: Format.strToBoolean(mc.preferencial) || false } : undefined;
};

export const MeiosComunicacao = ({ values }: { values: { meiosDeComunicacao: MeioDeComunicacao[] } }) => {
    const name = "meiosDeComunicacao";
    const [, meta] = useField<Array<MeioDeComunicacao>>({ name });
    return (
        <OmnijusCard
            header={<h3>Meios de Comunicação</h3>}
            body={
                <FieldArray name={name}>
                    {(arrayHelpers) => (
                        <>
                            <table className={styles.table}>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col className={styles.colIcones} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <td>Tipo</td>
                                        <td>Contato</td>
                                        <td>Preferencial</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {values.meiosDeComunicacao.map((meio, idx) => (
                                        <tr key={idx}>
                                            <td>{meio.tipo.descricao}</td>
                                            <td>{meio.contato}</td>
                                            <td>{meio.preferencial ? "Sim" : "Não"}</td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className={styles.botaoIcone}
                                                    onClick={async () => {
                                                        const novoMeioDeComunicacao = await modalNovoMeioComunicacao(
                                                            "Editar meio de comunicação",
                                                            meio
                                                        );
                                                        novoMeioDeComunicacao &&
                                                            arrayHelpers.replace(idx, novoMeioDeComunicacao);
                                                    }}
                                                >
                                                    <EditIcon title="Ícone de editar meio de comunicação" />
                                                </button>
                                                <button
                                                    type="button"
                                                    className={styles.botaoIcone}
                                                    onClick={() => {
                                                        arrayHelpers.remove(idx);
                                                    }}
                                                >
                                                    <DeleteIcon title="Ícone de excluir meio de comunicação" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button
                                type="button"
                                className={styles.botaoIcone}
                                onClick={async () => {
                                    const novoMeioDeComunicacao = await modalNovoMeioComunicacao(
                                        "Adicionar meio de comunicação",
                                        { tipo: { id: -1, descricao: "" }, contato: "", preferencial: false }
                                    );

                                    if (novoMeioDeComunicacao) {
                                        var meioPreferencialCadastrado = meta.value.find((v) => v.preferencial);

                                        if (novoMeioDeComunicacao.preferencial && meioPreferencialCadastrado) {
                                            ActionFeedback.error({
                                                title: `Erro ao adicionar meio de comunicação. O meio de comunicação ${meioPreferencialCadastrado.contato} já está marcado como preferencial`,
                                            });
                                        } else {
                                            arrayHelpers.push(novoMeioDeComunicacao);
                                        }
                                    }
                                }}
                            >
                                <AddIcon title="Ícone de adicionar novo meio de comunicação" />
                            </button>
                            {meta.touched && meta.error && (
                                <div className={styles.error}>
                                    {Array.isArray(meta.error)
                                        ? meta.error.map((er) => Object.values(er).join(","))
                                        : typeof meta.error === "object"
                                        ? Object.values(meta.error).join(",")
                                        : meta.error}
                                </div>
                            )}
                        </>
                    )}
                </FieldArray>
            }
        />
    );
};
