export enum IdEtapaCadastro
{
    DadosContato = 1,
    DadosRG = 2,
    RegistroProfissional = 3,
    FormacaoAcademica = 4,
    ExperienciaProfissional = 5,
    Endereco = 6,
    DadosBancarios = 7,
    Concluido = 99
}