import React, { useEffect, useState } from "react";
import { TipoInconsistencia } from "services/onboarding/models/tipo-inconsistencia";
import { listarTiposInconsistencia } from "services/onboarding/onboarding-inconsistencias-service";
import { Loading } from "shared/loading/loading";
import { OmnijusSelectField } from "../fields/omnijus-select-field";

interface OmnijusCampoTipoInconsistenciaProps {
    hideLabel?: boolean;
    name?: string;
    label?: string;
    isDisabled?: boolean;
}

export const OmnijusCampoTipoInconsistencia = (props: OmnijusCampoTipoInconsistenciaProps) => {
    const [promiseOpcoes, setPromiseOpcoes] = useState<Promise<TipoInconsistencia[] | undefined>>();

    useEffect(() => {
        setPromiseOpcoes(listarTiposInconsistencia());
    }, []);

    return (
        <Loading promise={promiseOpcoes}>
            {(opcoes) => (
                <OmnijusSelectField
                    isDisabled={props.isDisabled}
                    name={props.name || "idTipoInconsistencia"}
                    label={props.hideLabel ? undefined : props.label}
                    options={opcoes
                        ?.map((o) => ({
                            value: o.id.toString(),
                            label: o.descricao || "",
                        }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
                />
            )}
        </Loading>
    );
};
