import React from "react";
import { Anexo } from "services/onboarding/models/anexo";
import { IdEtapaCadastro } from "services/onboarding/models/id-etapa-cadastro";
import { CampoCadastro, CampoImagem } from "../campo-cadastro/campo-cadastro";
import { CardCadastro } from "../card-cadastro/card-cadastro";
import styles from "./endereco.module.scss";

export interface EnderecoProps {
    cep?: string;
    logradouro?: string;
    numero?: string;
    complemento?: string;
    bairro?: string;
    cidade?: string;
    uf?: string;
    comprovanteEndereco?: Anexo;
}

const etapa = { descricao: "Endereço", id: IdEtapaCadastro.Endereco };

export const Endereco = ({ cep, logradouro, numero, complemento, cidade, uf, comprovanteEndereco }: EnderecoProps) => {
    return (
        <CardCadastro
            header={etapa.descricao}
            body={
                <div className={styles.endereco}>
                    <div>
                        <CampoCadastro rotulo="CEP" valor={cep} nome="cep" />
                        <CampoCadastro rotulo="Logradouro" valor={logradouro} nome="logradouro" />
                        <CampoCadastro rotulo="Número" valor={numero} nome="numero" />
                        <CampoCadastro rotulo="Complemento" valor={complemento} nome="complemento" />
                        <CampoCadastro rotulo="Cidade" valor={cidade} nome="cidade" />
                        <CampoCadastro rotulo="Estado" valor={uf} nome="uf" />
                    </div>
                    <CampoImagem rotulo="Comprovante de endereço" anexo={comprovanteEndereco} />
                </div>
            }
            etapa={etapa}
        />
    );
};
