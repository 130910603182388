import { PagedResult } from "lib/paged-result";
import { RequestApi } from "@omnijus/common";
import { PaginationInfo } from "../../lib/pagination-info";
import {
    DeadLetterResponse,
    DeadLetterViewModel,
    EnvioResponse,
    ErroResponse,
    StatusDeadLetter,
} from "./models/dead-letter";
import { ParametrosPesquisarDLQ } from "./models/parametros-pesquisar-dlq";
import { CLIENT_ID } from "services/client-id";

const mensagemBaseUrl = process.env.REACT_APP_BFF_ADMINISTRATIVO_API_URL + "/api/Corporativo/v1/DeadLetter";

const requestApi = new RequestApi(CLIENT_ID);

export const DeadLetterService = {
    consultarMensagens: async (
        filtro: ParametrosPesquisarDLQ,
        paginationInfo: PaginationInfo
    ): Promise<PagedResult<DeadLetterViewModel>> => {
        const url = new URL(`${mensagemBaseUrl}/Mensagem`);

        let params = { ...filtro, ...paginationInfo };
        url.search = RequestApi.objectToQueryString(params);

        const res = await requestApi.get<PagedResult<DeadLetterViewModel>>(url.toString());
        return res
            ? {
                  ...res,
                  results: res.results.map((item) => ({
                      ...item,
                      dataPrimeiraEmissao: new Date(item.dataPrimeiraEmissao),
                      dataCriacao: new Date(item.dataCriacao),
                      dataProximaTentativa: item.dataProximaTentativa && new Date(item.dataProximaTentativa),
                      dataUltimaTentativa: item.dataUltimaTentativa && new Date(item.dataUltimaTentativa),
                  })),
              }
            : Promise.reject("Erro ao consultar mensagens");
    },
    obterMensagemPorId: async (idMensagem: String): Promise<DeadLetterResponse | undefined> => {
        const url = `${mensagemBaseUrl}/Mensagem/${idMensagem}`;
        var res = await requestApi.get<DeadLetterResponse>(url);

        return (
            res && {
                ...res,
                mensagem: {
                    ...res.mensagem,
                    dataPrimeiraEmissao: new Date(res.mensagem.dataPrimeiraEmissao),
                    dataCriacao: new Date(res.mensagem.dataCriacao),
                    dataProximaTentativa:
                        res.mensagem.dataProximaTentativa && new Date(res.mensagem.dataProximaTentativa),
                    dataUltimaTentativa: res.mensagem.dataUltimaTentativa && new Date(res.mensagem.dataUltimaTentativa),
                },
            }
        );
    },
    enviarEmLote: async (ids: string[]) => {
        const url = `${mensagemBaseUrl}/Mensagem/ReenviarEmLote`;
        await requestApi.post(url, { ids });
    },
    reenviarMensagem: async (idMensagem: string, mensagem: string) => {
        const url = `${mensagemBaseUrl}/Mensagem/${idMensagem}/Reenviar`;
        await requestApi.post(url, { mensagem });
    },
    ignorarMensagem: async (idMensagem: string) => {
        const url = `${mensagemBaseUrl}/Mensagem/${idMensagem}/Ignorar`;
        await requestApi.post(url);
    },
    listarStatus: async () => {
        const url = `${mensagemBaseUrl}/Status`;
        return await requestApi.get<StatusDeadLetter[]>(url);
    },
    obterEnvio: async (idMensagem: String, idEnvio: String): Promise<EnvioResponse | undefined> => {
        const url = `${mensagemBaseUrl}/Mensagem/${idMensagem}/Envio/${idEnvio}`;
        var res = await requestApi.get<EnvioResponse>(url);

        return res;
    },
    obterHistoricoErro: async (idMensagem: String, idErro: String): Promise<ErroResponse | undefined> => {
        const url = `${mensagemBaseUrl}/Mensagem/${idMensagem}/Erro/${idErro}`;
        var res = await requestApi.get<ErroResponse>(url);

        return (
            res && {
                ...res,
                id: res.id,
                data: res.data,
                tipoErro: res.tipoErro,
                mensagem: res.mensagem,
                mensagemErro: res.mensagemErro,
            }
        );
    },
};
